// SimpleLiveTransactions.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';

// If you have a subscription key, define it here:
const SUBSCRIPTION_KEY =
  process.env.NEXT_PUBLIC_APP ||
  process.env.REACT_APP_PUBLIC ||
  '';

// Create an axios client with default headers
const apiClient = axios.create({
  headers: { 'X-Verified-By': 'Vercel' },
});

// Interceptor to add your Ocp-Apim-Subscription-Key
apiClient.interceptors.request.use(
  (config) => {
    config.headers['Ocp-Apim-Subscription-Key'] = SUBSCRIPTION_KEY;
    return config;
  },
  (error) => Promise.reject(error)
);

const SimpleLiveTransactions = () => {
  const [messages, setMessages] = useState([]);
  const [wsUrl, setWsUrl] = useState(null);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);
  const [socketInstance, setSocketInstance] = useState(null);

  // 1. Fetch WebSocket URL from /api/v2_generatewebsocketURL
  useEffect(() => {
    const fetchWsUrl = async () => {
      try {
        console.log("[LiveTransactions] Fetching WebSocket URL...");
        const response = await apiClient.get('https://api.doh.money/api/v2_generatewebsocketURL');
        console.log("[LiveTransactions] WS URL API response:", response.data);

        const url = response.data.clientUrl || response.data.url || response.data.websocketUrl;
        if (!url) {
          throw new Error("No valid WebSocket URL found in the response.");
        }
        setWsUrl(url);
        console.log("[LiveTransactions] Fetched WS URL:", url);
      } catch (err) {
        console.error("[LiveTransactions] Error fetching WS URL:", err);
        setError("Failed to fetch WebSocket URL.");
      }
    };

    fetchWsUrl();
  }, []);

  // 2. Connect to WebSocket when wsUrl is available
  useEffect(() => {
    if (!wsUrl) {
      console.log("[LiveTransactions] No WS URL yet.");
      return;
    }

    console.log("[LiveTransactions] Connecting to WS:", wsUrl);
    const ws = new WebSocket(wsUrl);
    setSocketInstance(ws);

    ws.onopen = () => {
      console.log("[LiveTransactions] WebSocket connected.");
      setConnected(true);

      // Join the "livetransactions" group
      const joinMsg = { type: "joinGroup", group: "livetransactions" };
      console.log("[LiveTransactions] Sending joinGroup message:", joinMsg);
      ws.send(JSON.stringify(joinMsg));
    };

    ws.onerror = (evt) => {
      console.error("[LiveTransactions] WebSocket error:", evt);
      setError("WebSocket error occurred.");
    };

    ws.onclose = (evt) => {
      console.log("[LiveTransactions] WebSocket closed:", evt);
      setConnected(false);
    };

    // 3. Handle incoming messages
    ws.onmessage = (event) => {
      console.log("[LiveTransactions] Raw message received:", event.data);
      try {
        let message = event.data;
        if (typeof message === 'string') {
          message = JSON.parse(message);
        }
        // If it’s double-stringified, parse again
        if (typeof message === 'string') {
          message = JSON.parse(message);
        }
        console.log("[LiveTransactions] Parsed message:", message);

        // Check if it's a live_transaction
        if (message.type === "live_transaction" && message.data) {
          let payload = message.data;
          if (typeof payload === 'string') {
            payload = JSON.parse(payload);
          }
          setMessages((prev) => {
            const updated = [...prev, payload];
            console.log("[LiveTransactions] Updated messages:", updated);
            return updated.slice(-100); // keep last 100
          });
        } else {
          console.warn("[LiveTransactions] Unexpected message format:", message);
        }
      } catch (err) {
        console.error("[LiveTransactions] Error parsing message:", err);
      }
    };

    // Cleanup on unmount
    return () => {
      console.log("[LiveTransactions] Closing WebSocket connection.");
      ws.close();
    };
  }, [wsUrl]);

  // 4. Send periodic "ping" to keep the connection alive
  useEffect(() => {
    const interval = setInterval(() => {
      if (socketInstance && socketInstance.readyState === WebSocket.OPEN) {
        console.log("[LiveTransactions] Sending ping...");
        socketInstance.send(JSON.stringify({ type: "ping" }));
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [socketInstance]);

  // 5. Periodically log the readyState
  useEffect(() => {
    const interval = setInterval(() => {
      if (socketInstance) {
        console.log("[LiveTransactions] WebSocket readyState:", socketInstance.readyState);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [socketInstance]);

  // Render UI
  return (
    <Box sx={{ padding: 2, backgroundColor: '#1a1a1a', borderRadius: 2, color: '#fff' }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#FFD700' }}>
        Live Transactions {connected ? '🟢 Connected' : '🔴 Disconnected'}
      </Typography>

      {error && (
        <Typography variant="body2" color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {messages.length === 0 ? (
        <Box display="flex" alignItems="center" gap={1}>
          <CircularProgress size={20} color="inherit" />
          <Typography variant="body2">Waiting for transactions...</Typography>
        </Box>
      ) : (
        <Paper
          elevation={3}
          sx={{
            maxHeight: '60vh',
            overflowY: 'auto',
            p: 2,
            backgroundColor: '#2c2c2c',
            mt: 1
          }}
        >
          {messages.map((msg, idx) => (
            <Box
              key={idx}
              sx={{
                mb: 2,
                p: 1,
                borderRadius: 1,
                backgroundColor: '#1e1e1e',
                color: '#00FF99',
                fontFamily: 'monospace'
              }}
            >
              <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {JSON.stringify(msg, null, 2)}
              </pre>
            </Box>
          ))}
        </Paper>
      )}
    </Box>
  );
};

export default SimpleLiveTransactions;