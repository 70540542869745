import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './toolbar.css';
import { FaTelegramPlane, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';

const FeedbackModal = ({ isOpen, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [feedbackList, setFeedbackList] = useState([]);

    const azureFunctionUrl = `${process.env.REACT_APP_API_URL}/feedbackbot`; // Updated to use environment variable
    const voteApiUrl = `${process.env.REACT_APP_API_URL}/feedbackvote`; // Updated to use environment variable

    useEffect(() => {
        if (isOpen) {
            fetchFeedbackList();
        }
    }, [isOpen]);

    const fetchFeedbackList = async () => {
        try {
            const response = await axios.get(`${azureFunctionUrl}/list`);
            setFeedbackList(response.data);
        } catch (error) {
            console.error('Error fetching feedback list:', error);
        }
    };

    const sendFeedback = async () => {
        if (!feedback) return;

        setLoading(true);

        try {
            await axios.post(azureFunctionUrl, { feedback });
            setSuccess(true);
            setFeedback(''); // Clear the feedback form
        } catch (error) {
            console.error('Error sending feedback:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleVote = async (feedbackId, voteType) => {
        try {
            setLoading(true);
            await axios.post(voteApiUrl, { feedbackId, voteType });
            fetchFeedbackList(); // Optionally refresh the feedback list
        } catch (error) {
            console.error('Error voting:', error);
        } finally {
            setLoading(false);
        }
    };

    return isOpen ? (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h2 className="modal-header">Instant Feedback</h2>
                {success ? (
                    <p className="thank-you-message">
                        Thank you for your feedback! 🎉
                        <br />
                        You can view your feedback on our Telegram channel:
                        <a
                            href="https://t.me/doh_money"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://t.me/doh_money
                        </a>
                    </p>
                ) : (
                    <>
                        <textarea
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            placeholder="Type your feedback here..."
                        />
                        <div className="button-group">
                            <button
                                onClick={sendFeedback}
                                disabled={loading || !feedback}
                                className="feedback-button"
                            >
                                {loading ? (
                                    'Sending...'
                                ) : (
                                    <FaTelegramPlane className="telegram-icon" />
                                )}
                            </button>
                        </div>
                    </>
                )}

                <div className="feedback-list">
                    <h3>🔥 Vote on Feedback! 🔥</h3>
                    {feedbackList.length === 0 ? (
                        <p>No feedback to vote on yet.</p>
                    ) : (
                        feedbackList.map((item) => (
                            <div key={item.id} className="feedback-item">
                                <p>{item.text}</p>
                                <div className="vote-buttons">
                                    <button
                                        onClick={() =>
                                            handleVote(item.id, 'upvote')
                                        }
                                        disabled={loading}
                                        className="feedback-button"
                                    >
                                        <FaThumbsUp /> Upvote
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleVote(item.id, 'downvote')
                                        }
                                        disabled={loading}
                                        className="feedback-button"
                                    >
                                        <FaThumbsDown /> Downvote
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    ) : null;
};

export default FeedbackModal;
