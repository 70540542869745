import React, { useEffect, useRef, useState } from 'react';

const SYMBOL_MAP = {
  crypto: [
    { label: 'Alephium (ALPH/USDT)', value: 'MEXC:ALPHUSDT' },
    { label: 'Bitcoin (BTC/USDT)', value: 'BINANCE:BTCUSDT' },
    { label: 'Ethereum (ETH/USDT)', value: 'BINANCE:ETHUSDT' },
    { label: 'Solana (SOL/USDT)', value: 'BINANCE:SOLUSDT' },
  ],
  stocks: [
    { label: 'Apple (AAPL)', value: 'NASDAQ:AAPL' },
    { label: 'Tesla (TSLA)', value: 'NASDAQ:TSLA' },
    { label: 'Amazon (AMZN)', value: 'NASDAQ:AMZN' },
  ],
  forex: [
    { label: 'EUR/USD', value: 'FX:EURUSD' },
    { label: 'USD/JPY', value: 'FX:USDJPY' },
    { label: 'GBP/USD', value: 'FX:GBPUSD' },
  ],
};

const TradingViewChart = ({
  width = '100%',
  height = 500,
  interval = 'D',
  theme = 'dark',
  hideTopToolbar = false,
  hideSideToolbar = false,
}) => {
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const [assetType, setAssetType] = useState('crypto');
  const [symbol, setSymbol] = useState(SYMBOL_MAP.crypto[0].value);

  const symbols = SYMBOL_MAP[assetType];

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    if (iframeRef.current) {
      container.removeChild(iframeRef.current);
      iframeRef.current = null;
    }

    const srcUrl = `https://s.tradingview.com/widgetembed/?frameElementId=tradingview_advanced&symbol=${symbol}&interval=${interval}&theme=${theme}&style=1&locale=en&toolbar_bg=rgba(0,0,0,1)&enable_publishing=false&allow_symbol_change=true&hide_top_toolbar=${hideTopToolbar}&hide_side_toolbar=${hideSideToolbar}`;

    const iframe = document.createElement('iframe');
    iframe.src = srcUrl;
    iframe.style.width = typeof width === 'number' ? `${width}px` : width;
    iframe.style.height = `${height}px`;
    iframe.frameBorder = '0';
    iframe.allowTransparency = true;
    iframe.allowFullScreen = true;
    iframe.style.border = 'none';

    container.appendChild(iframe);
    iframeRef.current = iframe;

    return () => {
      if (iframeRef.current && container.contains(iframeRef.current)) {
        container.removeChild(iframeRef.current);
        iframeRef.current = null;
      }
    };
  }, [symbol, interval, theme, width, height, hideTopToolbar, hideSideToolbar]);

  const dropdownStyle = {
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    border: '1px solid #333',
    borderRadius: '6px',
    padding: '6px 10px',
    fontSize: '14px',
    outline: 'none',
    appearance: 'none',
    cursor: 'pointer',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem', width: '100%' }}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <select
          style={dropdownStyle}
          value={assetType}
          onChange={e => {
            const selectedType = e.target.value;
            setAssetType(selectedType);
            setSymbol(SYMBOL_MAP[selectedType][0].value);
          }}
        >
          <option value="crypto">Crypto</option>
          <option value="stocks">Stocks</option>
          <option value="forex">Forex</option>
        </select>

        <select
          style={dropdownStyle}
          value={symbol}
          onChange={e => setSymbol(e.target.value)}
        >
          {symbols.map(({ label, value }) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>

      <div
        ref={containerRef}
        style={{
          width: typeof width === 'number' ? `${width}px` : width,
          height: `${height}px`,
          minHeight: '400px',
          position: 'relative',
          flex: 1,
        }}
      />
    </div>
  );
};

export default TradingViewChart;