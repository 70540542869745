"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTransferTx = exports.getAddressFromUnlockScript = exports.getSenderAddress = exports.getDepositInfo = exports.getALPHDepositInfo = exports.isALPHTransferTx = exports.validateExchangeAddress = void 0;
const address_1 = require("../address");
const utils_1 = require("../utils");
const unlock_script_codec_1 = require("../codec/unlock-script-codec");
const script_codec_1 = require("../codec/script-codec");
const error_1 = require("../error");
function validateExchangeAddress(address) {
    const decoded = (0, utils_1.base58ToBytes)(address);
    if (decoded.length === 0)
        throw new Error('Address is empty');
    const addressType = decoded[0];
    if (addressType !== address_1.AddressType.P2PKH && addressType !== address_1.AddressType.P2SH) {
        throw new Error('Invalid address type');
    }
    if (decoded.length !== 33) {
        throw new Error('Invalid address length');
    }
}
exports.validateExchangeAddress = validateExchangeAddress;
function isALPHTransferTx(tx) {
    return isTransferTx(tx) && checkALPHOutput(tx);
}
exports.isALPHTransferTx = isALPHTransferTx;
function getALPHDepositInfo(tx) {
    if (!isALPHTransferTx(tx))
        return [];
    const inputAddresses = getInputAddresses(tx);
    const result = new Map();
    tx.unsigned.fixedOutputs.forEach((o) => {
        if (!inputAddresses.includes(o.address)) {
            const amount = result.get(o.address);
            if (amount === undefined) {
                result.set(o.address, BigInt(o.attoAlphAmount));
            }
            else {
                result.set(o.address, BigInt(o.attoAlphAmount) + amount);
            }
        }
    });
    return Array.from(result.entries()).map(([key, value]) => ({ targetAddress: key, depositAmount: value }));
}
exports.getALPHDepositInfo = getALPHDepositInfo;
function getInputAddresses(tx) {
    const inputAddresses = [];
    for (const input of tx.unsigned.inputs) {
        try {
            if (input.unlockScript === (0, utils_1.binToHex)(unlock_script_codec_1.encodedSameAsPrevious))
                continue;
            const address = getAddressFromUnlockScript(input.unlockScript);
            if (!inputAddresses.includes(address)) {
                inputAddresses.push(address);
            }
        }
        catch (error) {
            throw new error_1.TraceableError(`Failed to decode address from unlock script`, error);
        }
    }
    return inputAddresses;
}
function getDepositInfo(tx) {
    if (!isTransferTx(tx))
        return { alph: [], tokens: [] };
    const inputAddresses = getInputAddresses(tx);
    const alphDepositInfos = new Map();
    const tokenDepositInfos = new Map();
    tx.unsigned.fixedOutputs.forEach((o) => {
        if (!inputAddresses.includes(o.address)) {
            const alphAmount = alphDepositInfos.get(o.address) ?? 0n;
            alphDepositInfos.set(o.address, alphAmount + BigInt(o.attoAlphAmount));
            o.tokens.forEach((token) => {
                const depositPerToken = tokenDepositInfos.get(token.id) ?? new Map();
                const currentAmount = depositPerToken.get(o.address) ?? 0n;
                depositPerToken.set(o.address, currentAmount + BigInt(token.amount));
                tokenDepositInfos.set(token.id, depositPerToken);
            });
        }
    });
    return {
        alph: Array.from(alphDepositInfos.entries()).map(([key, value]) => ({ targetAddress: key, depositAmount: value })),
        tokens: Array.from(tokenDepositInfos.entries()).flatMap(([tokenId, depositPerToken]) => {
            return Array.from(depositPerToken.entries()).map(([targetAddress, depositAmount]) => ({
                tokenId,
                targetAddress,
                depositAmount
            }));
        })
    };
}
exports.getDepositInfo = getDepositInfo;
// we assume that the tx is a simple transfer tx, i.e. isALPHTransferTx(tx) || isTokenTransferTx(tx)
function getSenderAddress(tx) {
    return getAddressFromUnlockScript(tx.unsigned.inputs[0].unlockScript);
}
exports.getSenderAddress = getSenderAddress;
var UnlockScriptType;
(function (UnlockScriptType) {
    UnlockScriptType[UnlockScriptType["P2PKH"] = 0] = "P2PKH";
    UnlockScriptType[UnlockScriptType["P2MPKH"] = 1] = "P2MPKH";
    UnlockScriptType[UnlockScriptType["P2SH"] = 2] = "P2SH";
})(UnlockScriptType || (UnlockScriptType = {}));
function getAddressFromUnlockScript(unlockScript) {
    if (!(0, utils_1.isHexString)(unlockScript)) {
        throw new Error(`Invalid unlock script ${unlockScript}, expected a hex string`);
    }
    const decoded = (0, utils_1.hexToBinUnsafe)(unlockScript);
    if (decoded.length === 0)
        throw new Error('UnlockScript is empty');
    const unlockScriptType = decoded[0];
    const unlockScriptBody = decoded.slice(1);
    if (unlockScriptType === UnlockScriptType.P2PKH) {
        if (unlockScriptBody.length !== 33) {
            throw new Error(`Invalid p2pkh unlock script: ${unlockScript}`);
        }
        return (0, address_1.addressFromPublicKey)((0, utils_1.binToHex)(unlockScriptBody));
    }
    if (unlockScriptType === UnlockScriptType.P2MPKH) {
        throw new Error('Naive multi-sig address is not supported for exchanges as it will be replaced by P2SH');
    }
    if (unlockScriptType === UnlockScriptType.P2SH) {
        let p2sh;
        try {
            p2sh = unlock_script_codec_1.unlockScriptCodec.decode(decoded).value;
        }
        catch (e) {
            throw new error_1.TraceableError(`Invalid p2sh unlock script: ${unlockScript}`, e);
        }
        return (0, address_1.addressFromScript)(script_codec_1.scriptCodec.encode(p2sh.script));
    }
    throw new Error('Invalid unlock script type');
}
exports.getAddressFromUnlockScript = getAddressFromUnlockScript;
function checkALPHOutput(tx) {
    const outputs = tx.unsigned.fixedOutputs;
    return outputs.every((o) => o.tokens.length === 0);
}
function isTransferTx(tx) {
    if (tx.contractInputs.length !== 0 ||
        tx.generatedOutputs.length !== 0 ||
        tx.unsigned.inputs.length === 0 ||
        tx.unsigned.scriptOpt !== undefined) {
        return false;
    }
    return true;
}
exports.isTransferTx = isTransferTx;
