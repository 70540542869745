import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './totalsupply.module.css'; // Import CSS Module

const TotalSupply = () => {
    const [poolData, setPoolData] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [selectedToken, setSelectedToken] = useState('');
    const [totalSupply, setTotalSupply] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [matchedPoolInfo, setMatchedPoolInfo] = useState(null);

    const API_URL = 'https://dohcloud.azurewebsites.net/api_doh_totalsupply';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://dohcloud.azurewebsites.net/api_test_transactions',
                );
                const pools = response.data;
                setPoolData(pools);

                const extractedTokens = [];
                pools.forEach((pool) => {
                    if (pool.elexiumPool) {
                        const { token0, token1 } = pool.elexiumPool;

                        if (token0 && token0.address) {
                            extractedTokens.push({
                                address: token0.address,
                                id: token0.id,
                                symbol: token0.symbol,
                                name: token0.name,
                                logo: token0.logoURI || token0.logo,
                            });
                        }

                        if (token1 && token1.address) {
                            extractedTokens.push({
                                address: token1.address,
                                id: token1.id,
                                symbol: token1.symbol,
                                name: token1.name,
                                logo: token1.logoURI || token1.logo,
                            });
                        }
                    }

                    if (pool.ayinPool) {
                        const { token0, token1 } = pool.ayinPool;

                        if (token0 && token0.address) {
                            extractedTokens.push({
                                address: token0.address,
                                id: token0.id,
                                symbol: token0.symbol,
                                name: token0.name,
                                logo: token0.logoURI || token0.logo,
                            });
                        }

                        if (token1 && token1.address) {
                            extractedTokens.push({
                                address: token1.address,
                                id: token1.id,
                                symbol: token1.symbol,
                                name: token1.name,
                                logo: token1.logoURI || token1.logo,
                            });
                        }
                    }
                });

                const uniqueTokensMap = {};
                extractedTokens.forEach((token) => {
                    const lowerCaseAddress = token.address.toLowerCase();
                    if (!uniqueTokensMap[lowerCaseAddress]) {
                        uniqueTokensMap[lowerCaseAddress] = token;
                    }
                });

                const uniqueTokens = Object.values(uniqueTokensMap);
                setTokens(uniqueTokens);
                console.log(
                    'Unique tokens extracted for dropdown:',
                    uniqueTokens,
                );
            } catch (error) {
                console.error('Error fetching pool data:', error.message);
                setErrorMessage('Failed to fetch pool data.');
            }
        };

        fetchData();
    }, []);

    const handleTokenChange = (e) => {
        const selectedId = e.target.value;
        setSelectedToken(selectedId);
        setTotalSupply(null);
        setTransactionDetails(null);
        setErrorMessage(null);
        setMatchedPoolInfo(null);
    };

    const handleFetchTotalSupply = async () => {
        if (!selectedToken) {
            setErrorMessage('Please select a token from the dropdown.');
            return;
        }

        setLoading(true);
        setErrorMessage(null);

        try {
            const token = tokens.find(
                (t) => t.id.toLowerCase() === selectedToken.toLowerCase(),
            );

            if (!token) {
                setErrorMessage('Selected token not found in the tokens list.');
                setLoading(false);
                return;
            }

            const response = await axios.get(API_URL, {
                params: {
                    tokenId: token.id,
                    tokenAddress: token.address,
                },
            });

            const { totalSupply, transactionDetails } = response.data;

            const matchedPool = poolData.find(
                (pool) =>
                    (pool.elexiumPool &&
                        (pool.elexiumPool.token0.id.toLowerCase() ===
                            selectedToken.toLowerCase() ||
                            pool.elexiumPool.token1.id.toLowerCase() ===
                                selectedToken.toLowerCase())) ||
                    (pool.ayinPool &&
                        (pool.ayinPool.token0.id.toLowerCase() ===
                            selectedToken.toLowerCase() ||
                            pool.ayinPool.token1.id.toLowerCase() ===
                                selectedToken.toLowerCase())),
            );

            if (matchedPool) {
                let matchedToken = null;

                if (
                    matchedPool.elexiumPool &&
                    (matchedPool.elexiumPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase() ||
                        matchedPool.elexiumPool.token1.id.toLowerCase() ===
                            selectedToken.toLowerCase())
                ) {
                    matchedToken =
                        matchedPool.elexiumPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase()
                            ? matchedPool.elexiumPool.token0
                            : matchedPool.elexiumPool.token1;
                } else if (
                    matchedPool.ayinPool &&
                    (matchedPool.ayinPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase() ||
                        matchedPool.ayinPool.token1.id.toLowerCase() ===
                            selectedToken.toLowerCase())
                ) {
                    matchedToken =
                        matchedPool.ayinPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase()
                            ? matchedPool.ayinPool.token0
                            : matchedPool.ayinPool.token1;
                }

                if (matchedToken) {
                    setMatchedPoolInfo({
                        address: matchedToken.address,
                        tokenId: matchedToken.id,
                    });
                }
            }

            setTotalSupply(totalSupply);
            setTransactionDetails(transactionDetails);
        } catch (error) {
            console.error('Error fetching total supply:', error.message);
            setErrorMessage('Failed to fetch total supply.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Token Total Supply Viewer</h1>

            {/* Display Matched Pool Information */}
            {matchedPoolInfo && (
                <div className={styles.matchedPoolInfo}>
                    <h2>Matched Pool Information</h2>
                    <p>
                        <strong>Address:</strong> {matchedPoolInfo.address}
                    </p>
                    <p>
                        <strong>Token ID:</strong> {matchedPoolInfo.tokenId}
                    </p>
                </div>
            )}

            {/* Display Error Message */}
            {errorMessage && (
                <div className={styles.errorMessage}>
                    <p>{errorMessage}</p>
                </div>
            )}

            {/* Dropdown for Token Selection */}
            <div className={styles.selectionSection}>
                <label htmlFor="token-select" className={styles.label}>
                    Select a Token:
                </label>
                <div className={styles.selectButtonGroup}>
                    <select
                        id="token-select"
                        value={selectedToken}
                        onChange={handleTokenChange}
                        className={styles.tokenSelect}
                    >
                        <option value="">-- Select a Token --</option>
                        {tokens.map((token) => (
                            <option key={token.id} value={token.id}>
                                {token.name} ({token.symbol})
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={handleFetchTotalSupply}
                        className={styles.fetchButton}
                        disabled={!selectedToken || loading}
                    >
                        {loading ? 'Fetching...' : 'Fetch Total Supply'}
                    </button>
                </div>
            </div>

            {loading && <p className={styles.loadingText}>Loading...</p>}

            {totalSupply !== null && (
                <div className={styles.totalSupply}>
                    <h3>Total Supply for Token:</h3>
                    <p>{totalSupply}</p>
                </div>
            )}

            {transactionDetails && (
                <div className={styles.transactionDetails}>
                    <h3>Transaction Details:</h3>
                    <pre className={styles.pre}>
                        {JSON.stringify(transactionDetails, null, 2)}
                    </pre>
                </div>
            )}
        </div>
    );
};

export default TotalSupply;
