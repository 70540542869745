// src/components/tokens/ALPHCalculator.js

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import './alphcalc.css';
import { FaTimes, FaSpinner, FaCopy, FaCheck } from 'react-icons/fa'; // Added FaSpinner, FaCopy, FaCheck

const defaultLogoURL = 'https://via.placeholder.com/20?text=?'; // Adjusted for small logos

const ALPHCalculator = ({ alphPrice, tokenPrices, tokenList, onClose }) => {
    const [alphAmount, setAlphAmount] = useState(100); // Default to 100 ALPH
    const [selectedToken, setSelectedToken] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false); // Loading state
    const [copySuccess, setCopySuccess] = useState(false); // Copy success state
    const [theme, setTheme] = useState('light'); // Theme state

    // Build tokenOptions using tokenList and tokenPrices
    const tokenOptions = useMemo(() => {
        if (!tokenList || tokenList.length === 0) return [];

        return tokenList
            .filter((token) => token.symbol !== 'ALPH') // Exclude ALPH itself to prevent self-conversion
            .map((token) => ({
                value: token.symbol,
                label: token.symbol,
                logo: token.logoURI || defaultLogoURL,
            }));
    }, [tokenList]);

    // Custom Option component to include logos
    const Option = (props) => (
        <components.Option {...props}>
            <img
                src={props.data.logo}
                alt={`${props.data.label} logo`}
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10,
                    borderRadius: '50%',
                }}
            />
            {props.label}
        </components.Option>
    );

    // Custom SingleValue component to include logos
    const SingleValue = (props) => (
        <components.SingleValue {...props}>
            <img
                src={props.data.logo}
                alt={`${props.data.label} logo`}
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10,
                    borderRadius: '50%',
                }}
            />
            {props.data.label}
        </components.SingleValue>
    );

    // Calculate the result whenever alphAmount or selectedToken changes
    useEffect(() => {
        // Reset result and error when inputs change
        setResult(null);
        setError(null);

        if (alphAmount === '' || selectedToken === null) {
            // Do not perform calculation if inputs are incomplete
            return;
        }

        // Input validation
        if (alphAmount < 0) {
            setError('ALPH amount cannot be negative.');
            return;
        }

        if (alphAmount > 1e12) {
            // Example upper limit
            setError('ALPH amount is too large.');
            return;
        }

        const targetPrice = tokenPrices[selectedToken.value];

        if (!targetPrice || targetPrice <= 0) {
            setError('Selected token price is unavailable.');
            return;
        }

        // Simulate a brief calculation delay for the loading indicator
        setIsCalculating(true);
        const timer = setTimeout(() => {
            const calculatedAmount = (alphAmount * alphPrice) / targetPrice;
            setResult(calculatedAmount);
            setIsCalculating(false);
        }, 300); // 300ms delay for demonstration

        return () => clearTimeout(timer);
    }, [alphAmount, selectedToken, alphPrice, tokenPrices]);

    // Handle changes in the ALPH amount input
    const handleAlphAmountChange = (e) => {
        const value = e.target.value;
        if (value === '') {
            setAlphAmount('');
            setResult(null);
            setError(null);
        } else {
            const num = parseFloat(value);
            if (!isNaN(num)) {
                setAlphAmount(num);
            }
        }
    };

    // Handle changes in the token selection
    const handleTokenChange = (option) => {
        setSelectedToken(option);
    };

    // Handle copy to clipboard
    const handleCopy = () => {
        if (result && selectedToken) {
            const textToCopy = `${result.toLocaleString(undefined, {
                maximumFractionDigits: 6,
            })} ${selectedToken.value}`;
            navigator.clipboard.writeText(textToCopy).then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
            });
        }
    };

    // Toggle theme
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <div
            className="alph-calculator-overlay"
            onClick={onClose}
            aria-modal="true"
            role="dialog"
        >
            <div
                className={`alph-calculator-container ${theme}`}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
                {/* Theme Toggle Button */}
                <button
                    className="theme-toggle-button"
                    onClick={toggleTheme}
                    aria-label="Toggle Theme"
                >
                    {theme === 'light' ? '🌙' : '☀️'}
                </button>

                {/* Close Button */}
                <button
                    className="close-button"
                    onClick={onClose}
                    title="Close Calculator"
                    aria-label="Close Calculator"
                >
                    <FaTimes />
                </button>

                <h2>ALPH to Token Calculator</h2>
                <div className="calculator-inputs">
                    <div className="input-group">
                        <label htmlFor="alph-amount">ALPH Amount:</label>
                        <input
                            type="number"
                            id="alph-amount"
                            value={alphAmount}
                            onChange={handleAlphAmountChange}
                            min="0"
                            step="any"
                            placeholder="Enter ALPH amount"
                            aria-describedby="alph-amount-desc"
                        />
                        <small id="alph-amount-desc">
                            Enter the amount of ALPH you want to convert.
                        </small>
                    </div>
                    <div className="input-group">
                        <label htmlFor="target-token">Select Token:</label>
                        <Select
                            id="target-token"
                            options={tokenOptions}
                            value={selectedToken}
                            onChange={handleTokenChange}
                            placeholder="Select Token"
                            isClearable
                            components={{ Option, SingleValue }}
                            classNamePrefix="react-select"
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    minWidth: 200,
                                }),
                            }}
                            aria-label="Select Token"
                        />
                        <small id="target-token-desc">
                            Choose the token you want to receive.
                        </small>
                    </div>
                </div>
                <div className="calculator-result">
                    {error && (
                        <p className="error-message" role="alert">
                            {error}
                        </p>
                    )}
                    {isCalculating && (
                        <FaSpinner
                            className="spinner"
                            aria-label="Calculating..."
                        />
                    )}
                    {!isCalculating &&
                        result !== null &&
                        selectedToken &&
                        !error && (
                            <div className="result-container">
                                <p>
                                    {alphAmount} ALPH ≈{' '}
                                    {Number.isFinite(result)
                                        ? result.toLocaleString(undefined, {
                                              maximumFractionDigits: 6,
                                          })
                                        : 'N/A'}{' '}
                                    {selectedToken.value}
                                </p>
                                <button
                                    className="copy-button"
                                    onClick={handleCopy}
                                    aria-label="Copy Result"
                                >
                                    {copySuccess ? (
                                        <FaCheck color="green" />
                                    ) : (
                                        <FaCopy />
                                    )}
                                </button>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

ALPHCalculator.propTypes = {
    alphPrice: PropTypes.number.isRequired, // Price of ALPH in USD
    tokenPrices: PropTypes.object.isRequired, // { symbol: price }
    tokenList: PropTypes.arrayOf(
        PropTypes.shape({
            symbol: PropTypes.string.isRequired,
            logoURI: PropTypes.string,
            // Include other necessary properties if needed
        }),
    ).isRequired, // Token list with symbol and logoURI
    onClose: PropTypes.func.isRequired, // Function to close the calculator
};

export default ALPHCalculator;
