import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

const TradingChart = ({ chartData, transactions = [], isDarkMode, pairName, loading, error }) => {
  // Reference to the inner container div.
  const chartContainerRef = useRef(null);
  // Reference to store the TradingView widget instance.
  const widgetRef = useRef(null);
  // Flag for whether the TradingView library is available.
  const [libLoaded, setLibLoaded] = useState(false);

  // Check for TradingView library availability on mount.
  useEffect(() => {
    if (window.TradingView) {
      setLibLoaded(true);
    } else {
      console.error("TradingView library is not available on window.");
    }
  }, []);

  // Use useLayoutEffect to ensure the DOM is fully updated before initializing.
  useLayoutEffect(() => {
    if (!libLoaded) return;
    if (!window.TradingView) {
      console.error("TradingView library not loaded.");
      return;
    }
    if (!chartContainerRef.current) {
      console.error("chartContainerRef is not set.");
      return;
    }
    if (chartData.length === 0) {
      console.error("chartData is empty.");
      return;
    }

    // Remove any existing widget instance.
    if (widgetRef.current) {
      try {
        widgetRef.current.remove();
      } catch (err) {
        console.error("Error removing TradingView widget:", err);
      }
      widgetRef.current = null;
    }

    // Clear the container's inner HTML.
    chartContainerRef.current.innerHTML = '';

    // Delay initialization slightly to ensure the container is in the DOM.
    setTimeout(() => {
      try {
        widgetRef.current = new window.TradingView.widget({
          symbol: pairName,
          container: chartContainerRef.current, // Pass the actual DOM node here.
          library_path: '/tradingview/charting_library/',
          locale: 'en',
          autosize: true,
          interval: 'D', // default interval is daily
          theme: isDarkMode ? 'Dark' : 'Light',
          overrides: {
            'paneProperties.background': isDarkMode ? '#1E1E1E' : '#FFFFFF',
            'paneProperties.vertGridProperties.color': isDarkMode ? '#444444' : '#DDDDDD',
            'paneProperties.horzGridProperties.color': isDarkMode ? '#444444' : '#DDDDDD',
            'symbolWatermarkProperties.transparency': 90,
            'scalesProperties.textColor': isDarkMode ? '#B0B0B0' : '#333333',
            'mainSeriesProperties.style': 1,
            'mainSeriesProperties.candleStyle.upColor': '#32CD32',
            'mainSeriesProperties.candleStyle.downColor': '#FF4500',
            'mainSeriesProperties.candleStyle.borderUpColor': '#008000',
            'mainSeriesProperties.candleStyle.borderDownColor': '#8B0000',
            'mainSeriesProperties.candleStyle.wickUpColor': '#32CD32',
            'mainSeriesProperties.candleStyle.wickDownColor': '#FF4500',
          },
          charts_storage_url: 'https://saveload.tradingview.com',
          charts_storage_api_version: '1.1',
          client_id: 'trading_dashboard',
          user_id: 'public_user',
          datafeed: {
            onReady: (cb) => {
              setTimeout(() => {
                cb({
                  supports_time: true,
                  supports_search: false,
                  supports_group_request: false,
                  supports_marks: true,
                  supports_timescale_marks: false,
                  supports_history: true,
                });
              }, 0);
            },
            resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
              setTimeout(() => {
                onSymbolResolvedCallback({
                  name: symbolName,
                  ticker: symbolName,
                  session: '24x7',
                  timezone: 'Etc/UTC',
                  minmov: 1,
                  pricescale: 100000000,
                  has_intraday: true,
                  volume_precision: 8,
                  exchange: 'DOH.MONEY',
                  supported_resolutions: ['1', '5', '15', '30', '60', '240', 'D', 'W', 'M'],
                });
              }, 0);
            },
            getBars: (symbolInfo, resolution, { from, to }, onHistoryCallback) => {
              const sortedData = [...chartData].sort((a, b) => a.time - b.time);
              const convertedData = sortedData.map(bar => ({
                time: bar.time < 10000000000 ? bar.time * 1000 : bar.time,
                open: Number(bar.open),
                high: Number(bar.high),
                low: Number(bar.low),
                close: Number(bar.close),
                volume: bar.volume ? Number(bar.volume) : 0,
              }));
              const bars = convertedData.filter(
                bar => bar.time >= from * 1000 && bar.time <= to * 1000
              );
              if (bars.length === 0) {
                onHistoryCallback([], { noData: true });
              } else {
                onHistoryCallback(bars, { noData: false });
              }
            },
            getMarks: (symbolInfo, from, to, onDataCallback, resolution) => {
              const marks = (transactions || [])
                .filter(tx => tx.transaction_type)
                .map(tx => ({
                  id: tx.hash,
                  time: Math.floor(new Date(tx.timestamp).getTime() / 1000),
                  color: tx.transaction_type.toLowerCase().includes('buy') ? '#32CD32' : '#FF4500',
                  text: tx.transaction_type,
                  label: tx.hash.slice(0, 6) + '...',
                }))
                .filter(mark => mark.time >= from && mark.time <= to);
              onDataCallback(marks, { noData: marks.length === 0 });
            },
            subscribeBars: (symbolInfo, resolution, onRealtimeCallback, listenerGUID) => {},
            unsubscribeBars: (listenerGUID) => {},
          },
          enabled_features: ['study_templates'],
        });
        console.log("Custom TradingView widget initialized.");
      } catch (e) {
        console.error("Error initializing custom TradingView widget:", e);
      }
    }, 0);
  }, [libLoaded, chartData, isDarkMode, pairName, transactions]);

  // Cleanup widget on component unmount.
  useEffect(() => {
    return () => {
      if (widgetRef.current) {
        try {
          widgetRef.current.remove();
        } catch (err) {
          console.error("Error during widget cleanup:", err);
        }
        widgetRef.current = null;
      }
    };
  }, []);

  return (
    <Box
      sx={{
        height: '600px',
        width: '100%',
        minHeight: '300px',
        backgroundColor: isDarkMode ? '#1E1E1E' : '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
        p: 1,
      }}
    >
      {/* The inner div that will contain the TradingView widget */}
      <div
        ref={chartContainerRef}
        style={{ height: '100%', width: '100%' }}
      />
      {loading && (
        <>
          <Skeleton variant="rectangular" width="100%" height="400px" sx={{ borderRadius: '8px' }} />
          <Typography variant="body1" mt={2} sx={{ color: 'text.secondary', position: 'absolute' }}>
            Loading Chart...
          </Typography>
        </>
      )}
      {error && (
        <Typography color="error" sx={{ position: 'absolute' }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default TradingChart;