import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TokenSupplyViewer = () => {
    const [poolData, setPoolData] = useState([]); // State to hold fetched pool data
    const [tokens, setTokens] = useState([]); // State to hold unique tokens for dropdown
    const [selectedToken, setSelectedToken] = useState(''); // State to hold the selected token ID
    const [totalSupply, setTotalSupply] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null); // State to hold error messages
    const [matchedPoolInfo, setMatchedPoolInfo] = useState(null); // State to hold matched pool info

    const API_URL = 'https://dohcloud.azurewebsites.net/api_doh_totalsupply'; // Ensure this is set in your .env

    // Fetch pool data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://dohcloud.azurewebsites.net/api_test_transactions',
                );
                const pools = response.data;
                setPoolData(pools);

                // Extract tokens from pool data (both elexiumPool and ayinPool)
                const extractedTokens = [];
                pools.forEach((pool) => {
                    if (pool.elexiumPool) {
                        // Ensure elexiumPool exists
                        const { token0, token1 } = pool.elexiumPool;

                        if (token0 && token0.address) {
                            extractedTokens.push({
                                address: token0.address,
                                id: token0.id,
                                symbol: token0.symbol,
                                name: token0.name,
                                logo: token0.logoURI || token0.logo, // Handle possible different logo keys
                            });
                        }

                        if (token1 && token1.address) {
                            extractedTokens.push({
                                address: token1.address,
                                id: token1.id,
                                symbol: token1.symbol,
                                name: token1.name,
                                logo: token1.logoURI || token1.logo,
                            });
                        }
                    }

                    if (pool.ayinPool) {
                        // Ensure ayinPool exists
                        const { token0, token1 } = pool.ayinPool;

                        if (token0 && token0.address) {
                            extractedTokens.push({
                                address: token0.address,
                                id: token0.id,
                                symbol: token0.symbol,
                                name: token0.name,
                                logo: token0.logoURI || token0.logo, // Handle possible different logo keys
                            });
                        }

                        if (token1 && token1.address) {
                            extractedTokens.push({
                                address: token1.address,
                                id: token1.id,
                                symbol: token1.symbol,
                                name: token1.name,
                                logo: token1.logoURI || token1.logo,
                            });
                        }
                    }
                });

                // Remove duplicate tokens based on address (case-insensitive)
                const uniqueTokensMap = {};
                extractedTokens.forEach((token) => {
                    const lowerCaseAddress = token.address.toLowerCase();
                    if (!uniqueTokensMap[lowerCaseAddress]) {
                        uniqueTokensMap[lowerCaseAddress] = token;
                    }
                });

                const uniqueTokens = Object.values(uniqueTokensMap);
                setTokens(uniqueTokens);
                console.log(
                    'Unique tokens extracted for dropdown:',
                    uniqueTokens,
                );
            } catch (error) {
                console.error('Error fetching pool data:', error.message);
                setErrorMessage('Failed to fetch pool data.');
            }
        };

        fetchData();
    }, []);

    const handleTokenChange = (e) => {
        const selectedId = e.target.value;
        setSelectedToken(selectedId);
        setTotalSupply(null); // Reset previous total supply
        setTransactionDetails(null); // Reset previous transaction details
        setErrorMessage(null); // Reset previous error messages
        setMatchedPoolInfo(null); // Reset previous matched pool info
    };

    const handleFetchTotalSupply = async () => {
        if (!selectedToken) {
            setErrorMessage('Please select a token from the dropdown.');
            return;
        }

        setLoading(true);
        setErrorMessage(null); // Reset previous error messages

        try {
            // Find the selected token details from tokens list
            const token = tokens.find(
                (t) => t.id.toLowerCase() === selectedToken.toLowerCase(),
            );

            if (!token) {
                setErrorMessage('Selected token not found in the tokens list.');
                setLoading(false);
                return;
            }

            // Call the Azure Function API
            const response = await axios.get(API_URL, {
                params: {
                    tokenId: token.id,
                    tokenAddress: token.address,
                },
            });

            const { totalSupply, transactionDetails } = response.data;

            // Set matched pool info (optional, based on your requirements)
            const matchedPool = poolData.find(
                (pool) =>
                    (pool.elexiumPool &&
                        (pool.elexiumPool.token0.id.toLowerCase() ===
                            selectedToken.toLowerCase() ||
                            pool.elexiumPool.token1.id.toLowerCase() ===
                                selectedToken.toLowerCase())) ||
                    (pool.ayinPool &&
                        (pool.ayinPool.token0.id.toLowerCase() ===
                            selectedToken.toLowerCase() ||
                            pool.ayinPool.token1.id.toLowerCase() ===
                                selectedToken.toLowerCase())),
            );

            if (matchedPool) {
                let matchedToken = null;

                if (
                    matchedPool.elexiumPool &&
                    (matchedPool.elexiumPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase() ||
                        matchedPool.elexiumPool.token1.id.toLowerCase() ===
                            selectedToken.toLowerCase())
                ) {
                    matchedToken =
                        matchedPool.elexiumPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase()
                            ? matchedPool.elexiumPool.token0
                            : matchedPool.elexiumPool.token1;
                } else if (
                    matchedPool.ayinPool &&
                    (matchedPool.ayinPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase() ||
                        matchedPool.ayinPool.token1.id.toLowerCase() ===
                            selectedToken.toLowerCase())
                ) {
                    matchedToken =
                        matchedPool.ayinPool.token0.id.toLowerCase() ===
                        selectedToken.toLowerCase()
                            ? matchedPool.ayinPool.token0
                            : matchedPool.ayinPool.token1;
                }

                if (matchedToken) {
                    setMatchedPoolInfo({
                        address: matchedToken.address,
                        tokenId: matchedToken.id,
                    });
                }
            }

            setTotalSupply(totalSupply);
            setTransactionDetails(transactionDetails);
        } catch (error) {
            console.error('Error fetching total supply:', error.message);
            setErrorMessage('Failed to fetch total supply.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                padding: '20px',
                fontFamily: 'Arial, sans-serif',
                maxWidth: '800px',
                margin: '0 auto',
            }}
        >
            <h1>Token Total Supply Viewer</h1>

            {/* Display Matched Pool Information */}
            {matchedPoolInfo && (
                <div
                    style={{
                        backgroundColor: '#f0f8ff',
                        padding: '15px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        border: '1px solid #add8e6',
                    }}
                >
                    <h2>Matched Pool Information</h2>
                    <p>
                        <strong>Address:</strong> {matchedPoolInfo.address}
                    </p>
                    <p>
                        <strong>Token ID:</strong> {matchedPoolInfo.tokenId}
                    </p>
                </div>
            )}

            {/* Display Error Message */}
            {errorMessage && (
                <div
                    style={{
                        backgroundColor: '#ffe6e6',
                        padding: '10px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        border: '1px solid #ffcccc',
                    }}
                >
                    <p style={{ color: '#cc0000' }}>{errorMessage}</p>
                </div>
            )}

            {/* Dropdown for Token Selection */}
            <div style={{ marginBottom: '20px' }}>
                <label
                    htmlFor="token-select"
                    style={{ marginRight: '10px', fontSize: '16px' }}
                >
                    Select a Token:
                </label>
                <select
                    id="token-select"
                    value={selectedToken}
                    onChange={handleTokenChange}
                    style={{
                        padding: '10px',
                        fontSize: '16px',
                        width: '60%',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                    }}
                >
                    <option value="">-- Select a Token --</option>
                    {tokens.map((token) => (
                        <option key={token.id} value={token.id}>
                            {token.name} ({token.symbol})
                        </option>
                    ))}
                </select>
                <button
                    onClick={handleFetchTotalSupply}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        border: 'none',
                        backgroundColor: '#4CAF50',
                        color: '#fff',
                        marginLeft: '10px',
                    }}
                    disabled={!selectedToken || loading}
                >
                    {loading ? 'Fetching...' : 'Fetch Total Supply'}
                </button>
            </div>

            {loading && <p>Loading...</p>}

            {totalSupply !== null && (
                <div
                    style={{
                        backgroundColor: '#e6ffe6',
                        padding: '15px',
                        marginBottom: '20px',
                        borderRadius: '5px',
                        border: '1px solid #b3ffb3',
                    }}
                >
                    <h3>Total Supply for Token:</h3>
                    <p>{totalSupply}</p>
                </div>
            )}

            {transactionDetails && (
                <div
                    style={{
                        backgroundColor: '#f9f9f9',
                        padding: '15px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                    }}
                >
                    <h3>Transaction Details:</h3>
                    <pre
                        style={{
                            maxHeight: '400px',
                            overflow: 'auto',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                        }}
                    >
                        {JSON.stringify(transactionDetails, null, 2)}
                    </pre>
                </div>
            )}
        </div>
    );
};

export default TokenSupplyViewer;
