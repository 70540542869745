import React, { useEffect, useState } from 'react';
import axios from 'axios';

const apiUrl =
    process.env.REACT_APP_API_URL || 'https://dohcloud.azurewebsites.net';

const NodeStats = () => {
    const [totalNodes, setTotalNodes] = useState(0);
    const [prodNodesCount, setProdNodesCount] = useState(0);
    const [testNodesCount, setTestNodesCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const fetchNodeStats = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/getnodestats`);
            const stats = response.data;

            setTotalNodes(stats.totalNodes || 0);
            setProdNodesCount(stats.prodNodes || 0);
            setTestNodesCount(stats.testNodes || 0);
        } catch (error) {
            console.error('Failed to fetch node stats:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNodeStats();
    }, []);

    if (loading) {
        return <p>Loading stats...</p>;
    }

    return (
        <div className="node-stats-container">
            {' '}
            {/* This container should match your updated CSS */}
            <div className="stat-card">
                <p className="stat-title">Deployed</p>
                <p className="stat-value">{totalNodes}</p>
            </div>
            <div className="stat-card">
                <p className="stat-title">Mainnet</p>
                <p className="stat-value">{prodNodesCount}</p>
            </div>
            <div className="stat-card">
                <p className="stat-title">Testnet</p>
                <p className="stat-value">{testNodesCount}</p>
            </div>
        </div>
    );
};

export default NodeStats;
