// src/DohnutRewards.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dohnutrewards.css';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
} from 'recharts'; // Import Recharts components

// Define colors for different rarities
const RARITY_COLORS = {
  Legendary: '#FFD700', // Gold
  Epic: '#8E44AD',      // Purple
  Rare: '#2980B9',      // Blue
  Common: '#95A5A6',    // Grey
  Unknown: '#6c757d',   // Dark Grey
};

// Helper Functions
const shortenAddress = (address) => {
  if (!address) return 'N/A';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const shortenTokenID = (tokenID) => {
  if (!tokenID) return 'N/A';
  return `${tokenID.slice(0, 10)}...${tokenID.slice(-10)}`;
};

// Loading Component
const Loading = () => {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
      <span>Loading data...</span>
    </div>
  );
};

// Error Component
const Error = ({ message }) => {
  return (
    <div className="error-container">
      <div className="error-message">
        <h2>Error</h2>
        <p>{message}</p>
      </div>
    </div>
  );
};

// CollapsibleCard Component: Allows us to toggle sections open/closed
const CollapsibleCard = ({ title, children, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="collapsible-card">
      <div
        className="collapsible-header"
        onClick={() => setOpen((prev) => !prev)}
      >
        <h2>{title}</h2>
        <span className={`chevron ${open ? 'open' : ''}`} />
      </div>
      {open && <div className="collapsible-content">{children}</div>}
    </div>
  );
};

// RewardPool Component
const RewardPool = ({ rewardPool }) => {
  return (
    <CollapsibleCard title="Reward Pool" defaultOpen>
      <div className="reward-pool">
        <div className="reward-pool-item">
          <span className="label">Balance:</span>
          <span className="value">{rewardPool.Balance} EX</span>
        </div>
        <div className="reward-pool-item">
          <span className="label">Last Updated:</span>
          <span className="value">
            {rewardPool.UpdatedAt
              ? new Date(rewardPool.UpdatedAt).toLocaleString()
              : 'N/A'}
          </span>
        </div>
      </div>
    </CollapsibleCard>
  );
};

// WalletRewards Component
const WalletRewards = ({ walletRewards }) => {
  const [search, setSearch] = useState('');

  // Filter the walletRewards based on user input
  const filteredRewards = walletRewards.filter((wallet) =>
    wallet.WalletAddress.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <CollapsibleCard title="Wallet Rewards">
      <div className="wallet-rewards-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search wallet address..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="table-responsive">
          <table className="reward-table">
            <thead>
              <tr>
                <th>Wallet</th>
                <th>Total Rewards (EX)</th>
                <th>Legendary</th>
                <th>Epic</th>
                <th>Rare</th>
                <th>Common</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {filteredRewards.map((wallet, index) => (
                <tr key={index} className="wallet-row">
                  <td>
                    <a
                      href={`https://explorer.alephium.org/addresses/${wallet.WalletAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="wallet-link"
                    >
                      {shortenAddress(wallet.WalletAddress)}
                    </a>
                  </td>
                  <td>{parseFloat(wallet.TotalRewards).toFixed(8)}</td>
                  <td>{wallet.LegendaryCount}</td>
                  <td>{wallet.EpicCount}</td>
                  <td>{wallet.RareCount}</td>
                  <td>{wallet.CommonCount}</td>
                  <td>{new Date(wallet.UpdatedAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CollapsibleCard>
  );
};

// NFTs Component
const NFTs = ({ nfts }) => {
  // Define CSS class suffix for different rarities
  const rarityClass = {
    Legendary: 'legendary',
    Epic: 'epic',
    Rare: 'rare',
    Common: 'common',
    Unknown: 'unknown',
  };

  const [search, setSearch] = useState('');
  
  // We can search by tokenID, NFT index, or holder’s address
  const filteredNFTs = nfts.filter((nft) => {
    const tokenIDMatch = nft.TokenID.toLowerCase().includes(search.toLowerCase());
    const holderMatch = nft.CurrentHolder.toLowerCase().includes(search.toLowerCase());
    const indexMatch = nft.NFTIndex?.toString().includes(search);
    return tokenIDMatch || holderMatch || indexMatch;
  });

  return (
    <CollapsibleCard title="NFTs">
      <div className="nft-container">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search by Token ID, Index, or Holder..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="table-responsive">
          <table className="nft-table">
            <thead>
              <tr>
                <th>Token ID</th>
                <th>NFT Index</th>
                <th>Rarity</th>
                <th>Current Holder</th>
                <th>Collection Address</th>
                <th>Collection ID</th>
              </tr>
            </thead>
            <tbody>
              {filteredNFTs.map((nft, index) => (
                <tr key={index} className={`nft-row ${rarityClass[nft.Rarity] || rarityClass.Unknown}`}>
                  <td>
                    <a
                      href={`https://explorer.alephium.org/tokens/${nft.TokenID}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="token-link"
                    >
                      {shortenTokenID(nft.TokenID)}
                    </a>
                  </td>
                  <td>{nft.NFTIndex}</td>
                  <td>
                    <span className={`badge badge-${rarityClass[nft.Rarity] || 'unknown'}`}>
                      {nft.Rarity}
                    </span>
                  </td>
                  <td>
                    <a
                      href={`https://explorer.alephium.org/addresses/${nft.CurrentHolder}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="holder-link"
                    >
                      {shortenAddress(nft.CurrentHolder)}
                    </a>
                  </td>
                  <td>{shortenAddress(nft.CollectionAddress)}</td>
                  <td>{shortenTokenID(nft.CollectionID)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </CollapsibleCard>
  );
};

// WhatAreDohnuts Component
const WhatAreDohnuts = () => {
  return (
    <CollapsibleCard title="What Are DOHnuts?" defaultOpen>
      <p>
        DOHnuts are exclusive NFTs that reward holders with weekly payouts in 
        <strong> $EX</strong> (<em>Elexium token</em>).
      </p>
      <h3>How It Works:</h3>
      <p>
        Each week, 50% of the $DOH project wallet’s LP staking rewards are 
        distributed to NFT holders. Proceeds from DOH sales (including royalties) 
        are reinvested into the DOHnut EX Lock, compounding weekly to boost rewards.
      </p>
      <h3>Rarity Rewards</h3>
      <ul>
        <li><strong>Legendary:</strong> 2.5% per NFT</li>
        <li><strong>Epic:</strong> 1.5% per NFT</li>
        <li><strong>Rare:</strong> 1% per NFT</li>
        <li><strong>Common:</strong> 0.7% per NFT</li>
      </ul>
      <p className="note">
        ⚠️ <strong>Note:</strong> Rewards shown during the epoch are estimates. 
        Final rewards will be calculated and airdropped after the epoch concludes. 
        Rewards paid via wallet{' '}
        <span className="wallet-address">
          18J6KUb768iVu6Y7dzFqPRRkum24aTobHjmTe6ztqb3eN
        </span>
      </p>
    </CollapsibleCard>
  );
};

// PastEpochRewards Component
const PastEpochRewards = () => {
  const [pastEpochs, setPastEpochs] = useState([
    { epoch: 'Epoch 1', reward: '150 EX' },
    { epoch: 'Epoch 2', reward: '127 EX' },
    { epoch: 'Epoch 3', reward: '77 EX' },
    { epoch: 'Epoch 4', reward: '47 EX' },
    { epoch: 'Epoch 5', reward: '50 EX' },
    { epoch: 'Epoch 6', reward: '40 EX' },
    { epoch: 'Epoch 7', reward: '45 EX' },
    { epoch: 'Epoch 8', reward: '55 EX' },
    // You can load more from the database if needed
  ]);

  return (
    <CollapsibleCard title="Past Epoch Rewards">
      <div className="past-epoch-rewards-container">
        <table className="epoch-table">
          <thead>
            <tr>
              <th>Epoch</th>
              <th>Reward</th>
            </tr>
          </thead>
          <tbody>
            {pastEpochs.map((epochData, index) => (
              <tr key={index}>
                <td>{epochData.epoch}</td>
                <td>{epochData.reward}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CollapsibleCard>
  );
};

// RarityDistributionChart Component
const RarityDistributionChart = ({ rarityCounts }) => {
  if (!rarityCounts || Object.keys(rarityCounts).length === 0) {
    return (
      <CollapsibleCard title="Rarity Distribution">
        <p>No Rarity Data Available.</p>
      </CollapsibleCard>
    );
  }

  // Prepare data for the Pie Chart
  const data = Object.keys(rarityCounts).map((rarity) => ({
    name: rarity,
    value: rarityCounts[rarity],
  })).filter(item => item.value > 0); // Filter out rarities with zero count

  if (data.length === 0) {
    return (
      <CollapsibleCard title="Rarity Distribution">
        <p>No Rarity Data Available.</p>
      </CollapsibleCard>
    );
  }

  return (
    <CollapsibleCard title="Rarity Distribution">
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={RARITY_COLORS[entry.name] || RARITY_COLORS.Unknown} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleCard>
  );
};

// RewardPoolHistoryChart Component
const RewardPoolHistoryChart = ({ rewardPoolHistory }) => {
  if (!rewardPoolHistory || rewardPoolHistory.length === 0) {
    return (
      <CollapsibleCard title="Reward Pool History">
        <p>No Reward Pool History Available.</p>
      </CollapsibleCard>
    );
  }

  // Prepare data for the Line Chart
  const data = rewardPoolHistory.map((record) => ({
    date: new Date(record.Timestamp).toLocaleDateString(),
    balance: parseFloat(record.Balance),
  }));

  return (
    <CollapsibleCard title="Reward Pool History">
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="balance" name="Balance (EX)" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleCard>
  );
};

// EpochRewardsChart Component
const EpochRewardsChart = ({ epochRewardsHistory }) => {
  if (!epochRewardsHistory || epochRewardsHistory.length === 0) {
    return (
      <CollapsibleCard title="Epoch Rewards Over Time">
        <p>No Epoch Rewards Data Available.</p>
      </CollapsibleCard>
    );
  }

  // Prepare data for the Bar Chart
  const data = epochRewardsHistory.map((record) => ({
    epoch: `Epoch ${record.epoch}`,
    reward: parseFloat(record.Reward),
  }));

  return (
    <CollapsibleCard title="Epoch Rewards Over Time">
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="epoch" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="reward" name="Reward (EX)" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleCard>
  );
};

// Main DohnutRewards Component
function DohnutRewards() {
  const [data, setData] = useState(null); // Holds the fetched data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // Fetch data from the API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://dohcloud.azurewebsites.net/api_doh_rewardsplatform_fetch'
        );

        console.log('API Response:', response.data); // Debugging log

        if (response.data.success) {
          setData(response.data.data);
        } else {
          setError(response.data.error || 'Failed to fetch data from the API.');
        }
      } catch (err) {
        setError('An error occurred while fetching data.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <div className="rewards-platform-container">
      <header className="header-bar">
        <h1 className="main-header">DOH Rewards Platform</h1>
      </header>

      {/* Info / Intro Section */}
      <WhatAreDohnuts />

      {/* Reward Pool Section */}
      {data.rewardPool && <RewardPool rewardPool={data.rewardPool} />}

      {/* Charts Section */}
      <div className="analytics-charts">
        {/* Rarity Distribution Chart */}
        {data.nftRarityHistory && Object.keys(data.nftRarityHistory).length > 0 && (
          <RarityDistributionChart rarityCounts={data.nftRarityHistory} />
        )}

        {/* Reward Pool History Chart */}
        {data.history?.rewardPoolHistory && data.history.rewardPoolHistory.length > 0 && (
          <RewardPoolHistoryChart rewardPoolHistory={data.history.rewardPoolHistory} />
        )}

        {/* Epoch Rewards Over Time Chart */}
        {data.history?.epochRewardsHistory && data.history.epochRewardsHistory.length > 0 && (
          <EpochRewardsChart epochRewardsHistory={data.history.epochRewardsHistory} />
        )}
      </div>

      {/* Past Epoch Rewards Section */}
      <PastEpochRewards />

      {/* Wallet Rewards Section */}
      {data.walletRewards && data.walletRewards.length > 0 && (
        <WalletRewards walletRewards={data.walletRewards} />
      )}

      {/* NFTs Section */}
      {data.nfts && data.nfts.length > 0 && <NFTs nfts={data.nfts} />}

      {/* Footer */}
      <footer className="footer">
        <p>
          Powered by DOH &nbsp;|&nbsp;{' '}
          <a href="https://explorer.alephium.org/" target="_blank" rel="noopener noreferrer">
            Alephium Explorer
          </a>
        </p>
      </footer>
    </div>
  );
}

export default DohnutRewards;
