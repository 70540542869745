import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  TextField,
  Button,
  CssBaseline,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Collapse,
} from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon, // Imported ExpandLessIcon
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
} from 'recharts';
import { format } from 'date-fns';

// Dark Theme Configuration with Enhanced Colors
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1e88e5',
    },
    secondary: {
      main: '#d81b60',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bdbdbd',
    },
  },
});

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  margin: '16px 0',
  padding: '16px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 6px 30px rgba(0, 0, 0, 0.7)',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.background.paper,
}));

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#A28EFF',
  '#FF6699',
  '#33CCFF',
  '#FF33A8',
  '#66FF66',
  '#FF9933',
];

const TokenMetrics = () => {
  // State Variables
  const [tokens, setTokens] = useState([]);
  const [selectedTokenId, setSelectedTokenId] = useState('');
  const [selectedTokenPools, setSelectedTokenPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [walletSummary, setWalletSummary] = useState([]);
  const [walletLoading, setWalletLoading] = useState(false);
  const [walletError, setWalletError] = useState(null);
  const [poolSummaryData, setPoolSummaryData] = useState(null);
  const [openSections, setOpenSections] = useState({
    transactionDetails: false,
    totals: false,
    gainers: false,
    losers: false,
  });

  // Environment Variables (Ensure these are set in your .env file)
  const TOKEN_LIST_URL =
    'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json';
  const FETCH_POOLS_URL = process.env.REACT_APP_POOL_API_URL; 
  const FETCH_POOLSUMMARY_URL = process.env.REACT_APP_FETCH_POOLSUMMARY_URL; 
  const FETCH_WALLETSUMMARY_URL = process.env.REACT_APP_FETCH_WALLETSUMMARY_URL;
  const SUBSCRIPTION_KEY = process.env.REACT_APP_SUBSCRIPTION_KEY;
  // Constants for Token IDs (Update as per actual IDs)
  const ALPH_TOKEN_ID =
    '0000000000000000000000000000000000000000000000000000000000000000'; // Represents Alephium (ALPH)
  const DOH_TOKEN_ID =
    '93db3ced5685c6e5438afcc94fc20a1bca8885be75c02f6f164da30f217c2500'; // Represents DOH

  // Helper Function to Copy Text to Clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Copied to clipboard:', text);
      },
      (err) => {
        console.error('Failed to copy:', err);
      }
    );
  };

  /**
   * Formats a blockchain address by truncating it and wrapping it in a clickable link.
   * @param {string} address - The full blockchain address.
   * @returns {JSX.Element} - A clickable link with the formatted address.
   */
  const formatAddress = (address) => (
    address ? (
      <a
        href={`${EXPLORER_BASE_URL}/addresses/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: '#1e88e5' }}
        aria-label={`View address ${address} on Alephium Explorer`}
      >
        {address.slice(0, 6)}...{address.slice(-4)}
      </a>
    ) : 'N/A'
  );

  const EXPLORER_BASE_URL = 'https://explorer.alephium.org';

  /**
   * Processes the list of transactions to compute overall totals, gainers, and losers.
   * @param {Array<object>} transactions - The list of transaction objects.
   * @returns {object} - An object containing transactionDetails, overallTotals, gainers, and losers.
   */
  const processPoolSummary = (transactions) => {
    const transactionDetails = transactions;

    const overallTotalsMap = {};
    const gainersMap = {};
    const losersMap = {};

    transactions.forEach((tx) => {
      const {
        pool_name,
        gain_loss,
        transaction_type,
        got_tokens,
        for_tokens,
        initiating_address,
        ratio,
      } = tx;

      if (!pool_name) return;

      // Initialize overallTotals for the pool if not present
      if (!overallTotalsMap[pool_name]) {
        overallTotalsMap[pool_name] = {
          pool_name,
          transaction_count: 0,
          total_gain_loss: 0,
          total_buy_volume: 0,
          buy_transaction_count: 0,
          total_sell_volume: 0,
          sell_transaction_count: 0,
          total_tokens_transacted: 0,
        };
      }

      const poolTotal = overallTotalsMap[pool_name];
      poolTotal.transaction_count += 1;
      poolTotal.total_gain_loss += gain_loss || 0;

      if (transaction_type === 'BUY') {
        poolTotal.total_buy_volume += for_tokens || 0; // Assuming 'for_tokens' represents the amount spent
        poolTotal.buy_transaction_count += 1;
      }

      if (transaction_type === 'SELL') {
        poolTotal.total_sell_volume += for_tokens || 0;
        poolTotal.sell_transaction_count += 1;
      }

      poolTotal.total_tokens_transacted += (got_tokens || 0) + (for_tokens || 0);

      // For gainers
      if (gain_loss && gain_loss > 0) {
        if (!gainersMap[pool_name]) {
          gainersMap[pool_name] = [];
        }
        gainersMap[pool_name].push({
          pool_name,
          initiating_address,
          total_gain_loss: gain_loss,
        });
      }

      // For losers
      if (gain_loss && gain_loss < 0) {
        if (!losersMap[pool_name]) {
          losersMap[pool_name] = [];
        }
        losersMap[pool_name].push({
          pool_name,
          initiating_address,
          total_gain_loss: gain_loss,
        });
      }
    });

    // Convert overallTotalsMap to array and compute averages as per SP
    const overallTotals = Object.values(overallTotalsMap).map((pool) => ({
      pool_name: pool.pool_name,
      transaction_count: pool.transaction_count,
      total_gain_loss: pool.total_gain_loss.toFixed(2),
      avg_buy_price:
        pool.buy_transaction_count > 0
          ? (pool.total_buy_volume / pool.buy_transaction_count).toFixed(8)
          : 'N/A',
      avg_sell_price:
        pool.sell_transaction_count > 0
          ? (pool.total_sell_volume / pool.sell_transaction_count).toFixed(8)
          : 'N/A',
      total_tokens_transacted: pool.total_tokens_transacted.toFixed(2),
    }));

    // For gainers: take top 10 per pool
    const gainers = [];
    Object.entries(gainersMap).forEach(([pool_name, txs]) => {
      const sortedGainers = txs
        .sort((a, b) => b.total_gain_loss - a.total_gain_loss)
        .slice(0, 10);
      gainers.push(...sortedGainers);
    });

    // For losers: take top 10 per pool
    const losers = [];
    Object.entries(losersMap).forEach(([pool_name, txs]) => {
      const sortedLosers = txs
        .sort((a, b) => a.total_gain_loss - b.total_gain_loss)
        .slice(0, 10);
      losers.push(...sortedLosers);
    });

    return {
      transactionDetails,
      overallTotals,
      gainers,
      losers,
    };
  };

  /**
   * Fetches the list of tokens from the Alephium token list repository.
   */
  useEffect(() => {
    const fetchTokenList = async () => {
      try {
        const response = await axios.get(TOKEN_LIST_URL);
        setTokens(response.data.tokens || []);
      } catch (err) {
        setError('Failed to fetch token list.');
        console.error(err);
      }
    };
    fetchTokenList();
  }, [TOKEN_LIST_URL]);

    /**
   * Fetches pools based on the selected token.
   */
    useEffect(() => {
      if (!selectedTokenId) return;
  
      const fetchPoolsForToken = async () => {
        setLoading(true);
        setError(null);
        try {
          const url = `${FETCH_POOLS_URL}${selectedTokenId}`;
          const response = await axios.get(url, {
            headers: {
              'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY, // Add subscription key here
            },
          });
          setSelectedTokenPools(response.data || []);
        } catch (err) {
          setError('Failed to fetch pools for the selected token.');
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      fetchPoolsForToken();
    }, [selectedTokenId, FETCH_POOLS_URL, SUBSCRIPTION_KEY]);
  

  /**
   * Fetches and processes pool summary data based on the selected token.
   */
  useEffect(() => {
    if (!selectedTokenId) return;

    const fetchPoolSummary = async () => {
      try {
        const url = `${FETCH_POOLSUMMARY_URL}${selectedTokenId}`;
        const response = await axios.get(url, {
          headers: {
            'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY, // Add subscription key here
          },
        });
        console.log('Pool Summary Data:', response.data); // Debugging line to verify data structure

        // Assuming response.data is a list of transactions
        const transactions = response.data || [];
        const processedData = processPoolSummary(transactions);
        setPoolSummaryData(processedData);
      } catch (err) {
        setError('Failed to fetch pool summary data.');
        console.error(err);
      }
    };

    fetchPoolSummary();
  }, [selectedTokenId, FETCH_POOLSUMMARY_URL, SUBSCRIPTION_KEY]);

   /**
   * Fetches wallet summary based on wallet address and selected token ID.
   */
   const fetchWalletSummary = async () => {
    if (!walletAddress || !selectedTokenId) {
      setWalletError('Please enter a valid wallet address and select a token.');
      return;
    }
    setWalletLoading(true);
    setWalletError(null);
    try {
      const url = `${FETCH_WALLETSUMMARY_URL}${selectedTokenId}/${walletAddress}`;
      const response = await axios.get(url, {
        headers: {
          'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY, // Add subscription key here
        },
      });
      setWalletSummary(response.data || []);
    } catch (err) {
      setWalletError('Failed to fetch wallet summary.');
      console.error(err);
    } finally {
      setWalletLoading(false);
    }
  };
  /**
   * Handles token selection from the dropdown.
   * @param {object} event - The event object from the select input.
   */
  const handleTokenSelection = (event) => {
    const newTokenId = event.target.value;
    setSelectedTokenId(newTokenId);
    setSelectedTokenPools([]); // Clear pools when changing token
    setPoolSummaryData(null); // Clear pool summary when changing token
    setWalletSummary([]); // Optionally, clear wallet summary when changing token
  };

  /**
   * Toggles the expansion of a specific section.
   * @param {string} section - The section to toggle ('transactionDetails', 'totals', 'gainers', 'losers').
   */
  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Loading Indicator
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  /**
   * Retrieves the symbol for a given token ID.
   * @param {string} tokenId - The ID of the token.
   * @returns {string} - The symbol of the token or a formatted address if not found.
   */
  const getTokenSymbol = (tokenId) => {
    if (!tokenId) return 'N/A';
    // Handle special tokens
    if (tokenId === ALPH_TOKEN_ID) {
      return 'ALPH';
    }
    if (tokenId === DOH_TOKEN_ID) {
      return 'DOH';
    }

    // Lookup in the token list
    const token = tokens.find((t) => t.id === tokenId);
    return token ? token.symbol : formatAddress(tokenId);
  };

  // Helper to format Pool Name as Pair Name
  const formatPoolName = (pool) => {
    const token0Symbol = pool.Tokens.Token0.symbol;
    const token1Symbol = pool.Tokens.Token1.symbol;
    return `${token0Symbol}/${token1Symbol} - ${pool.Name}`;
  };

  // Helper to format Pool ID
  const formatPoolId = (poolId) => {
    if (!poolId || poolId.length < 8) return poolId;
    return `${poolId.slice(0, 4)}...${poolId.slice(-4)}`;
  };

  // Prepare data for Overall Totals Bar Chart
  const overallTotalsChartData = poolSummaryData
    ? poolSummaryData.overallTotals.map((pool) => ({
        pool_name: pool.pool_name,
        total_tokens_transacted: parseFloat(pool.total_tokens_transacted),
      }))
    : [];

  // Prepare data for Gain/Loss Pie Chart
  const gainLossData = poolSummaryData
    ? [
        {
          name: 'Total Gain',
          value: poolSummaryData.overallTotals.reduce(
            (acc, pool) => acc + parseFloat(pool.total_gain_loss),
            0
          ),
        },
        {
          name: 'Total Loss',
          value: Math.abs(
            poolSummaryData.overallTotals.reduce(
              (acc, pool) => acc + parseFloat(pool.total_gain_loss),
              0
            )
          ),
        },
      ]
    : [];

  // Prepare data for Net Gain/Loss Line Chart
  const netGainLossChartData = poolSummaryData
    ? poolSummaryData.overallTotals.map((pool) => ({
        pool_name: pool.pool_name,
        net_gain_loss: parseFloat(pool.total_gain_loss),
      }))
    : [];

  // Prepare data for Wallet Summary Buy/Sell Counts
  const walletBuySellCounts = walletSummary.reduce(
    (acc, tx) => {
      if (tx.transaction_type === 'BUY') acc.buy += 1;
      if (tx.transaction_type === 'SELL') acc.sell += 1;
      return acc;
    },
    { buy: 0, sell: 0 }
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline /> {/* Apply global CSS reset for dark mode */}
      <Box p={4}>
        <Typography variant="h4" gutterBottom align="center">
          Financial Dashboard
        </Typography>

        {/* Token Selection Dropdown */}
        <FormControl fullWidth variant="outlined">
          <InputLabel id="token-select-label">Select a Token</InputLabel>
          <Select
            labelId="token-select-label"
            value={selectedTokenId}
            onChange={handleTokenSelection}
            label="Select a Token"
            displayEmpty
          >
            <MenuItem value="">
              <em>-- Select a Token --</em>
            </MenuItem>
            {tokens.map((token) => (
              <MenuItem key={token.id} value={token.id}>
                {token.name} ({token.symbol})
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Error Message */}
        {error && (
          <Box mt={2}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Box>
        )}

        {/* Display Pools for Selected Token */}
        {selectedTokenId && !loading && (
          <>
            {selectedTokenPools.length === 0 ? (
              <Typography mt={2} align="center">
                No pools found for this token.
              </Typography>
            ) : (
              <Box mt={4}>
                <Typography variant="h5" gutterBottom align="center">
                  Pools for{' '}
                  {tokens.find((token) => token.id === selectedTokenId)?.name}
                </Typography>
                <Grid container spacing={3} justifyContent="center">
                  {selectedTokenPools.map((pool) => (
                    <Grid item xs={12} sm={6} md={4} key={pool.PoolID}>
                      <Accordion
                        TransitionProps={{ unmountOnExit: true }}
                        sx={{
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                          aria-controls={`panel-${pool.PoolID}-content`}
                          id={`panel-${pool.PoolID}-header`}
                        >
                          <Box display="flex" alignItems="center" width="100%">
                            {/* Overlapping Token Logos */}
                            <Box position="relative" width={60} height={60}>
                              <Avatar
                                src={pool.Tokens.Token0.logo}
                                alt={pool.Tokens.Token0.symbol}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  border: '2px solid white',
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  zIndex: 2,
                                  transition: 'transform 0.3s',
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                  },
                                }}
                              />
                              <Avatar
                                src={pool.Tokens.Token1.logo}
                                alt={pool.Tokens.Token1.symbol}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  border: '2px solid white',
                                  position: 'absolute',
                                  top: 0,
                                  left: 20,
                                  zIndex: 1,
                                  transition: 'transform 0.3s',
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                  },
                                }}
                              />
                            </Box>
                            <Box ml={2}>
                              <Typography variant="h6" color="#ffffff">
                                {formatPoolName(pool)}
                              </Typography>
                            </Box>
                            {/* Exchange Logo */}
                            <Box ml="auto">
                              <Avatar
                                src={pool.ExchangeLogo} // Assuming ExchangeLogo URL is provided in the pool data
                                alt={pool.Exchange}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  border: '2px solid white',
                                  transition: 'transform 0.3s',
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <Typography variant="body2" gutterBottom>
                              <strong>Exchange:</strong> {pool.Exchange}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Stable:</strong> {pool.Stable ? 'Yes' : 'No'}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              <strong>Pool ID:</strong> {formatPoolId(pool.PoolID)}
                            </Typography>
                            <Box mt={1}>
                              <Typography variant="body2" gutterBottom>
                                <strong>Tokens:</strong>
                              </Typography>
                              <ul>
                                <li>
                                  {pool.Tokens.Token0.name} (
                                  {pool.Tokens.Token0.symbol}) -{' '}
                                  {formatAddress(pool.Tokens.Token0.address)}
                                  <Tooltip title="Copy Address">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        copyToClipboard(pool.Tokens.Token0.address)
                                      }
                                    >
                                      <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </li>
                                <li>
                                  {pool.Tokens.Token1.name} (
                                  {pool.Tokens.Token1.symbol}) -{' '}
                                  {formatAddress(pool.Tokens.Token1.address)}
                                  <Tooltip title="Copy Address">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        copyToClipboard(pool.Tokens.Token1.address)
                                      }
                                    >
                                      <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </li>
                              </ul>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        )}

        {/* Charts and Tables */}
        {poolSummaryData && (
          <Box mt={6}>
            <Grid container spacing={4}>
              {/* Overall Totals Bar Chart */}
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom align="center">
                      Total Tokens Transacted per Pool
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={overallTotalsChartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="pool_name"
                          tick={{ fontSize: 12 }}
                          interval={0}
                          angle={-45}
                          textAnchor="end"
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Bar
                          dataKey="total_tokens_transacted"
                          fill="#1e88e5"
                          animationDuration={1500}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>

              {/* Gain/Loss Pie Chart */}
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom align="center">
                      Overall Gain vs Loss
                    </Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Pie
                          data={gainLossData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                          isAnimationActive={true}
                        >
                          {gainLossData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>

              {/* Net Gain/Loss Line Chart */}
              <Grid item xs={12}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h6" gutterBottom align="center">
                      Net Gain/Loss per Pool
                    </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart
                        data={netGainLossChartData}
                        margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="pool_name"
                          tick={{ fontSize: 12 }}
                          interval={0}
                          angle={-45}
                          textAnchor="end"
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Line
                          type="monotone"
                          dataKey="net_gain_loss"
                          stroke="#ff7300"
                          strokeWidth={2}
                          activeDot={{ r: 8 }}
                          isAnimationActive={true}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </CardContent>
                </StyledCard>
              </Grid>
            </Grid>

            {/* Collapsible Tables */}
            <Box mt={4}>
              <Grid container spacing={3}>
                {/* Transaction Details */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6">Transaction Details</Typography>
                        <Button
                          variant="outlined"
                          onClick={() => toggleSection('transactionDetails')}
                          endIcon={
                            openSections.transactionDetails ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                        >
                          {openSections.transactionDetails ? 'Hide' : 'Show'}
                        </Button>
                      </Box>
                      <Collapse in={openSections.transactionDetails}>
                        <Box mt={2}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Transaction Type</StyledTableCell>
                                  <StyledTableCell>Pool Name</StyledTableCell>
                                  <StyledTableCell>Wallet</StyledTableCell>
                                  <StyledTableCell>For Tokens</StyledTableCell>
                                  <StyledTableCell>Got Tokens</StyledTableCell>
                                  <StyledTableCell>Ratio</StyledTableCell>
                                  <StyledTableCell>Gain/Loss</StyledTableCell>
                                  <StyledTableCell>Timestamp</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {poolSummaryData.transactionDetails.map((entry) => (
                                  <TableRow key={entry.hash}>
                                    <TableCell>{entry.transaction_type}</TableCell>
                                    <TableCell>{entry.pool_name}</TableCell>
                                    <TableCell>{formatAddress(entry.initiating_address)}</TableCell> {/* Added Wallet Column */}
                                    <TableCell>
                                      {entry.for_tokens !== null
                                        ? entry.for_tokens
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {entry.got_tokens !== null
                                        ? entry.got_tokens
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {entry.ratio !== null
                                        ? entry.ratio.toFixed(8)
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {entry.gain_loss !== null
                                        ? entry.gain_loss.toFixed(2)
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {format(
                                        new Date(entry.timestamp),
                                        'PPpp'
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Overall Totals */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6">
                          Overall Totals for Each Pool
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() => toggleSection('totals')}
                          endIcon={openSections.totals ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                          {openSections.totals ? 'Hide' : 'Show'}
                        </Button>
                      </Box>
                      <Collapse in={openSections.totals}>
                        <Box mt={2}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Pool Name</StyledTableCell>
                                  <StyledTableCell>Transaction Count</StyledTableCell>
                                  <StyledTableCell>Total Gain/Loss</StyledTableCell>
                                  <StyledTableCell>Avg Buy Price</StyledTableCell>
                                  <StyledTableCell>Avg Sell Price</StyledTableCell>
                                  <StyledTableCell>Total Tokens Transacted</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {poolSummaryData.overallTotals.map((entry) => (
                                  <TableRow key={entry.pool_name}>
                                    <TableCell>{entry.pool_name}</TableCell>
                                    <TableCell>{entry.transaction_count}</TableCell>
                                    <TableCell>{entry.total_gain_loss}</TableCell>
                                    <TableCell>
                                      {entry.avg_buy_price !== 'N/A'
                                        ? entry.avg_buy_price
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                      {entry.avg_sell_price !== 'N/A'
                                        ? entry.avg_sell_price
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>{entry.total_tokens_transacted}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Top Gainers */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6">Top 10 Gainers for Each Pool</Typography>
                        <Button
                          variant="outlined"
                          onClick={() => toggleSection('gainers')}
                          endIcon={openSections.gainers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                          {openSections.gainers ? 'Hide' : 'Show'}
                        </Button>
                      </Box>
                      <Collapse in={openSections.gainers}>
                        <Box mt={2}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Pool Name</StyledTableCell>
                                  <StyledTableCell>Wallet Address</StyledTableCell>
                                  <StyledTableCell>Total Gain/Loss</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {poolSummaryData.gainers.length > 0 ? (
                                  poolSummaryData.gainers.map((entry, index) => (
                                    <TableRow
                                      key={`${entry.pool_name}-${entry.initiating_address}-${index}`}
                                      hover
                                      style={{ cursor: 'pointer' }}
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = '#333')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = 'inherit')
                                      }
                                    >
                                      <TableCell>{entry.pool_name}</TableCell>
                                      <TableCell>
                                        {formatAddress(entry.initiating_address)}
                                      </TableCell>
                                      <TableCell>{entry.total_gain_loss.toFixed(2)}</TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={3} align="center">
                                      No data available.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Top Losers */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h6">Top 10 Losers for Each Pool</Typography>
                        <Button
                          variant="outlined"
                          onClick={() => toggleSection('losers')}
                          endIcon={openSections.losers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                          {openSections.losers ? 'Hide' : 'Show'}
                        </Button>
                      </Box>
                      <Collapse in={openSections.losers}>
                        <Box mt={2}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Pool Name</StyledTableCell>
                                  <StyledTableCell>Wallet Address</StyledTableCell>
                                  <StyledTableCell>Total Loss</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {poolSummaryData.losers.length > 0 ? (
                                  poolSummaryData.losers.map((entry, index) => (
                                    <TableRow
                                      key={`${entry.pool_name}-${entry.initiating_address}-${index}`}
                                      hover
                                      style={{ cursor: 'pointer' }}
                                      onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = '#333')
                                      }
                                      onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = 'inherit')
                                      }
                                    >
                                      <TableCell>{entry.pool_name}</TableCell>
                                      <TableCell>
                                        {formatAddress(entry.initiating_address)}
                                      </TableCell>
                                      <TableCell>{entry.total_gain_loss.toFixed(2)}</TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={3} align="center">
                                      No data available.
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        {/* Wallet Address Input */}
        <Box mt={6}>
          <Typography variant="h5" gutterBottom align="center">
            Wallet Address Summary
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                fullWidth
                label="Wallet Address"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} display="flex" alignItems="center">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={fetchWalletSummary}
                disabled={walletLoading || !walletAddress || !selectedTokenId}
                endIcon={walletLoading ? <CircularProgress size={20} /> : null}
              >
                {walletLoading ? 'Fetching...' : 'Get Summary'}
              </Button>
            </Grid>
          </Grid>
          {walletError && (
            <Box mt={2} textAlign="center">
              <Typography color="error">{walletError}</Typography>
            </Box>
          )}
          <Box mt={4}>
            {walletSummary.length > 0 ? (
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" gutterBottom align="center">
                    Transaction Summary for {formatAddress(walletAddress)}
                  </Typography>

                  {/* Buy and Sell Counts */}
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <StyledCard>
                        <CardContent>
                          <Typography variant="subtitle1" align="center">
                            Buy Transactions
                          </Typography>
                          <Typography variant="h4" align="center" color="primary">
                            {walletBuySellCounts.buy}
                          </Typography>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <StyledCard>
                        <CardContent>
                          <Typography variant="subtitle1" align="center">
                            Sell Transactions
                          </Typography>
                          <Typography variant="h4" align="center" color="secondary">
                            {walletBuySellCounts.sell}
                          </Typography>
                        </CardContent>
                      </StyledCard>
                    </Grid>
                  </Grid>

                  {/* Wallet Summary Table */}
                  <Box mt={2}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Transaction Type</StyledTableCell>
                            <StyledTableCell>Pool Name</StyledTableCell>
                            <StyledTableCell>Wallet</StyledTableCell>
                            <StyledTableCell>For Tokens</StyledTableCell>
                            <StyledTableCell>Got Tokens</StyledTableCell>
                            <StyledTableCell>Ratio</StyledTableCell>
                            <StyledTableCell>Gain/Loss</StyledTableCell>
                            <StyledTableCell>Timestamp</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {walletSummary.map((entry) => (
                            <TableRow key={entry.hash}>
                              <TableCell>{entry.transaction_type}</TableCell>
                              <TableCell>{entry.pool_name}</TableCell>
                              <TableCell>{formatAddress(entry.initiating_address)}</TableCell> {/* Added Wallet Column */}
                              <TableCell>
                                {entry.for_tokens !== null
                                  ? entry.for_tokens
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>
                                {entry.got_tokens !== null
                                  ? entry.got_tokens
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>
                                {entry.ratio !== null
                                  ? entry.ratio.toFixed(8)
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>
                                {entry.gain_loss !== null
                                  ? entry.gain_loss.toFixed(2)
                                  : 'N/A'}
                              </TableCell>
                              <TableCell>
                                {format(
                                  new Date(entry.timestamp),
                                  'PPpp'
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </CardContent>
              </StyledCard>
            ) : (
              <Typography align="center">No wallet data available.</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TokenMetrics;
