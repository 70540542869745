import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './auth';
import Navbar from './navbar';
import Footer from './footer';
import NodeManager from './nodemanager/nodemanager';
import HomePage from './homepage';
import Tokens from './tokens/tokens';
import WorkflowStatusPage from './nodemanager/workflowstatuspage';
import './app.css'; // Ensure this includes your custom CSS
import customTheme from './customTheme'; // Assuming this is used elsewhere or can be removed if not needed
import { AlephiumWalletProvider } from '@alephium/web3-react';
import DOHmail from './dohmail/dohmail';
import NodeDetails from './nodemanager/nodedetails';
import Pools from './tokens/pools';
import Wallet from './tokens/wallet';
import TokenCatcher from './tokens/tokencatcher';
import Poker from './games/poker/poker';
import { WalletModalProvider } from './walletmodalcontext';
import WalletConnectModal from './walletconnectmodal';
import TokenBubbles from './tokens/tokenbubbles';
import MintUploader from './mint/mintuploader';
import ProfitLoss from './tokens/profitloss';
import Dohnut from './dohnuts/dohnut';
import TestBalances from './tokens/testbalances';
import HistoricalTrades from './tokens/historicaltrades';
import TokenTradeHistory from './tokens/tradehistory';
import TokenList from './tokens/tokenlist_test';
import TransactionProcessor from './utils/transactionprocessor';
import NFTOwners from './utils_novercel/fetch2';
import DohnutRewards from './utils/dohnutrewards';
import TokenTransactionAnalyzer from './utils/transactions';
import TokenTransaction_V2 from './v2/transactions';
import TokenListPools from './tokens/tokenlist_pools';
import TotalSupply from './utils_novercel/totalsupply';
import TokenMetrics from './v2/tokenmetrics';
import Odyssey from './utils_novercel/odysseyfe';
import TradingDashboard from './v2/TradingDashboard';
import TokenChartPageWrapper from './v2/singlechart';
import Game from './games/game'; // <-- Import your game component here
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import TestWebsocketConnection from './v2/testwebpubsub';
import SimpleLiveTransactions from './v2/test_livetransactions';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const queryClient = new QueryClient();

const walletProviderConfig = {
    network: 'mainnet',
    rpcUrl: 'https://rpc.alephium.org',
};

const App = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Manage theme state: light or dark
    const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');

    // Update CSS classes based on theme mode
    useEffect(() => {
        const container = document.getElementById('app-container');
        if (container) {
            container.className = `token-list-container ${mode}`;
        }
    }, [mode]);

    // Disable Right-Click and common key combinations for inspecting the code
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleKeyDown = (e) => {
            // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U, etc.
            if (
                e.key === 'F12' ||
                (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J')) ||
                (e.ctrlKey && e.key.toLowerCase() === 'u') ||
                (e.ctrlKey && e.key.toLowerCase() === 's')
            ) {
                e.preventDefault();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    // Toggle theme mode
    const toggleTheme = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    // Create MUI theme based on mode
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                    primary: {
                        main: '#FFD700', // Gold color to match your custom CSS
                    },
                    secondary: {
                        main: '#007bff', // Blue color to match your custom CSS
                    },
                    background: {
                        default: mode === 'dark' ? '#121212' : '#f5f5f5',
                        paper: mode === 'dark' ? '#1e1e1e' : '#ffffff',
                    },
                    text: {
                        primary: mode === 'dark' ? '#ffffff' : '#000000',
                        secondary: '#bdbdbd',
                    },
                },
                typography: {
                    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
                },
            }),
        [mode],
    );

    return (
        <AlephiumWalletProvider config={walletProviderConfig}>
            <WalletModalProvider>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <BrowserRouter>
                                <div
                                    id="app-container"
                                    className={`token-list-container ${mode}`}
                                >
                                    <Navbar
                                        onSearch={handleSearch}
                                        toggleTheme={toggleTheme}
                                        mode={mode}
                                    />
                                    <div className="main-content">
                                        <Routes>
                                            <Route path="/nodes" element={<NodeManager />} />
                                            <Route path="/home" element={<HomePage />} />
                                            <Route path="/tokens" element={<Tokens searchQuery={searchQuery} />} />
                                            <Route path="/tokencatcher" element={<TokenCatcher />} />
                                            <Route path="/poker" element={<Poker />} />
                                            <Route path="/workflow-status" element={<WorkflowStatusPage />} />
                                            <Route path="/tokenbubbles" element={<TokenBubbles />} />
                                            <Route path="/mintuploader" element={<MintUploader />} />
                                            <Route path="/wallet" element={<Wallet />} />
                                            <Route path="/profitloss" element={<ProfitLoss />} />
                                            <Route path="/balances" element={<TestBalances />} />
                                            <Route path="/historicaltrades" element={<HistoricalTrades />} />
                                            <Route path="/tokentradehistory" element={<TokenTradeHistory />} />
                                            <Route path="/tokenlist" element={<TokenList />} />
                                            <Route path="/nftowners" element={<NFTOwners />} />
                                            <Route path="/tradehistory" element={<TokenTradeHistory />} />
                                            <Route path="/historicaltrades" element={<HistoricalTrades />} />
                                            <Route path="/transactionprocessor" element={<TransactionProcessor />} />
                                            <Route path="/transactions" element={<TokenTransactionAnalyzer />} />
                                            <Route path="/nftowners" element={<NFTOwners />} />
                                            <Route path="/dohnut" element={<Dohnut />} />
                                            <Route path="/pools" element={<TokenListPools />} />
                                            <Route path="/totalsupply" element={<TotalSupply />} />
                                            <Route path="/dohnutrewards" element={<DohnutRewards />} />
                                            <Route path="/transaction_v2" element={<TokenTransaction_V2 />} />
                                            <Route path="/" element={<TradingDashboard />} />
                                            <Route path="/TokenMetrics" element={<TokenMetrics />} />
                                            <Route path="/odyssey" element={<Odyssey />} />
                                            <Route path="/singlechart" element={<TokenChartPageWrapper />} />
                                            {/* New Route for the Game */}
                                            <Route path="/game" element={<Game />} />
                                            <Route path="/testwebsocket" element={<TestWebsocketConnection />} />
                                            <Route path="/test_livetransactions" element={<SimpleLiveTransactions />} />
                                        </Routes>
                                    </div>
                                    <Footer />
                                </div>
                                <WalletConnectModal />
                            </BrowserRouter>
                        </ThemeProvider>
                    </AuthProvider>
                </QueryClientProvider>
            </WalletModalProvider>
        </AlephiumWalletProvider>
    );
};

export default App;