import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Typography,
  Tooltip,
  IconButton,
  Box,
  Grid,
  Paper,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Button,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  SwapHoriz as SwapHorizIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as d3 from 'd3';

const EXPLORER_BASE_URL = 'https://explorer.alephium.org';
const SUBSCRIPTION_KEY =
  process.env.NEXT_PUBLIC_APP ||
  process.env.REACT_APP_SUBSCRIPTION_KEY ||
  'fallback-subscription-key';

const ALPH_TOKEN_ID = '0000000000000000000000000000000000000000000000000000000000000000';
const DOH_TOKEN_ID = '93db3ced5685c6e5438afcc94fc20a1bca8885be75c02f6f164da30f217c2500';

// Define DEX logo URLs for Ayin, Elexium, and Onion
const DEX_LOGOS = {
  Ayin: "https://raw.githubusercontent.com/alephium/token-list/master/logos/AYIN.png",
  Elexium: "https://raw.githubusercontent.com/alephium/token-list/master/logos/EX.png",
  Onion: "https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/onion_logo.png",
};

const transactionTypeMap = {
  BUY: {
    color: '#2e7d32',
    bg: 'transparent',
    width: '50%',
    icon: (
      <span
        style={{
          display: 'inline-block',
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#2e7d32',
          marginRight: 4,
        }}
      />
    ),
  },
  SELL: {
    color: '#c62828',
    bg: 'transparent',
    width: '50%',
    icon: (
      <span
        style={{
          display: 'inline-block',
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#c62828',
          marginRight: 4,
        }}
      />
    ),
  },
  'LP Deposit': {
    color: '#1976d2',
    bg: 'transparent',
    width: '50%',
    icon: (
      <span
        style={{
          display: 'inline-block',
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#1976d2',
          marginRight: 4,
        }}
      />
    ),
  },
  'LP Withdraw': {
    color: '#6a1b9a',
    bg: 'transparent',
    width: '50%',
    icon: (
      <span
        style={{
          display: 'inline-block',
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#6a1b9a',
          marginRight: 4,
        }}
      />
    ),
  },
  Routed: {
    color: '#ef6c00',
    bg: 'transparent',
    width: '50%',
    icon: (
      <span
        style={{
          display: 'inline-block',
          width: 6,
          height: 6,
          borderRadius: '50%',
          backgroundColor: '#ef6c00',
          marginRight: 4,
          opacity: 0.8,
        }}
      />
    ),
  },
};

const flashGold = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;
const flashRed = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

// Main container updated for scrollability.
const TransactionContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#141414',
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'auto',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderRight: '1px solid rgba(255, 255, 255, 0.1)',
  color: theme.palette.text.primary,
  fontSize: '0.75rem',
  padding: '4px 6px',
  whiteSpace: 'nowrap',
  WebkitFontSmoothing: 'antialiased',
  backfaceVisibility: 'hidden',
}));

const PairLogoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 32,
  height: 32,
}));

const PairLogoImage = styled('img')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  position: 'absolute',
  top: 0,
}));

// Helper to transform token logos
const transformLogo = (logo) => {
  if (!logo) return null;
  return logo.startsWith('http')
    ? logo
    : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${logo}`;
};

const TokenLogoOrFallback = ({ src, alt, symbol, tokenId, sx }) => {
  const [hasError, setHasError] = useState(false);
  return (
    <Tooltip title={`Token ID: ${tokenId}`} arrow>
      <Box
        component="span"
        onClick={() => navigator.clipboard.writeText(tokenId).catch((err) => console.error("Failed to copy:", err))}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&:hover': { color: '#FFD700' },
          ...sx,
        }}
      >
        {hasError || !src ? (
          <Box
            sx={{
              width: 16,
              height: 16,
              mr: 0.5,
              borderRadius: '50%',
              backgroundColor: '#ccc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.65rem',
              color: '#fff',
            }}
          >
            ?
          </Box>
        ) : (
          <Box
            component="img"
            src={src}
            alt={alt}
            sx={{ width: 16, height: 16, mr: 0.5, borderRadius: '50%', ...sx }}
            onError={() => setHasError(true)}
          />
        )}
      </Box>
    </Tooltip>
  );
};

TokenLogoOrFallback.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  symbol: PropTypes.string,
  tokenId: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

const getTokenData = (tokenId, tokenList, poolData) => {
  if (!tokenId) return { symbol: "Unknown", logo: null, tokenId };
  let token = tokenList.find((t) => t.id === tokenId);
  if (token) return { ...token, logo: transformLogo(token.logo) };
  const pool = poolData.find((p) => p.PoolID === tokenId);
  if (pool) {
    return {
      symbol: `${pool.Token0Symbol}/${pool.Token1Symbol} LP`,
      logo: transformLogo(pool.Logo),
      tokenId,
    };
  }
  const poolToken = poolData.find((p) => p.Token0ID === tokenId || p.Token1ID === tokenId);
  if (poolToken) {
    return {
      symbol: poolToken.Token0ID === tokenId ? poolToken.Token0Symbol : poolToken.Token1Symbol,
      logo: transformLogo(poolToken.Token0ID === tokenId ? poolToken.Token0Logo : poolToken.Token1Logo),
      tokenId,
    };
  }
  return { symbol: "Unknown", logo: null, tokenId };
};

const formatRatio = (ratio) => {
  if (ratio == null || isNaN(ratio)) return 'N/A';
  const num = parseFloat(ratio);
  return num >= 1 ? num.toFixed(3) : num.toFixed(5).replace(/^0+/, '');
};

const safeJsonParse = (str) => {
  try {
    const parsed = typeof str === 'object' ? str : JSON.parse(str);
    return parsed || {};
  } catch (e) {
    console.error('JSON parse error:', e, 'Input:', str);
    return {};
  }
};

const formatNumber = (num, decimals = 4) => {
  if (num == null || isNaN(num)) return 'N/A';
  return Number(num).toFixed(decimals);
};

const formatWalletAddress = (address) => {
  if (!address) return 'N/A';
  return `...${address.slice(-5)}`;
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`transaction-tabpanel-${index}`}
      aria-labelledby={`transaction-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SparklineChart = ({ data, width = 200, height = 50 }) => {
  const ref = useRef();
  useEffect(() => {
    const svg = d3.select(ref.current);
    svg.selectAll("*").remove();
    const margin = { top: 5, right: 5, bottom: 5, left: 5 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const xScale = d3.scaleLinear().domain([0, data.length - 1]).range([0, innerWidth]);
    const yScale = d3.scaleLinear().domain(d3.extent(data)).nice().range([innerHeight, 0]);
    const line = d3.line().x((d, i) => xScale(i)).y(d => yScale(d)).curve(d3.curveMonotoneX);
    const g = svg.append("g").attr("transform", `translate(${margin.left}, ${margin.top})`);
    g.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", line);
  }, [data, width, height]);
  return <svg ref={ref} width={width} height={height} />;
};

SparklineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

// NEW: Added onLoadAllTransactions prop is used to trigger fetching all transactions
const TokenTransactionV2 = ({
  transactions,
  poolData,
  tokenList,
  onPoolSelect,
  searchQuery: externalSearchQuery = '',
  onWalletClick,
  onLoadAllTransactions,
}) => {
  const storedFilters = JSON.parse(localStorage.getItem('transactionFilters') || '{}');
  const [searchQuery, setSearchQuery] = useState(storedFilters.searchQuery || externalSearchQuery);
  const [filters, setFilters] = useState(storedFilters.filters || { type: '' });
  const [selectedFiat, setSelectedFiat] = useState('USD');
  const fiatConversionRates = {
    USD: 0.99,
    EUR: 0.90,
    GBP: 0.80,
  };

  useEffect(() => {
    localStorage.setItem('transactionFilters', JSON.stringify({ searchQuery, filters }));
  }, [searchQuery, filters]);

  const [walletDetails, setWalletDetails] = useState({});
  const [expandedRow, setExpandedRow] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderByState, setOrderByState] = useState('time');
  const [displayCount, setDisplayCount] = useState(50);
  // Use loadingTransactions to show spinner until data is fetched
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched && transactions && transactions.length >= 0) {
      setLoadingTransactions(false);
      setHasFetched(true);
    }
  }, [transactions, hasFetched]);

  const poolMap = useMemo(() => {
    return poolData.reduce((acc, pool) => {
      acc[pool.PoolID] = pool;
      return acc;
    }, {});
  }, [poolData]);

  const fetchWalletDetails = useCallback(async (tokenId, initiatingAddress, hash) => {
    const apiUrl = `https://api.doh.money/api/v2_walletsummary/${tokenId}/${initiatingAddress}`;
    try {
      setWalletDetails((prev) => ({
        ...prev,
        [hash]: { data: null, loading: true, error: null },
      }));
      const response = await axios.get(apiUrl, {
        headers: {
          'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setWalletDetails((prev) => ({
        ...prev,
        [hash]: { data: response.data, loading: false, error: null },
      }));
    } catch (err) {
      setWalletDetails((prev) => ({
        ...prev,
        [hash]: { data: null, loading: false, error: err.message || 'Failed to fetch wallet details' },
      }));
    }
  }, []);

  const handleExpandClick = useCallback(
    (hash, initiatingAddress, tokenId) => {
      const isExpanded = expandedRow === hash;
      setExpandedRow(isExpanded ? null : hash);
      if (!isExpanded && tokenId && initiatingAddress) {
        fetchWalletDetails(tokenId, initiatingAddress, hash);
        const pool = poolMap[tokenId];
        if (pool && onPoolSelect) {
          onPoolSelect(pool);
        }
      }
    },
    [expandedRow, poolMap, fetchWalletDetails, onPoolSelect]
  );

  const comparator = useCallback(
    (a, b) => {
      let comparison = 0;
      if (orderByState === 'ratio') {
        comparison = parseFloat(a.ratio) - parseFloat(b.ratio);
      } else if (orderByState === 'type') {
        comparison = (a.type || '').localeCompare(b.type || '');
      } else if (orderByState === 'time') {
        comparison = new Date(a.timestamp || 0) - new Date(b.timestamp || 0);
      }
      return order === 'asc' ? comparison : -comparison;
    },
    [order, orderByState]
  );

  const handleSort = (column) => {
    if (orderByState === column) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderByState(column);
      setOrder('desc');
    }
  };

  const processedTransactions = useMemo(() => {
    return transactions.map((tx) => {
      const gotTokens = safeJsonParse(tx.gotTokens);
      const forTokens = safeJsonParse(tx.forTokens);
      let pool = poolData.find((p) => p.PoolID === tx.poolId);
      if (!pool) {
        const tokenKeys = [...Object.keys(gotTokens), ...Object.keys(forTokens)];
        pool = poolData.find((p) =>
          tokenKeys.includes(p.Token0ID) || tokenKeys.includes(p.Token1ID)
        );
      }
      const dexName = pool?.PoolExchange || 'Unknown DEX';
      let dexLogo = null;
      if (dexName.toLowerCase().includes('ayin')) {
        dexLogo = DEX_LOGOS.Ayin;
      } else if (dexName.toLowerCase().includes('elexium') || dexName.toLowerCase().includes('ex')) {
        dexLogo = DEX_LOGOS.Elexium;
      } else if (dexName.toLowerCase().includes('onion')) {
        dexLogo = DEX_LOGOS.Onion;
      }
      return {
        ...tx,
        gotTokens,
        forTokens,
        type: tx.transactionType || 'N/A',
        ratio: tx.ratio || 'N/A',
        dex: dexName,
        dexLogo: dexLogo,
        ALPH_PRICE: tx.ALPH_PRICE || 'N/A',
      };
    }).sort(comparator);
  }, [transactions, poolData, comparator]);

  const filteredTransactions = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return processedTransactions.filter((tx) => {
      const poolName = poolMap[tx.poolId]?.PoolName || '';
      const matchesSearch =
        (tx.hash && tx.hash.toLowerCase().includes(query)) ||
        (tx.initiatingAddress && tx.initiatingAddress.toLowerCase().includes(query)) ||
        poolName.toLowerCase().includes(query);
      const matchesType = filters.type ? tx.type === filters.type : true;
      const statusCondition = tx.status ? tx.status.toLowerCase() === 'success' : true;
      return statusCondition && matchesSearch && matchesType;
    });
  }, [processedTransactions, poolMap, searchQuery, filters]);

  const calculateWalletTotals = useCallback((details, poolName, initiatingAddress) => {
    if (!details || details.length === 0) {
      return {
        poolName: poolName || 'N/A',
        initiatingAddress: formatWalletAddress(initiatingAddress),
        transactionCount: 0,
        buys: 0,
        sells: 0,
        avgBuyPrice: 'N/A',
        avgSellPrice: 'N/A',
        totalGainLoss: 'N/A',
      };
    }
    let buys = 0, sells = 0, totalBuyPrice = 0, totalSellPrice = 0, gainLoss = 0;
    details.forEach((detail) => {
      if (detail.transaction_type === 'BUY') {
        buys += 1;
        totalBuyPrice += parseFloat(detail.buy_price) || 0;
        gainLoss += parseFloat(detail.gain_loss) || 0;
      } else if (detail.transaction_type === 'SELL') {
        sells += 1;
        totalSellPrice += parseFloat(detail.sell_price) || 0;
        gainLoss += parseFloat(detail.gain_loss) || 0;
      }
    });
    const avgBuyPrice = buys > 0 ? (totalBuyPrice / buys).toFixed(6) : 'N/A';
    const avgSellPrice = sells > 0 ? (totalSellPrice / sells).toFixed(6) : 'N/A';
    return {
      poolName: poolName || 'N/A',
      initiatingAddress: formatWalletAddress(initiatingAddress),
      transactionCount: details.length,
      buys,
      sells,
      avgBuyPrice,
      avgSellPrice,
      totalGainLoss: gainLoss.toFixed(6),
    };
  }, []);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <TransactionContainer>
      <Grid container spacing={1} alignItems="center" mb={1}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Search Transactions"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Hash, Wallet, or Pool Name"
            fullWidth
            InputProps={{ sx: { backgroundColor: 'background.paper', color: 'text.primary' } }}
            InputLabelProps={{ sx: { color: 'text.secondary', fontSize: '0.75rem' } }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Fiat</InputLabel>
            <Select
              value={selectedFiat}
              onChange={(e) => setSelectedFiat(e.target.value)}
              label="Fiat"
              sx={{ fontSize: '0.75rem' }}
            >
              {Object.keys(fiatConversionRates).map((fiat) => (
                <MenuItem key={fiat} value={fiat} sx={{ fontSize: '0.75rem' }}>
                  {fiat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel>Tx Type</InputLabel>
            <Select
              value={filters.type}
              onChange={(e) => setFilters({ ...filters, type: e.target.value })}
              label="Tx Type"
              sx={{ fontSize: '0.75rem' }}
            >
              <MenuItem value="">All</MenuItem>
              {Object.keys(transactionTypeMap).map((type) => (
                <MenuItem key={type} value={type} sx={{ fontSize: '0.75rem' }}>
                  {type}
                </MenuItem>
              ))}
              <MenuItem value="N/A" sx={{ fontSize: '0.75rem' }}>Unknown</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Button to load all transactions */}
        <Grid item xs={12} sm={2}>
          <Button variant="outlined" size="small" fullWidth onClick={onLoadAllTransactions}>
            Load All Transactions
          </Button>
        </Grid>
      </Grid>
      {loadingTransactions ? (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={StyledPaper} sx={{ overflowX: 'auto' }}>
          <Table stickyHeader aria-label="transaction table">
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>
                  <Tooltip title="Sort by Time">
                    <Box display="flex" alignItems="center" onClick={() => handleSort('time')} sx={{ cursor: 'pointer' }}>
                      <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Time</Typography>
                      {orderByState === 'time' &&
                        (order === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ width: '50px' }}>
                  <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Tx</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Wallet</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Pool">
                    <Box display="flex" alignItems="center" sx={{ cursor: 'default' }}>
                      <Typography variant="body2" fontWeight="bold" fontSize="0.75rem" mr={1}>Pool</Typography>
                      <PairLogoContainer />
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Sort by Type">
                    <Box display="flex" alignItems="center" onClick={() => handleSort('type')} sx={{ cursor: 'pointer' }}>
                      <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Type</Typography>
                      {orderByState === 'type' &&
                        (order === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Got</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">For</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Sort by Ratio">
                    <Box display="flex" alignItems="center" onClick={() => handleSort('ratio')} sx={{ cursor: 'pointer' }}>
                      <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">Ratio</Typography>
                      {orderByState === 'ratio' &&
                        (order === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Sort by ALPH Price">
                    <Box display="flex" alignItems="center" onClick={() => handleSort('ALPH_PRICE')} sx={{ cursor: 'pointer' }}>
                      <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">ALPH Price</Typography>
                      {orderByState === 'ALPH_PRICE' &&
                        (order === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />)}
                    </Box>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="body2" fontWeight="bold" fontSize="0.75rem">DEX</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTransactions.slice(0, displayCount).map((tx) => {
                const isExpanded = expandedRow === tx.hash;
                let pool = poolData.find((p) => p.PoolID === tx.poolId);
                if (!pool) {
                  const tokenKeys = [
                    ...Object.keys(tx.gotTokens || {}),
                    ...Object.keys(tx.forTokens || {}),
                  ];
                  pool = poolData.find((p) =>
                    tokenKeys.includes(p.Token0ID) || tokenKeys.includes(p.Token1ID)
                  );
                }
                const ratioNum = parseFloat(tx.ratio);
                const fiatValue = (!isNaN(ratioNum) && fiatConversionRates[selectedFiat])
                  ? ratioNum * fiatConversionRates[selectedFiat]
                  : null;

                return (
                  <React.Fragment key={tx.hash}>
                    <TableRow
                      hover
                      onClick={() => handleExpandClick(tx.hash, tx.initiatingAddress, pool?.TokenID)}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: isExpanded ? 'rgba(255, 255, 255, 0.05)' : 'inherit',
                        transition: 'background-color 0.3s ease',
                        '&:hover': { backgroundColor: 'rgba(255,255,255,0.05)' },
                      }}
                    >
                      <StyledTableCell>
                        <Tooltip title={isExpanded ? 'Hide Details' : 'Show Details'}>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleExpandClick(tx.hash, tx.initiatingAddress, pool?.TokenID);
                            }}
                            size="small"
                          >
                            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        {tx.timestamp ? (
                          <Typography variant="body2" fontSize="0.75rem">
                            {new Date(tx.timestamp).toLocaleString()}
                          </Typography>
                        ) : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" alignItems="center">
                          <Tooltip title="View Transaction on Explorer">
                            <IconButton
                              component="a"
                              href={`${EXPLORER_BASE_URL}/transactions/${tx.hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              size="small"
                            >
                              <FingerprintIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {tx.isRealtime && (
                            <Typography variant="caption" sx={{
                              backgroundColor: '#FFD700',
                              color: '#000',
                              fontWeight: 'bold',
                              px: 0.5,
                              ml: 0.5,
                              borderRadius: 1,
                              fontSize: '0.6rem'
                            }}>
                              RT
                            </Typography>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="body2"
                            sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'primary.main' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (onWalletClick) onWalletClick(tx.initiatingAddress);
                            }}
                          >
                            {formatWalletAddress(tx.initiatingAddress)}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {pool ? (
                          <PairLogoContainer>
                            {pool.Token0Logo && (
                              <PairLogoImage src={pool.Token0Logo} alt={pool.Token0Symbol} style={{ left: 0 }} />
                            )}
                            {pool.Token1Logo && (
                              <PairLogoImage src={pool.Token1Logo} alt={pool.Token1Symbol} style={{ left: 16 }} />
                            )}
                          </PairLogoContainer>
                        ) : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          sx={{
                            backgroundColor: transactionTypeMap[tx.type]?.bg || '#e0e0e0',
                            color: transactionTypeMap[tx.type]?.color || 'text.primary',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                          }}
                        >
                          {transactionTypeMap[tx.type]?.icon || (
                            <HelpOutlineIcon fontSize="small" sx={{ width: 16, height: 16, mr: 0.5 }} />
                          )}
                          <Typography variant="body2" sx={{ fontSize: '0.75rem', ml: 0.5 }}>
                            {tx.type}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        {tx.gotTokens && Object.keys(tx.gotTokens).length > 0 ? (
                          Object.entries(tx.gotTokens).map(([tid, amt]) => {
                            const tokenData = getTokenData(tid, tokenList, poolData);
                            const logo = tokenData.logo;
                            const symbol = tokenData.symbol || "Unknown";
                            const formattedAmount = Number(amt).toFixed(2);
                            return (
                              <Tooltip key={tid} title={`Amount: ${formattedAmount} ${symbol}`} arrow>
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    mr: 1,
                                    backgroundColor: '#2C2C2C',
                                    borderRadius: 1,
                                    px: 0.5,
                                  }}
                                >
                                  {logo && (
                                    <TokenLogoOrFallback src={logo} alt={symbol} symbol={symbol} tokenId={tid} />
                                  )}
                                  <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#00c853' }}>
                                    +{formattedAmount}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="text.secondary" fontSize="0.75rem">N/A</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tx.forTokens && Object.keys(tx.forTokens).length > 0 ? (
                          Object.entries(tx.forTokens).map(([tid, amt]) => {
                            const tokenData = getTokenData(tid, tokenList, poolData);
                            const logo = tokenData.logo;
                            const symbol = tokenData.symbol || "Unknown";
                            const formattedAmount = Number(amt).toFixed(2);
                            return (
                              <Tooltip key={tid} title={`Amount: ${formattedAmount} ${symbol}`} arrow>
                                <Box
                                  component="span"
                                  sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    mr: 1,
                                    backgroundColor: '#2C2C2C',
                                    borderRadius: 1,
                                    px: 0.5,
                                  }}
                                >
                                  {logo && (
                                    <TokenLogoOrFallback src={logo} alt={symbol} symbol={symbol} tokenId={tid} />
                                  )}
                                  <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#d32f2c' }}>
                                    -{formattedAmount}
                                  </Typography>
                                </Box>
                              </Tooltip>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="text.secondary" fontSize="0.75rem">N/A</Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tx.ratio !== 'N/A' && !isNaN(tx.ratio) ? (
                          <Box>
                            <Typography variant="body2" sx={{ color: '#00c853', fontSize: '0.75rem' }}>
                              ℵ {formatRatio(tx.ratio)}
                            </Typography>
                            {fiatValue !== null && (
                              <Typography
                                variant="caption"
                                sx={{
                                  fontSize: '0.75rem',
                                  fontWeight: 'bold',
                                  backgroundColor: '#2C2C2C',
                                  borderRadius: 1,
                                  px: 0.5,
                                }}
                              >
                                {selectedFiat} {fiatValue.toFixed(4)}
                              </Typography>
                            )}
                          </Box>
                        ) : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {tx.ALPH_PRICE !== 'N/A' && !isNaN(tx.ALPH_PRICE) ? (
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              color: '#ffd700',
                              backgroundColor: '#2C2C2C',
                              borderRadius: 1,
                              px: 0.5,
                            }}
                          >
                            ${Number(tx.ALPH_PRICE).toFixed(2)}
                          </Typography>
                        ) : 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {tx.dexLogo ? (
                          <Tooltip title={tx.dex}>
                            <Box
                              component="img"
                              src={tx.dexLogo}
                              alt={`${tx.dex} Logo`}
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': { transform: 'scale(1.1)' },
                              }}
                            />
                          </Tooltip>
                        ) : tx.dex ? (
                          <Tooltip title={tx.dex}>
                            <SwapHorizIcon fontSize="small" sx={{ color: 'text.secondary' }} />
                          </Tooltip>
                        ) : (
                          <Typography variant="body2" fontSize="0.75rem" color="text.secondary">N/A</Typography>
                        )}
                      </StyledTableCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableCell colSpan={13} sx={{ padding: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 2 }}>
                            <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ mb: 2, fontSize: '0.75rem' }}>
                              <Tab label="Overview" id="transaction-tab-0" aria-controls="transaction-tabpanel-0" />
                              <Tab label="Pool Details" id="transaction-tab-1" aria-controls="transaction-tabpanel-1" />
                            </Tabs>
                            <TabPanel value={tabIndex} index={0}>
                              <StyledPaper sx={{ mb: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                  Tokens Overview
                                </Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'error.main', fontSize: '0.75rem' }}>
                                      Tokens Sent:
                                    </Typography>
                                    {tx.forTokens && Object.keys(tx.forTokens).length > 0 ? (
                                      Object.entries(tx.forTokens).map(([tid, amount]) => (
                                        <Typography key={tid} variant="body2" fontSize="0.75rem">
                                          {getTokenData(tid, tokenList, poolData).symbol}: -{Number(amount).toFixed(2)}
                                        </Typography>
                                      ))
                                    ) : (
                                      <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                        None
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'success.main', fontSize: '0.75rem' }}>
                                      Tokens Received:
                                    </Typography>
                                    {tx.gotTokens && Object.keys(tx.gotTokens).length > 0 ? (
                                      Object.entries(tx.gotTokens).map(([tid, amount]) => (
                                        <Typography key={tid} variant="body2" fontSize="0.75rem">
                                          {getTokenData(tid, tokenList, poolData).symbol}: +{Number(amount).toFixed(2)}
                                        </Typography>
                                      ))
                                    ) : (
                                      <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                        None
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </StyledPaper>
                              <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={6}>
                                  <StyledPaper>
                                    <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                      Initiating Address Details
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'error.main', fontSize: '0.75rem' }}>
                                      Sent by Initiating Address:
                                    </Typography>
                                    {(() => {
                                      const sentTokensObj = safeJsonParse(tx.sentTokens);
                                      const initiatingSent = sentTokensObj[tx.initiatingAddress] || {};
                                      return Object.entries(initiatingSent).length > 0 ? (
                                        Object.entries(initiatingSent).map(([tokenId, amount]) => (
                                          <Typography key={tokenId} variant="body2" fontSize="0.75rem">
                                            {getTokenData(tokenId, tokenList, poolData).symbol}: -{Number(amount).toFixed(2)}
                                          </Typography>
                                        ))
                                      ) : (
                                        <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                          None
                                        </Typography>
                                      );
                                    })()}
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'success.main', fontSize: '0.75rem', mt: 1 }}>
                                      Received by Initiating Address:
                                    </Typography>
                                    {(() => {
                                      const receivedTokensObj = safeJsonParse(tx.receivedTokens);
                                      const initiatingReceived = receivedTokensObj[tx.initiatingAddress] || {};
                                      return Object.entries(initiatingReceived).length > 0 ? (
                                        Object.entries(initiatingReceived).map(([tokenId, amount]) => (
                                          <Typography key={tokenId} variant="body2" fontSize="0.75rem">
                                            {getTokenData(tokenId, tokenList, poolData).symbol}: +{Number(amount).toFixed(2)}
                                          </Typography>
                                        ))
                                      ) : (
                                        <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                          None
                                        </Typography>
                                      );
                                    })()}
                                  </StyledPaper>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <StyledPaper>
                                    <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                      Pool Address Details
                                    </Typography>
                                    {(() => {
                                      const sentTokensObj = safeJsonParse(tx.sentTokens);
                                      const poolAddress = Object.keys(sentTokensObj).find(
                                        (addr) => addr !== tx.initiatingAddress
                                      );
                                      if (poolAddress) {
                                        return (
                                          <Box>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'error.main', fontSize: '0.75rem' }}>
                                              Sent by Pool Address:
                                            </Typography>
                                            {(() => {
                                              const poolSent = sentTokensObj[poolAddress] || {};
                                              return Object.entries(poolSent).length > 0 ? (
                                                Object.entries(poolSent).map(([tokenId, amount]) => (
                                                  <Typography key={tokenId} variant="body2" fontSize="0.75rem">
                                                    {getTokenData(tokenId, tokenList, poolData).symbol}: -{Number(amount).toFixed(2)}
                                                  </Typography>
                                                ))
                                              ) : (
                                                <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                                  None
                                                </Typography>
                                              );
                                            })()}
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'success.main', fontSize: '0.75rem', mt: 1 }}>
                                              Received by Pool Address:
                                            </Typography>
                                            {(() => {
                                              const receivedTokensObj = safeJsonParse(tx.receivedTokens);
                                              const poolReceived = receivedTokensObj[poolAddress] || {};
                                              return Object.entries(poolReceived).length > 0 ? (
                                                Object.entries(poolReceived).map(([tokenId, amount]) => (
                                                  <Typography key={tokenId} variant="body2" fontSize="0.75rem">
                                                    {getTokenData(tokenId, tokenList, poolData).symbol}: +{Number(amount).toFixed(2)}
                                                  </Typography>
                                                ))
                                              ) : (
                                                <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                                  None
                                                </Typography>
                                              );
                                            })()}
                                          </Box>
                                        );
                                      } else {
                                        return (
                                          <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                                            Pool address details not available.
                                          </Typography>
                                        );
                                      }
                                    })()}
                                  </StyledPaper>
                                </Grid>
                              </Grid>
                              {walletDetails[tx.hash]?.data && (
                                <StyledPaper sx={{ mb: 2 }}>
                                  <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                    Wallet Summary
                                  </Typography>
                                  {(() => {
                                    const summary = calculateWalletTotals(walletDetails[tx.hash].data, pool?.PoolName, tx.initiatingAddress);
                                    return (
                                      <Box>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                          <strong>Tx Count:</strong> {summary.transactionCount}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                          <strong>Buys:</strong> {summary.buys} &nbsp; <strong>Avg Buy Price:</strong> {summary.avgBuyPrice}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                          <strong>Sells:</strong> {summary.sells} &nbsp; <strong>Avg Sell Price:</strong> {summary.avgSellPrice}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'success.main' }}>
                                          <strong>Total Gain/Loss:</strong> {summary.totalGainLoss}
                                        </Typography>
                                      </Box>
                                    );
                                  })()}
                                </StyledPaper>
                              )}
                              {pool && (
                                <StyledPaper sx={{ mb: 2 }}>
                                  <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                    Pool Performance
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>First Ratio:</strong> {formatRatio(pool.FirstPoolRatio)} ℵ
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Latest Ratio:</strong> {formatRatio(pool.LatestPoolRatio)} ℵ
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Highest Ratio:</strong> {formatRatio(pool.HighestPoolRatio)} ℵ
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </StyledPaper>
                              )}
                              {pool && (
                                <StyledPaper sx={{ mb: 2 }}>
                                  <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                    Pool Statistics
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>First Pool Time:</strong> {new Date(pool.FirstPoolTimestamp).toLocaleString()}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>First Ratio:</strong> {formatRatio(pool.FirstPoolRatio)} ℵ
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Latest Pool Time:</strong> {new Date(pool.LatestPoolTimestamp).toLocaleString()}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Latest Ratio:</strong> {formatRatio(pool.LatestPoolRatio)} ℵ
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Highest Pool Time:</strong> {new Date(pool.HighestPoolTimestamp).toLocaleString()}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Highest Ratio:</strong> {formatRatio(pool.HighestPoolRatio)} ℵ
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Total Transactions:</strong> {pool.TransactionCount || 'N/A'}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Buys / Sells:</strong> {pool.Buys} / {pool.Sells}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Total Tokens Transacted:</strong> {pool.TotalTokensTransacted}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>24HR Volume:</strong> {pool.Volume_24HR || 'N/A'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </StyledPaper>
                              )}
                              {walletDetails[tx.hash]?.loading && (
                                <Box display="flex" alignItems="center">
                                  <CircularProgress size={20} />
                                  <Typography variant="body2" ml={2} fontSize="0.75rem">
                                    Loading Wallet Details...
                                  </Typography>
                                </Box>
                              )}
                              {walletDetails[tx.hash]?.error && (
                                <Alert severity="error" sx={{ fontSize: '0.75rem' }}>
                                  {walletDetails[tx.hash].error}
                                </Alert>
                              )}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                              <StyledPaper sx={{ mb: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                  Pool Details
                                </Typography>
                                {pool ? (
                                  <Box>
                                    <Box mb={1} display="flex" alignItems="center">
                                      <PairLogoContainer>
                                        {pool.Token0Logo && (
                                          <PairLogoImage src={pool.Token0Logo} alt={pool.Token0Symbol} style={{ left: 0 }} />
                                        )}
                                        {pool.Token1Logo && (
                                          <PairLogoImage src={pool.Token1Logo} alt={pool.Token1Symbol} style={{ left: 16 }} />
                                        )}
                                      </PairLogoContainer>
                                      <Typography variant="body2" ml={1}>
                                        {pool.Token0Symbol} / {pool.Token1Symbol}
                                      </Typography>
                                    </Box>
                                    <Typography variant="body2"><strong>Pool Name:</strong> {pool.PoolName}</Typography>
                                    <Typography variant="body2" mt={1}><strong>Exchange:</strong> {pool.PoolExchange || 'N/A'}</Typography>
                                    <Typography variant="body2" mt={1}><strong>Stable:</strong> {pool.Stable ? 'Yes' : 'No'}</Typography>
                                    <Typography variant="body2" mt={1}><strong>Decimals:</strong> {pool.Decimals || 'N/A'}</Typography>
                                    <Typography variant="body2" mt={1}><strong>Description:</strong> {pool.Description || 'N/A'}</Typography>
                                    <Typography variant="body2" mt={1}><strong>Latest Ratio:</strong> {formatRatio(pool.LatestPoolRatio)} ℵ</Typography>
                                    <Typography variant="body2" mt={1}><strong>Tx Count:</strong> {pool.TransactionCount || 'N/A'}</Typography>
                                  </Box>
                                ) : (
                                  <Typography variant="body2">No pool details available.</Typography>
                                )}
                              </StyledPaper>
                            </TabPanel>
                            {walletDetails[tx.hash]?.data && (
                              <StyledPaper sx={{ mb: 2 }}>
                                <Typography variant="h6" gutterBottom sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                  Wallet Summary
                                </Typography>
                                {(() => {
                                  const summary = calculateWalletTotals(walletDetails[tx.hash].data, pool?.PoolName, tx.initiatingAddress);
                                  return (
                                    <Box>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Tx Count:</strong> {summary.transactionCount}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Buys:</strong> {summary.buys} &nbsp; <strong>Avg Buy Price:</strong> {summary.avgBuyPrice}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                        <strong>Sells:</strong> {summary.sells} &nbsp; <strong>Avg Sell Price:</strong> {summary.avgSellPrice}
                                      </Typography>
                                      <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'success.main' }}>
                                        <strong>Total Gain/Loss:</strong> {summary.totalGainLoss}
                                      </Typography>
                                    </Box>
                                  );
                                })()}
                              </StyledPaper>
                            )}
                          </Box>
                        </Collapse>
                      </StyledTableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
              {displayCount < filteredTransactions.length && (
                <TableRow>
                  <StyledTableCell colSpan={13} align="center">
                    <Button variant="outlined" size="small" onClick={() => setDisplayCount(displayCount + 50)}>
                      Load More
                    </Button>
                  </StyledTableCell>
                </TableRow>
              )}
              {filteredTransactions.length === 0 && (
                <TableRow>
                  <StyledTableCell colSpan={13}>
                    {loadingTransactions ? (
                      <Box display="flex" alignItems="center" justifyContent="center" sx={{ padding: '20px 0' }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <Typography variant="body2" align="center" fontSize="0.75rem">
                        No transactions available.
                      </Typography>
                    )}
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </TransactionContainer>
  );
};

TokenTransactionV2.propTypes = {
  transactions: PropTypes.array.isRequired,
  poolData: PropTypes.array.isRequired,
  tokenList: PropTypes.array.isRequired,
  onPoolSelect: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  onWalletClick: PropTypes.func,
  onLoadAllTransactions: PropTypes.func,
};

export default TokenTransactionV2;