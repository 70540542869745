import React, { useState, useEffect } from 'react';
import Table from './table';
import PlayerHand from './playerhand';
import Controls from './controls';
import { createDeck, shuffleDeck } from './deck';
import { dealCards, dealCommunityCards } from '../utils/pokerutils';
import { evaluateHand } from '../utils/handevaluator';
import styles from './poker.module.css';

const Poker = () => {
    const [deck, setDeck] = useState([]);
    const [playerHands, setPlayerHands] = useState([]);
    const [communityCards, setCommunityCards] = useState([]);
    const [pot, setPot] = useState(0);
    const [currentPlayer, setCurrentPlayer] = useState(0);
    const [round, setRound] = useState('pre-flop');
    const [playerChips, setPlayerChips] = useState([1000, 1000]);
    const [winner, setWinner] = useState(null);
    const [gameOver, setGameOver] = useState(false);
    const [checksCount, setChecksCount] = useState(0);
    const [winningHand, setWinningHand] = useState('');

    useEffect(() => {
        startNewGame();
    }, []);

    const startNewGame = () => {
        const newDeck = shuffleDeck(createDeck());
        setDeck(newDeck);
        setPlayerHands(dealCards([...newDeck], 2));
        setCommunityCards([]);
        setPot(0);
        setCurrentPlayer(0);
        setRound('pre-flop');
        setWinner(null);
        setGameOver(false);
        setPlayerChips([1000, 1000]);
        setChecksCount(0);
        setWinningHand('');
    };

    const dealNextRound = () => {
        if (gameOver) return;
        let newCommunityCards = [...communityCards];

        if (round === 'pre-flop') {
            newCommunityCards = [
                ...newCommunityCards,
                ...dealCommunityCards([...deck], 3),
            ];
            setRound('post-flop');
        } else if (round === 'post-flop') {
            newCommunityCards = [
                ...newCommunityCards,
                ...dealCommunityCards([...deck], 1),
            ];
            setRound('turn');
        } else if (round === 'turn') {
            newCommunityCards = [
                ...newCommunityCards,
                ...dealCommunityCards([...deck], 1),
            ];
            setRound('river');
        } else if (round === 'river') {
            determineWinner();
            return;
        }
        setCommunityCards(newCommunityCards);
        setDeck((prevDeck) => prevDeck.slice(newCommunityCards.length));
        setChecksCount(0);
    };

    const determineWinner = () => {
        const [hand1, hand2] = playerHands;
        const player1HandResult = evaluateHand([...hand1, ...communityCards]);
        const player2HandResult = evaluateHand([...hand2, ...communityCards]);

        if (player1HandResult.rank > player2HandResult.rank) {
            setWinner('Player 1');
            setPlayerChips([playerChips[0] + pot, playerChips[1]]);
            setWinningHand(player1HandResult.type);
        } else if (player2HandResult.rank > player1HandResult.rank) {
            setWinner('Player 2');
            setPlayerChips([playerChips[0], playerChips[1] + pot]);
            setWinningHand(player2HandResult.type);
        } else {
            setWinner('Draw');
            setWinningHand('Both players have the same hand');
        }
        setGameOver(true);
    };

    const handleCheck = () => {
        if (checksCount >= 1) {
            dealNextRound();
        } else {
            setCurrentPlayer((prev) => (prev + 1) % 2);
            setChecksCount(checksCount + 1);
        }
    };

    const handleRaise = (amount) => {
        if (amount > playerChips[currentPlayer]) {
            alert("You don't have enough chips to raise that amount.");
            return;
        }

        const newPot = pot + amount;
        const newPlayerChips = [...playerChips];
        newPlayerChips[currentPlayer] -= amount;
        setPot(newPot);
        setPlayerChips(newPlayerChips);
        setCurrentPlayer((prev) => (prev + 1) % 2);
        setChecksCount(0);
    };

    const handleFold = () => {
        alert(`Player ${currentPlayer + 1} folded!`);
        setWinner(currentPlayer === 0 ? 'Player 2' : 'Player 1');
        setPlayerChips(
            currentPlayer === 0
                ? [playerChips[0], playerChips[1] + pot]
                : [playerChips[0] + pot, playerChips[1]],
        );
        setWinningHand('Player folded');
        setGameOver(true);
    };

    return (
        <div className={styles.app}>
            <h1 className={styles.title}>Poker Game</h1>
            <p className={styles.potInfo}>Pot: ${pot}</p>
            <div className={styles.table}>
                <div className={styles.communityCards}>
                    {communityCards.map((card, index) => (
                        <div key={index} className={styles.customCard}>
                            <span className={styles.cardValue}>
                                {card.value}
                            </span>
                            <span className={styles.cardSuit}>{card.suit}</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.players}>
                <PlayerHand
                    player="Player 1"
                    cards={playerHands[0] || []}
                    chips={playerChips[0]}
                    isActive={currentPlayer === 0}
                />
                <PlayerHand
                    player="Player 2"
                    cards={playerHands[1] || []}
                    chips={playerChips[1]}
                    isActive={currentPlayer === 1}
                />
            </div>
            <Controls
                onCheck={handleCheck}
                onRaise={handleRaise}
                onFold={handleFold}
            />
            <button
                onClick={dealNextRound}
                className={styles.controlButton}
                disabled={gameOver || checksCount < 1}
            >
                Next Round
            </button>
            {winner && (
                <h2 className={styles.title}>
                    {winner} Wins! {winningHand}
                </h2>
            )}
            {gameOver && (
                <button onClick={startNewGame} className={styles.newGameButton}>
                    Start New Game
                </button>
            )}
        </div>
    );
};

export default Poker;
