import React, { useEffect, useState, useMemo, useCallback, memo, Suspense } from 'react';
import axios from 'axios';
import * as d3 from 'd3';
import {
  ThemeProvider,
  CssBaseline,
  Box,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Tabs,
  Tab,
  Button,
  Skeleton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import {
  ContentCopy as ContentCopyIcon,
  InfoOutlined as InfoOutlinedIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import useSWR, { SWRConfig } from 'swr';
import WalletSummaryView from './walletsummary';
import PoolSummaryView from './poolsummary';
import getCustomTheme from './theme';
import GaugeChart from 'react-gauge-chart';
import useWebPubSub from './useWebPubSub';
import TokenHolders from './holders';
import LiveTransactions from './livetransactions';
import TradingViewChart from '../tokens/tradingviewchart';

// Lazy load heavy components
const TokenTransactionV2 = React.lazy(() => import('./transactions'));
const TradingChartWidget = React.lazy(() => import('./tradingchart'));

/* =========================
   Constants & Axios Setup
   ========================= */
const API_BASE =
  process.env.REACT_APP_BASE_API_URL 

const TRANSACTIONS_API_URL =
  process.env.REACT_APP_TRANSACTIONS_API_URL 

const CHARTDATA_API_URL =
  process.env.REACT_APP_CHARTDATA_API_URL 

const PUSH_SNAPSHOT_API_URL =
  process.env.REACT_APP_PUSH_SNAPSHOT_API_URL 

const FEARGREED_API_URL =
  process.env.REACT_APP_FEAR_GREED_API_URL 

const ONION_TOKENS_API_URL =
  process.env.REACT_APP_ONION_TOKEN_API_URL 

const POOLSUMMARY_API_URL =
  process.env.REACT_APP_POOLSUMMARY_API_URL

const SUBSCRIPTION_KEY = process.env.REACT_APP_PUBLIC 

const apiClient = axios.create({
  headers: { 'X-Verified-By': 'Vercel' },
});
apiClient.interceptors.request.use(
  (config) => {
    config.headers['Ocp-Apim-Subscription-Key'] = SUBSCRIPTION_KEY;
    return config;
  },
  (error) => Promise.reject(error)
);

const fetcher = (url) => apiClient.get(url).then((res) => res.data);

/* =========================
   Data Fetching Functions
   ========================= */
const fetchTransactions = ([url, tokenId, loadAll]) =>
  apiClient
    .get(url, { params: { token_id: tokenId, ...(loadAll ? { all: 'true' } : {}) } })
    .then((res) => {
      console.log('Transactions API response for token_id:', tokenId, res.data);
      return res.data.transactions || [];
    });

const fetchChartData = ([baseUrl, poolID, tokenID]) =>
  apiClient
    .get(`${baseUrl}/${poolID}`, {
      params: { tokenid: tokenID },
    })
    .then((res) => {
      const rawData = res.data || [];
      return rawData
        .filter((item) => item.time && item.close !== null)
        .map((item) => ({
          time: Number(item.time) / 1000,
          low: item.low ?? item.close ?? 0,
          high: item.high ?? item.close ?? 0,
          open: item.open ?? item.close ?? 0,
          close: item.close ?? 0,
          volume: item.volume || 0,
        }))
        .sort((a, b) => a.time - b.time);
    });

/* =========================
   Styled Components & Helpers
   ========================= */
const UniformContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: '#141414',
  padding: theme.spacing(0.5),
  margin: theme.spacing(0.5),
  border: '1px solid #0e0e0e',
  borderRadius: 4,
}));

const Sidebar = styled(UniformContainer)(({ theme }) => ({
  height: 'calc(100vh - 16px)',
  overflowY: 'auto',
  paddingTop: theme.spacing(0.5),
}));

const DetailSection = styled(UniformContainer)(({ theme }) => ({
  padding: theme.spacing(1),
  border: '1px solid #333',
  borderRadius: 8,
  backgroundColor: '#1a1a1a',
}));

const TransactionsContainer = styled(UniformContainer)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  overflowY: 'auto',
}));

const scrollLeft = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const TickerContainer = styled(Box)({
  overflow: 'hidden',
  backgroundColor: '#141414',
  padding: '2px 0',
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
});

const TickerContent = styled(Box)({
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  animation: `${scrollLeft} 150s linear infinite`,
  animationPlayState: 'running',
  minWidth: '400%',
});

const TokenLogo = styled('img')({
  borderRadius: '50%',
  width: '16px',
  height: '16px',
  transition: 'opacity 0.3s ease-in-out',
});

const TokenItem = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '8px',
  borderRadius: '4px',
  border: selected ? `2px solid ${theme.palette.primary.main}` : '1px solid transparent',
  backgroundColor: selected ? theme.palette.action.hover : 'transparent',
  color: '#fff',
  cursor: 'pointer',
  marginBottom: '8px',
  transition: 'border-color 0.2s, transform 0.2s',
  '&:hover': { transform: 'scale(1.01)', backgroundColor: 'rgba(50,205,50,0.1)' },
}));

const PoolItem = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.action.selected : 'transparent',
  color: selected ? theme.palette.warning.main : '#fff',
  cursor: 'pointer',
  marginBottom: '4px',
  transition: 'background-color 0.2s',
}));

const PoolList = styled(Box)({ marginLeft: '8px', marginTop: '8px' });

const ChartContainer = styled(UniformContainer)({
  backgroundColor: '#000',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const FloatingToggleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '8px',
  '& svg': { fontSize: '1rem' },
  '&:hover': { backgroundColor: '#ffd700' },
}));

const LeftToggleButton = styled(FloatingToggleButton)(({ theme }) => ({
  position: 'fixed',
  left: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
}));

const RightToggleButton = styled(FloatingToggleButton)(({ theme }) => ({
  position: 'fixed',
  right: '8px',
  top: '50%',
  transform: 'translateY(-50%)',
}));

const TickerBar = memo(({ tokens, onTokenSelect }) => (
  <TickerContainer>
    <TickerContent>
      {tokens.map((token, index) => {
        const percentChange =
          token.FirstPoolRatio && token.LatestPoolRatio
            ? ((token.LatestPoolRatio - token.FirstPoolRatio) / token.FirstPoolRatio) * 100
            : 0;
        return (
          <Box
            key={`${token.TokenID}-${index}`}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              mr: 1,
              px: 0.5,
              borderRight: '1px solid #444',
              transition: 'background-color 0.2s',
              '&:hover': { backgroundColor: '#333' },
              cursor: 'pointer',
            }}
            onClick={() => onTokenSelect(token)}
          >
            {token.Logo && (
              <TokenLogo
                src={
                  token.Logo.startsWith('http')
                    ? token.Logo
                    : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${token.Logo}`
                }
                alt={`${token.TokenName || token.Name} logo`}
              />
            )}
            <Typography variant="caption" sx={{ color: '#fff', mr: 2 }}>
              {token.Symbol}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color:
                  token.FirstPoolRatio && token.LatestPoolRatio &&
                  ((token.LatestPoolRatio - token.FirstPoolRatio) / token.FirstPoolRatio) * 100 >= 0
                    ? '#00FF00'
                    : '#FF4444',
              }}
            >
              {token.FirstPoolRatio && token.LatestPoolRatio
                ? (((token.LatestPoolRatio - token.FirstPoolRatio) / token.FirstPoolRatio) * 100).toFixed(2)
                : 'N/A'}
              %
            </Typography>
          </Box>
        );
      })}
    </TickerContent>
  </TickerContainer>
));

// Updated formatMoney (default symbol ℵ)
const formatMoney = (amount, symbol = 'ℵ') => {
  if (amount == null || isNaN(amount)) return 'N/A';
  return `${symbol}${Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

const formatRatio = (ratio) => {
  if (ratio == null || isNaN(ratio)) return 'N/A';
  const num = parseFloat(ratio);
  return num >= 1 ? num.toFixed(3) : num.toFixed(5).replace(/^0+/, '');
};

const formatTokenID = (tokenID) =>
  tokenID ? `${tokenID.slice(0, 4)}...${tokenID.slice(-4)}` : 'N/A';

const formatTokenPriceWithSubscript = (price) =>
  !price || isNaN(price) ? 'N/A' : `${Number(price).toFixed(6)} ℵ`;

const AlphDetailRow = ({ label, value }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" py={0.25} borderBottom="1px solid #444">
    <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#32CD32' }}>
      {label}
    </Typography>
    <Typography variant="caption" sx={{ color: '#FFD700' }}>
      {value}
    </Typography>
  </Box>
);

const LocalFormattedTokenID = ({ tokenID, copyFn }) => (
  <Tooltip title={tokenID} arrow>
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography variant="caption" sx={{ cursor: 'pointer', mr: 0.5 }} onClick={() => copyFn(tokenID)}>
        {formatTokenID(tokenID)}
      </Typography>
      <IconButton size="small" onClick={() => copyFn(tokenID)} aria-label="Copy Token ID">
        <ContentCopyIcon sx={{ fontSize: 14, color: '#B0B0B0' }} />
      </IconButton>
    </Box>
  </Tooltip>
);

const LocalFormattedPoolID = ({ poolID, copyFn }) => (
  <Tooltip title={poolID} arrow>
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Typography variant="caption" sx={{ cursor: 'pointer', mr: 0.5 }} onClick={() => copyFn(poolID)}>
        {formatTokenID(poolID)}
      </Typography>
      <IconButton size="small" onClick={() => copyFn(poolID)} aria-label="Copy Pool ID">
        <ContentCopyIcon sx={{ fontSize: 14, color: '#B0B0B0' }} />
      </IconButton>
    </Box>
  </Tooltip>
);

const PoolRatioGauge = memo(({ selectedPool }) => {
  if (!selectedPool || !selectedPool.FirstPoolRatio || !selectedPool.HighestPoolRatio) {
    return <Typography variant="caption" color="text.secondary">No ratio data available</Typography>;
  }
  const minRatio = parseFloat(selectedPool.FirstPoolRatio);
  const maxRatio = parseFloat(selectedPool.HighestPoolRatio);
  const currentRatio = parseFloat(selectedPool.LatestPoolRatio);
  if (isNaN(minRatio) || isNaN(maxRatio) || isNaN(currentRatio)) return null;
  const normalizedValue = (currentRatio - minRatio) / (maxRatio - minRatio);
  return (
    <UniformContainer sx={{ textAlign: 'center' }}>
      <Typography variant="subtitle2" sx={{ mb: 0.5, color: '#FFD700' }}>
        Pool Ratio Gauge
      </Typography>
      <GaugeChart
        id="pool-ratio-speedometer"
        nrOfLevels={20}
        percent={normalizedValue}
        colors={['#d32f2f', '#FFA500', '#00c853']}
        arcWidth={0.3}
        needleColor="#fff"
        textColor="#fff"
        formatTextValue={() => `${currentRatio.toFixed(5)} ℵ`}
      />
      <Typography variant="caption" mt={0.5} color="text.secondary">
        {minRatio.toFixed(5)} ℵ - {maxRatio.toFixed(5)} ℵ
      </Typography>
    </UniformContainer>
  );
});

/* =========================
   FearGreedIndexDirect Component
   ========================= */
const FearGreedIndexDirect = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const classificationEmojis = {
    'Extreme Greed': '🤩',
    Greed: '😃',
    Neutral: '😐',
    Fear: '😟',
    'Extreme Fear': '😱',
  };

  useEffect(() => {
    async function fetchIndex() {
      try {
        const response = await axios.get('https://api.alternative.me/fng/?limit=1');
        if (response.data && response.data.data && response.data.data.length > 0) {
          const indexData = response.data.data[0];
          setData({
            index: indexData.value,
            classification: indexData.value_classification,
            timestamp: new Date(indexData.timestamp * 1000).toISOString(),
            time_until_update: indexData.time_until_update,
          });
        } else {
          setError("No Fear & Greed Index data found.");
        }
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    }
    fetchIndex();
  }, []);

  if (loading)
    return (
      <Box sx={{ p: 1, textAlign: 'center' }}>
        <CircularProgress size={20} color="inherit" />
      </Box>
    );
  if (error) return <Typography variant="caption" color="error">{error}</Typography>;

  return (
    <Box sx={{ p: 1, textAlign: 'center' }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#FFD700', mb: 1 }}>
        Fear & Greed Index
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Index:</strong> {data.index}
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Classification:</strong> {classificationEmojis[data.classification] || ''} {data.classification}
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Timestamp:</strong> {data.timestamp}
      </Typography>
      <Typography variant="caption" display="block">
        <strong>Next Update In:</strong> {data.time_until_update} sec
      </Typography>
    </Box>
  );
};

/* =========================
   TransactionStatsGauge, PumpMeter, OnionTokenMetrics
   ========================= */
const TransactionStatsGauge = memo(({ pool }) => {
  if (!pool) return null;
  const buys = pool.Buys || 0;
  const sells = pool.Sells || 0;
  const total = pool.TransactionCount || 0;
  const buysPercentage = total > 0 ? (buys / total) * 100 : 0;
  const sellsPercentage = total > 0 ? (sells / total) * 100 : 0;
  return (
    <UniformContainer>
      <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#FFD700', fontSize: '0.9rem' }}>
        Pool Transaction Stats
      </Typography>
      <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', mt: 0.5 }}>
        Total: {total}
      </Typography>
      <Box sx={{ display: 'flex', height: 10, width: '100%', borderRadius: 4, overflow: 'hidden', mt: 0.5 }}>
        <Box sx={{ width: `${buysPercentage}%`, backgroundColor: '#00c853' }} />
        <Box sx={{ width: `${sellsPercentage}%`, backgroundColor: '#d32f2f' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <Typography variant="caption" sx={{ color: '#00c853' }}>Buys: {buys}</Typography>
        <Typography variant="caption" sx={{ color: '#d32f2f' }}>Sells: {sells}</Typography>
      </Box>
      <Box sx={{ mt: 0.5 }}>
        <Typography variant="caption" sx={{ display: 'block', textAlign: 'center' }}>
          Avg Buy: {pool?.AvgBuyPrice ? formatMoney(pool.AvgBuyPrice) : 'N/A'}
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', textAlign: 'center' }}>
          Avg Sell: {pool?.AvgSellPrice ? formatMoney(pool.AvgSellPrice) : 'N/A'}
        </Typography>
        <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', color: '#FFD700' }}>
          Multiplier: {pool?.Latest_X ? Number(pool.Latest_X).toFixed(2) + 'x' : 'N/A'}
        </Typography>
      </Box>
    </UniformContainer>
  );
});

const PumpMeter = ({ volumeDaily, marketCap }) => {
  // Use DailyVolume_ALPH if VolumeDaily is not provided
  const vol = volumeDaily || 0;
  const cap = marketCap || 0;
  let pumpValue = 0;
  if (cap > 0) {
    const rawPump = (vol / cap) * 100;
    pumpValue = Math.min(100, 20 * Math.log10(rawPump + 1));
  }
  let barColor = '#00c853';
  if (pumpValue < 20) barColor = '#d32f2f';
  else if (pumpValue < 60) barColor = '#FFA500';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="caption" sx={{ color: '#FFD700' }}>{pumpValue.toFixed(1)}%</Typography>
      <LinearProgress
        variant="determinate"
        value={pumpValue}
        sx={{
          height: 8,
          borderRadius: 4,
          width: '100%',
          backgroundColor: '#444',
          '& .MuiLinearProgress-bar': { backgroundColor: barColor },
        }}
      />
    </Box>
  );
};

const OnionTokenMetrics = ({ token }) => {
  if (!token) return null;
  const { Logo, Symbol, VolumeDaily, DailyVolume_ALPH, MarketCap, marketcap, createdTimestamp, lastTradeTimestamp, Name } = token;
  const vol = VolumeDaily || DailyVolume_ALPH;
  const cap = MarketCap || marketcap;
  const logoUrl =
    Logo && (Logo.startsWith('http')
      ? Logo
      : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${Logo}`);
  const formattedCreated =
    token.createdTimestamp && Number(token.createdTimestamp) > 0
      ? new Date(Number(token.createdTimestamp)).toLocaleString()
      : 'N/A';
  const formattedLastTrade =
    token.lastTradeTimestamp && Number(token.lastTradeTimestamp) > 0
      ? new Date(Number(token.lastTradeTimestamp)).toLocaleString()
      : 'N/A';
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: '#1a1a1a',
        border: '2px solid #FFD700',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        minWidth: 220,
        maxWidth: 300,
        margin: '0 auto',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
        {logoUrl && (
          <img src={logoUrl} alt={`${Name} logo`} style={{ width: 40, height: 40, borderRadius: '50%' }} />
        )}
        <Typography variant="h6" sx={{ color: '#FFD700', fontWeight: 'bold' }}>{Symbol}</Typography>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Volume (Daily): {vol ? vol.toFixed(2) : 'N/A'}
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Market Cap: {cap ? formatMoney(cap) : 'N/A'}
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Created: {formattedCreated}
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff' }}>
          Last Trade: {formattedLastTrade}
        </Typography>
      </Box>
    </Box>
  );
};

/* =========================
   Compact Visual for Volume Stats
   ========================= */
const VolumeStatRow = ({ label, volume, marketCap }) => {
  const percentage = marketCap && marketCap > 0 ? Math.min(100, (volume / marketCap) * 100) : 0;
  return (
    <Box mb={1}>
      <AlphDetailRow label={label} value={formatMoney(volume)} />
      {marketCap && (
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: 4,
            borderRadius: 2,
            mt: 0.5,
            backgroundColor: '#444',
            '& .MuiLinearProgress-bar': { backgroundColor: '#32CD32' },
          }}
        />
      )}
    </Box>
  );
};

/* =========================
   TradingDashboardInner Component
   ========================= */
const TradingDashboardInner = () => {
  const [mode] = useState('dark');
  const theme = useMemo(() => getCustomTheme(mode), [mode]);
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const [chartExpanded, setChartExpanded] = useState(false);
  const [expandedTokenIds, setExpandedTokenIds] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [transactionTokenID, setTransactionTokenID] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  // Updated sort options include market cap and daily volume
  const [sortOption, setSortOption] = useState('transactions_desc');
  const [onionSortOption, setOnionSortOption] = useState('pumpMeter_desc');
  const [activeTab, setActiveTab] = useState('transactions');
  const [walletAddress, setWalletAddress] = useState('');
  const [tradingViewLoaded, setTradingViewLoaded] = useState(false);
  const [chartRequested, setChartRequested] = useState(false);
  const [chartMode, setChartMode] = useState('custom');
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [showRightSidebar, setShowRightSidebar] = useState(true);
  const [pumpInfoOpen, setPumpInfoOpen] = useState(false);
  const [selectedPoolFilter, setSelectedPoolFilter] = useState('all');
  const [loadAll, setLoadAll] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Rescanning tokens/transactions...");
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("[Dashboard] Selected token changed:", selectedToken?.TokenID);
  }, [selectedToken]);

  const groupName = selectedToken ? `token_${selectedToken.TokenID}` : null;
  const { liveCandleUpdates, snapshot } = useWebPubSub(groupName);

  useEffect(() => {
    console.log("Snapshot state updated:", snapshot);
  }, [snapshot]);

  const chartDataFromRest = useSWR(
    selectedPool && chartRequested ? [CHARTDATA_API_URL, selectedPool.PoolID, selectedPool.TokenID] : null,
    fetchChartData,
    { revalidateOnFocus: false, dedupingInterval: 5000 }
  ).data;

  const mergedChartData = useMemo(() => {
    if (snapshot?.chart?.length > 0) {
      console.log("[PUSH] Using pushed snapshot chart data:", snapshot.chart);
      const combined = [...snapshot.chart, ...liveCandleUpdates];
      const sorted = combined.sort((a, b) => a.time - b.time);
      console.log("[PUSH] Merged chart data:", sorted);
      return sorted;
    }
    console.log("[REST] Using REST fetched chart data:", chartDataFromRest);
    return chartDataFromRest || [];
  }, [snapshot, liveCandleUpdates, chartDataFromRest]);

  const { data: transactionsData = [] } = useSWR(
    transactionTokenID ? [TRANSACTIONS_API_URL, transactionTokenID, loadAll] : null,
    fetchTransactions,
    { revalidateOnFocus: false, dedupingInterval: 5000 }
  );

  const finalTransactions = useMemo(() => {
    if (!transactionTokenID) {
      return [];
    }
    if (snapshot && Array.isArray(snapshot.transactions)) {
      if (snapshot.transactions.length > 0) {
        console.log("[PUSH] Using pushed snapshot transactions:", snapshot.transactions);
        return snapshot.transactions;
      } else {
        console.warn("[PUSH] Received empty transactions array from push snapshot, falling back to REST.");
        return transactionsData || [];
      }
    }
    console.log("[REST] Using REST fetched transactions:", transactionsData);
    return transactionsData || [];
  }, [snapshot, transactionsData, transactionTokenID]);

  // Updated sort options including market cap and daily volume
  const defaultSortOptions = [
    { value: 'new_desc', label: 'Newest' },
    { value: 'name_asc', label: 'Name (A-Z)' },
    { value: 'name_desc', label: 'Name (Z-A)' },
    { value: 'price_asc', label: 'Price (Low to High)' },
    { value: 'price_desc', label: 'Price (High to Low)' },
    { value: 'multiplier_asc', label: 'Multiplier (Low to High)' },
    { value: 'multiplier_desc', label: 'Multiplier (High to Low)' },
    { value: 'transactions_asc', label: 'Txs (Low to High)' },
    { value: 'transactions_desc', label: 'Txs (High to Low)' },
    { value: 'latest_desc', label: 'Latest Tx Time (Newest First)' },
    { value: 'marketcap_desc', label: 'Market Cap (High to Low)' },
    { value: 'marketcap_asc', label: 'Market Cap (Low to High)' },
    { value: 'volume_desc', label: 'Daily Volume (High to Low)' },
    { value: 'volume_asc', label: 'Daily Volume (Low to High)' },
  ];
  const onionSortOptions = [
    { value: 'pumpMeter_desc', label: 'Pump Meter (High to Low)' },
    { value: 'pumpMeter_asc', label: 'Pump Meter (Low to High)' },
    { value: 'volume_desc', label: 'Volume (High to Low)' },
    { value: 'volume_asc', label: 'Volume (Low to High)' },
    { value: 'marketcap_desc', label: 'Market Cap (High to Low)' },
    { value: 'marketcap_asc', label: 'Market Cap (Low to High)' },
    { value: 'created_desc', label: 'Created (Newest First)' },
    { value: 'created_asc', label: 'Created (Oldest First)' },
  ];

  const handlePoolFilterChange = useCallback((e) => setSelectedPoolFilter(e.target.value), []);
  useEffect(() => {
    if (selectedPoolFilter === 'onion') setSortOption('');
  }, [selectedPoolFilter]);

  const handleTokenSelect = useCallback((token) => {
    setChartRequested(false);
    setChartMode('none'); // TEMPORARILY unmount both charts
    setTimeout(() => {
      setChartMode('custom');
      setChartRequested(true);
    }, 50);
    setSelectedToken(token);
    setTransactionTokenID(token.TokenID);
    setLoadAll(false);
    if (selectedPoolFilter === 'onion') {
      const onionSelectedPool = {
        PoolID: token.TokenID,
        TokenID: token.TokenID,
        TokenName: token.Name,
        Symbol: token.Symbol,
        Logo: token.Logo,
        PoolName: `${token.Symbol}/ALPH`,
        PoolExchange: 'Onion',
        PoolAddress: token.OwnerAddress || '',
        TokenAddress: token.OwnerAddress || '',
        PairName: `${token.Symbol}/ALPH`,
      };
      setSelectedPool(onionSelectedPool);
    } else {
      if (token.allPools && token.allPools.length > 0) {
        const topPool = token.allPools.reduce((prev, current) =>
          prev.TransactionCount > current.TransactionCount ? prev : current
        );
        setSelectedPool(topPool);
      } else {
        setSelectedPool(null);
      }
    }
  }, [selectedPoolFilter]);

  const handlePoolSelect = useCallback((pool) => {
    setSelectedPool(pool);
    setChartRequested(false);
  }, []);

  useEffect(() => {
    if (transactionTokenID) {
      console.log("Fetching transactions for token:", transactionTokenID);
    }
  }, [transactionTokenID]);

  const { data: coingeckoData } = useSWR(
    'https://api.coingecko.com/api/v3/coins/alephium?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false',
    fetcher,
    { refreshInterval: 60000, revalidateOnFocus: false, dedupingInterval: 5000 }
  );
  const alphPrice = coingeckoData?.market_data?.current_price?.usd;
  const marketCapGlobal = coingeckoData?.market_data?.market_cap?.usd;
  const volume24h = coingeckoData?.market_data?.total_volume?.usd;
  const change24h = coingeckoData?.market_data?.price_change_percentage_24h;
  const high24h = coingeckoData?.market_data?.high_24h?.usd;
  const low24h = coingeckoData?.market_data?.low_24h?.usd;
  const alphPriceLoading = !coingeckoData;

  const { data: tokensData, error: tokensError } = useSWR(
    TRANSACTIONS_API_URL,
    (url) => apiClient.get(url).then((res) => res.data),
    { refreshInterval: 60000, revalidateOnFocus: false, dedupingInterval: 5000 }
  );
  const tokens = useMemo(() => {
    if (!tokensData) return [];
    const allPools = Array.isArray(tokensData?.pools) ? tokensData.pools : [];
    const tokenMap = allPools.reduce((acc, pool) => {
      if (!acc[pool.TokenID]) {
        acc[pool.TokenID] = { ...pool, allPools: [] };
      }
      acc[pool.TokenID].allPools.push(pool);
      return acc;
    }, {});
    return Object.values(tokenMap);
  }, [tokensData]);
  const loadingTokens = !tokensData && !tokensError;
  const { data: onionData } = useSWR(
    ONION_TOKENS_API_URL,
    fetcher,
    { dedupingInterval: 5000, revalidateOnFocus: false }
  );
  const tokenList = useMemo(() => {
    return selectedPoolFilter === 'onion' ? (onionData?.data || []) : tokens;
  }, [selectedPoolFilter, tokens, onionData]);
  const sortOptionToUse = selectedPoolFilter === 'onion' ? onionSortOption : sortOption;
  const sortedFilteredTokens = useMemo(() => {
    if (!tokenList) return [];
    const lowerQuery = searchQuery.toLowerCase();
    const filtered = tokenList.filter((token) =>
      (token.TokenName || token.Name || '').toLowerCase().includes(lowerQuery) ||
      (token.Symbol || '').toLowerCase().includes(lowerQuery)
    );
    return filtered.sort((a, b) => {
      let aVal = 0, bVal = 0;
      switch (sortOptionToUse) {
        case 'new_desc':
          aVal = a.FirstPoolTimestamp ? new Date(a.FirstPoolTimestamp).getTime() : 0;
          bVal = b.FirstPoolTimestamp ? new Date(b.FirstPoolTimestamp).getTime() : 0;
          break;
        case 'name_asc':
          aVal = a.TokenName || a.Name || '';
          bVal = b.TokenName || b.Name || '';
          break;
        case 'name_desc':
          aVal = a.TokenName || a.Name || '';
          bVal = b.TokenName || b.Name || '';
          break;
        case 'price_asc':
          aVal = a.LatestPoolRatio || 0;
          bVal = b.LatestPoolRatio || 0;
          break;
        case 'price_desc':
          aVal = a.LatestPoolRatio || 0;
          bVal = b.LatestPoolRatio || 0;
          break;
        case 'multiplier_asc':
          aVal = (a.Latest_X || 0) * 1000 + (a.ATH_X || 0);
          bVal = (b.Latest_X || 0) * 1000 + (b.ATH_X || 0);
          break;
        case 'multiplier_desc':
          aVal = (a.Latest_X || 0) * 1000 + (a.ATH_X || 0);
          bVal = (b.Latest_X || 0) * 1000 + (b.ATH_X || 0);
          break;
        case 'transactions_asc':
          aVal = a.TransactionCount || 0;
          bVal = b.TransactionCount || 0;
          break;
        case 'transactions_desc':
          aVal = a.TransactionCount || 0;
          bVal = b.TransactionCount || 0;
          break;
        case 'latest_desc':
          aVal = a.allPools ? Math.max(...a.allPools.map(p => new Date(p.LatestPoolTimestamp).getTime())) : (a.LatestPoolTimestamp ? new Date(a.LatestPoolTimestamp).getTime() : 0);
          bVal = b.allPools ? Math.max(...b.allPools.map(p => new Date(p.LatestPoolTimestamp).getTime())) : (b.LatestPoolTimestamp ? new Date(b.LatestPoolTimestamp).getTime() : 0);
          break;
        case 'pumpMeter_desc': {
          const getPump = (token) => {
            const vol = token.VolumeDaily || token.DailyVolume_ALPH || 0;
            const cap = token.MarketCap || token.marketcap || 0;
            return cap ? Math.min(100, 20 * Math.log10((vol / cap) + 1)) : 0;
          };
          aVal = getPump(a);
          bVal = getPump(b);
          break;
        }
        case 'pumpMeter_asc': {
          const getPump = (token) => {
            const vol = token.VolumeDaily || token.DailyVolume_ALPH || 0;
            const cap = token.MarketCap || token.marketcap || 0;
            return cap ? Math.min(100, 20 * Math.log10((vol / cap) + 1)) : 0;
          };
          aVal = getPump(a);
          bVal = getPump(b);
          break;
        }
        case 'volume_desc':
          aVal = a.VolumeDaily || a.DailyVolume_ALPH || 0;
          bVal = b.VolumeDaily || b.DailyVolume_ALPH || 0;
          break;
        case 'volume_asc':
          aVal = a.VolumeDaily || a.DailyVolume_ALPH || 0;
          bVal = b.VolumeDaily || b.DailyVolume_ALPH || 0;
          break;
        case 'marketcap_desc':
          aVal = a.MarketCap || a.marketcap || 0;
          bVal = b.MarketCap || b.marketcap || 0;
          break;
        case 'marketcap_asc':
          aVal = a.MarketCap || a.marketcap || 0;
          bVal = b.MarketCap || b.marketcap || 0;
          break;
        case 'created_desc':
          aVal = a.createdTimestamp ? Number(a.createdTimestamp) : 0;
          bVal = b.createdTimestamp ? Number(b.createdTimestamp) : 0;
          break;
        case 'created_asc':
          aVal = a.createdTimestamp ? Number(a.createdTimestamp) : 0;
          bVal = b.createdTimestamp ? Number(b.createdTimestamp) : 0;
          break;
        default:
          aVal = 0;
          bVal = 0;
      }
      if (typeof aVal === 'string') {
        return sortOptionToUse.endsWith('_asc')
          ? aVal.localeCompare(bVal)
          : bVal.localeCompare(aVal);
      }
      return sortOptionToUse.endsWith('_asc') ? aVal - bVal : bVal - aVal;
    });
  }, [tokenList, searchQuery, sortOptionToUse]);

  const filteredTokens = useMemo(() => {
    if (selectedPoolFilter === 'all' || selectedPoolFilter === 'onion') return sortedFilteredTokens;
    return sortedFilteredTokens.filter((token) =>
      token.allPools?.some(
        (pool) => pool.PoolExchange && pool.PoolExchange.toLowerCase() === selectedPoolFilter.toLowerCase()
      )
    );
  }, [sortedFilteredTokens, selectedPoolFilter]);

  const { data: chartData, error: chartError } = useSWR(
    selectedPool && chartRequested ? [CHARTDATA_API_URL, selectedPool.PoolID, selectedPool.TokenID] : null,
    fetchChartData,
    { revalidateOnFocus: false, dedupingInterval: 5000 }
  );
  const loadingChart = selectedPool && chartRequested && !chartData && !chartError;

  const { data: poolSummary, error: poolSummaryError } = useSWR(
    selectedPool?.TokenID
      ? `${POOLSUMMARY_API_URL}&tokenId=${selectedPool.TokenID}`
      : null,
    fetcher
  );

  useEffect(() => {
    console.log("chartRequested =", chartRequested);
    console.log("chartData length =", chartData ? chartData.length : 0);
  }, [chartRequested, chartData]);

  const handleSortChange = useCallback((e) => setSortOption(e.target.value), []);
  const handleSearchChange = useCallback((e) => setSearchQuery(e.target.value), []);
  const handleTokenToggle = useCallback(
    (tokenId) =>
      setExpandedTokenIds((prev) =>
        prev.includes(tokenId) ? prev.filter((id) => id !== tokenId) : [...prev, tokenId]
      ),
    []
  );
  const copyToClipboard = useCallback((text) => {
    if (text) navigator.clipboard.writeText(text).catch((err) => console.error('Copy failed:', err));
  }, []);
  const handleLoadChartClick = useCallback(() => setChartRequested(true), []);
  
  const handleAdvancedChartClick = () => {
    setChartMode('advanced');
    setChartRequested(true);
  };
  
  const handleAddressClick = useCallback((address) => {
    setWalletAddress(address);
    setActiveTab('wallet');
  }, []);
  const handleLoadAllTransactions = useCallback(() => {
    setLoadAll(true);
  }, []);

  const triggerPushSnapshot = useCallback(async () => {
    try {
      const res = await apiClient.get(PUSH_SNAPSHOT_API_URL, {
        params: {
          tokenId: selectedToken?.TokenID,
          poolId: selectedPool?.PoolID,
        },
      });
      console.log("[PUSH] PushSnapshot triggered:", res.data);
    } catch (err) {
      console.error("[PUSH] Error triggering pushSnapshot:", err);
    }
  }, [selectedToken, selectedPool]);

  useEffect(() => {
    if (chartRequested && !window.TradingView) {
      const script = document.createElement('script');
      script.src = '/tradingview/charting_library/charting_library.js';
      script.async = true;
      script.onload = () => {
        console.log("TradingView library loaded.");
        setTradingViewLoaded(true);
      };
      script.onerror = () => console.error('Failed to load TradingView library');
      document.body.appendChild(script);
    }
  }, [chartRequested]);

  useEffect(() => {
    console.log("Chart mode:", chartMode);
    console.log("Chart requested:", chartRequested);
    console.log("Merged chart data length:", mergedChartData?.length);
    console.log("TradingView loaded:", tradingViewLoaded);
  }, [chartMode, chartRequested, mergedChartData, tradingViewLoaded]);

  useEffect(() => {
    if (selectedPool) setChartRequested(true);
  }, [selectedPool]);

  useEffect(() => {
    handleAdvancedChartClick();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: '#141414', color: '#fff', minHeight: '100vh', position: 'relative' }}>
        {tokenList && tokenList.length > 0 && <TickerBar tokens={tokenList} onTokenSelect={handleTokenSelect} />}
        <Grid container spacing={0.5}>
          {/* Left Sidebar */}
          {showLeftSidebar && (
            <Grid item xs={12} md={3} lg={2}>
              <UniformContainer sx={{ padding: 1 }}>
                {isMobile && selectedToken && (
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="subtitle2" sx={{ textAlign: 'center', mb: 1 }}>
                      Token Chart
                    </Typography>
                    <ChartContainer>
                      {loadingChart ? (
                        <Box display="flex" alignItems="center">
                          <CircularProgress size={30} color="primary" />
                          <Typography variant="caption" sx={{ ml: 1 }}>Loading Chart...</Typography>
                        </Box>
                      ) : chartError ? (
                        <Typography color="error" variant="caption">
                          {chartError.message}
                        </Typography>
                      ) : (
                        <Suspense fallback={<CircularProgress size={30} color="inherit" />}>
                          {chartMode === 'advanced' ? (
                            <TradingViewChart
                              key={`tradingview-mobile-${selectedToken?.TokenID || 'none'}`}
                              symbol="MEXC:ALPHUSDT"
                              width="100%"
                              height={chartExpanded ? 1100 : 800}
                            />
                          ) : chartMode === 'custom' ? (
                            <TradingChartWidget
                              key={`custom-mobile-${selectedPool?.PoolID || 'none'}`}
                              tradingViewLoaded={tradingViewLoaded}
                              chartData={mergedChartData}
                              isDarkMode={mode === 'dark'}
                              pairName={selectedPool?.PairName || ''}
                              chartMode={chartMode}
                              transactions={finalTransactions}
                              loading={loadingChart}
                              error={chartError}
                              width="100%"
                              height={chartExpanded ? 1100 : 800}
                              hideSideToolbar={true}
                            />
                          ) : (
                            <Box><Typography variant="caption">No chart selected</Typography></Box>
                          )}
                        </Suspense>
                      )}
                    </ChartContainer>
                  </Box>
                )}
                <Typography variant="h6" sx={{ mb: 0.5, fontWeight: 'bold', textAlign: 'center', fontSize: '0.9rem' }}>
                  Token Pools
                </Typography>
                <Box mb={0.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="sort-select-label" sx={{ color: '#fff' }}>Sort By</InputLabel>
                    <Select
                      labelId="sort-select-label"
                      id="sort-select"
                      value={selectedPoolFilter === 'onion' ? '' : sortOption}
                      label="Sort By"
                      onChange={handleSortChange}
                      sx={{ fontSize: '0.8rem', color: '#fff', backgroundColor: '#141414' }}
                    >
                      {defaultSortOptions.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {selectedPoolFilter === 'onion' && (
                  <Box mb={0.5}>
                    <FormControl fullWidth size="small" sx={{ border: '1px solid purple', borderRadius: 1 }}>
                      <InputLabel id="onion-sort-select-label" sx={{ color: 'purple' }}>Onion Sort</InputLabel>
                      <Select
                        labelId="onion-sort-select-label"
                        id="onion-sort-select"
                        value={onionSortOption}
                        label="Onion Sort"
                        onChange={(e) => setOnionSortOption(e.target.value)}
                        sx={{ fontSize: '0.8rem', color: 'purple', backgroundColor: '#141414' }}
                      >
                        {onionSortOptions.map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                <Box mb={0.5}>
                  <TextField
                    label="Search Tokens"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Token name or symbol..."
                    fullWidth
                    InputProps={{ sx: { backgroundColor: '#141414', fontSize: '0.8rem', color: '#fff' } }}
                    InputLabelProps={{ sx: { color: '#fff', fontSize: '0.8rem' } }}
                  />
                </Box>
                <Box mb={0.5}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="pool-select-label" sx={{ color: '#fff' }}>Pool Selection</InputLabel>
                    <Select
                      labelId="pool-select-label"
                      id="pool-select"
                      value={selectedPoolFilter}
                      label="Pool Selection"
                      onChange={handlePoolFilterChange}
                      sx={{ fontSize: '0.8rem', color: '#fff', backgroundColor: '#141414' }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="ayin">Ayin</MenuItem>
                      <MenuItem value="elexium">Elexium</MenuItem>
                      <MenuItem value="onion">Onion</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box mb={0.5} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="caption" sx={{ color: '#fff' }}>Pump Meter Info</Typography>
                  <IconButton size="small" onClick={() => setPumpInfoOpen(true)}>
                    <InfoOutlinedIcon sx={{ fontSize: 16, color: '#FFD700' }} />
                  </IconButton>
                </Box>
                <Divider sx={{ mb: 0.5, borderColor: '#0e0e0e' }} />
                {selectedPoolFilter === 'onion'
                  ? (onionData && onionData.data && onionData.data.length > 0
                      ? sortedFilteredTokens.map((token) => {
                          const displayName = token.Name;
                          const logoUrl = token.Logo?.startsWith('http')
                            ? token.Logo
                            : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${token.Logo}`;
                          return (
                            <Box key={token.TokenID}>
                              <TokenItem selected={selectedPool?.TokenID === token.TokenID} onClick={() => handleTokenSelect(token)}>
                                {logoUrl && <TokenLogo src={logoUrl} alt={`${displayName} logo`} style={{ marginRight: 4 }} />}
                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="caption" sx={{ fontSize: '0.8rem', color: '#fff' }}>
                                    {token.Symbol}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: '#FFD700', fontSize: '0.7rem' }}>
                                    {displayName}
                                  </Typography>
                                </Box>
                                <Box sx={{ width: 50 }}>
                                  <PumpMeter
                                    volumeDaily={token.VolumeDaily || token.DailyVolume_ALPH}
                                    marketCap={token.MarketCap || token.marketcap}
                                  />
                                </Box>
                              </TokenItem>
                              {selectedPool?.TokenID === token.TokenID && (
                                <Box sx={{ mt: 1, p: 1, backgroundColor: '#1a1a1a', border: '1px solid #FFD700', borderRadius: 1 }}>
                                  <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                    Volume (Daily): {token.VolumeDaily || token.DailyVolume_ALPH ? (token.VolumeDaily || token.DailyVolume_ALPH).toFixed(2) : 'N/A'}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                    Market Cap: {token.MarketCap || token.marketcap ? formatMoney(token.MarketCap || token.marketcap) : 'N/A'}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                    Created: {token.createdTimestamp ? new Date(Number(token.createdTimestamp)).toLocaleString() : 'N/A'}
                                  </Typography>
                                  <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                    Last Trade: {token.lastTradeTimestamp ? new Date(Number(token.lastTradeTimestamp)).toLocaleString() : 'N/A'}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          );
                        })
                      : <Typography variant="caption">No Onion tokens available.</Typography>)
                  : (loadingTokens ? (
                      <Box>
                        <Skeleton variant="rectangular" height={30} />
                        <Skeleton variant="rectangular" height={30} sx={{ mt: 0.5 }} />
                      </Box>
                    ) : tokensError ? (
                      <Typography color="error" variant="caption">
                        {tokensError.message}
                      </Typography>
                    ) : (
                      sortedFilteredTokens.map((token) => {
                        const displayName = token.TokenName || token.Name;
                        const logoUrl = token.Logo && (token.Logo.startsWith('http')
                          ? token.Logo
                          : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${token.Logo}`);
                        return (
                          <Box key={token.TokenID}>
                            <TokenItem
                              selected={selectedPool?.TokenID === token.TokenID}
                              onClick={() => { handleTokenToggle(token.TokenID); handleTokenSelect(token); }}
                            >
                              {logoUrl && <TokenLogo src={logoUrl} alt={`${displayName} logo`} style={{ marginRight: 4 }} />}
                              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="caption" sx={{ fontSize: '0.8rem', color: '#fff' }}>
                                  {token.Symbol}
                                </Typography>
                                {token.DexPairAddress && (
                                  <Box sx={{ backgroundColor: '#FFD700', px: 0.5, py: 0.25, borderRadius: 1, ml: 1 }}>
                                    <Typography variant="caption" sx={{ color: '#141414', fontWeight: 'bold' }}>
                                      DEX
                                    </Typography>
                                  </Box>
                                )}
                                <Typography variant="caption" sx={{ color: '#FFD700', fontSize: '0.7rem', ml: 0.5 }}>
                                  {displayName}
                                </Typography>
                              </Box>
                              <Box sx={{ width: 50 }}>
                                <PumpMeter
                                  volumeDaily={token.VolumeDaily || token.DailyVolume_ALPH}
                                  marketCap={token.MarketCap || token.marketcap}
                                />
                              </Box>
                            </TokenItem>
                            {expandedTokenIds.includes(token.TokenID) && token.allPools && (
                              <PoolList>
                                {token.allPools.map((pool) => (
                                  <PoolItem key={pool.PoolID} selected={selectedPool?.PoolID === pool.PoolID} onClick={() => handlePoolSelect(pool)}>
                                    <Box display="flex" alignItems="center" mr={0.5}>
                                      <Box position="relative" width={28} height={20} display="flex">
                                        {token.PairLogo2 && (
                                          <TokenLogo
                                            src={token.PairLogo2}
                                            alt={`${displayName} Pair Logo 2`}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              border: '1px solid white',
                                              borderRadius: '50%',
                                              opacity: 0.8,
                                              position: 'absolute',
                                              left: 4,
                                              zIndex: 1,
                                            }}
                                          />
                                        )}
                                        {token.PairLogo1 && (
                                          <TokenLogo
                                            src={token.PairLogo1}
                                            alt={`${displayName} Pair Logo 1`}
                                            style={{
                                              width: 16,
                                              height: 16,
                                              border: '1px solid white',
                                              borderRadius: '50%',
                                              position: 'absolute',
                                              left: 0,
                                              zIndex: 2,
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </Box>
                                    <Typography variant="caption" sx={{ flexGrow: 1, fontSize: '0.7rem', color: '#fff' }}>
                                      {`${pool.PoolExchange} - ${pool.PairName}`}
                                    </Typography>
                                    <Typography variant="caption" sx={{ color: '#FFD700', fontSize: '0.7rem' }}>
                                      {formatRatio(pool.LatestPoolRatio)} ℵ
                                    </Typography>
                                  </PoolItem>
                                ))}
                              </PoolList>
                            )}
                          </Box>
                        );
                      })
                    ))}
              </UniformContainer>
            </Grid>
          )}
          {/* Center Column */}
          <Grid item xs={12} md={showLeftSidebar && showRightSidebar ? 6 : showLeftSidebar || showRightSidebar ? 9 : 12} lg={showLeftSidebar && showRightSidebar ? 8 : showLeftSidebar || showRightSidebar ? 10 : 12}>
            <Grid container spacing={0.5}>
              {!isMobile && (
                <Grid item xs={12}>
                  <ChartContainer>
                    {loadingChart ? (
                      <Box display="flex" alignItems="center">
                        <CircularProgress size={30} color="primary" />
                        <Typography variant="caption" sx={{ ml: 1 }}>Loading Chart...</Typography>
                      </Box>
                    ) : chartError ? (
                      <Typography color="error" variant="caption">
                        {chartError.message}
                      </Typography>
                    ) : (
                      <Suspense fallback={<CircularProgress size={30} color="inherit" />}>
                        {chartMode === 'advanced' ? (
                          <TradingViewChart
                            key={`tradingview-${selectedToken?.TokenID || 'none'}`}
                            symbol="MEXC:ALPHUSDT"
                            width="100%"
                            height={chartExpanded ? 1100 : 800}
                          />
                        ) : chartMode === 'custom' ? (
                          <TradingChartWidget
                            key={`custom-${selectedPool?.PoolID || 'none'}`}
                            tradingViewLoaded={tradingViewLoaded}
                            chartData={mergedChartData}
                            isDarkMode={mode === 'dark'}
                            pairName={selectedPool?.PairName || ''}
                            chartMode={chartMode}
                            transactions={finalTransactions}
                            loading={loadingChart}
                            error={chartError}
                            width="100%"
                            height={chartExpanded ? 1100 : 800}
                            hideSideToolbar={false}
                          />
                        ) : (
                          <Box><Typography variant="caption">No chart selected</Typography></Box>
                        )}
                      </Suspense>
                    )}
                  </ChartContainer>
                </Grid>
              )}
              {!isMobile && (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
                    <IconButton onClick={() => setChartExpanded(prev => !prev)} size="small" sx={{ backgroundColor: '#1a1a1a' }}>
                      {chartExpanded ? <KeyboardArrowUpIcon sx={{ fontSize: '1.5rem', color: '#FFD700' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: '1.5rem', color: '#FFD700' }} />}
                    </IconButton>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sx={{ mt: chartExpanded ? 6 : 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pb: 1, pt: 1, borderBottom: '1px solid #333' }}>
                  <Tabs
                    value={activeTab}
                    onChange={(e, newVal) => setActiveTab(newVal)}
                    indicatorColor="primary"
                    textColor="inherit"
                    centered={false}
                    sx={{ '& .MuiTab-root': { p: '6px 12px', fontSize: '0.9rem' } }}
                  >
                    <Tab label="Transactions" value="transactions" />
                    <Tab label="Wallet" value="wallet" />
                    <Tab label="Summary" value="summary" />
                    <Tab label="Holders" value="holders" />
                    <Tab label="Live" value="live" />
                  </Tabs>
                  <Button onClick={handleAdvancedChartClick} variant="outlined" size="small" sx={{ ml: 2 }}>
                    ALPH chart
                  </Button>
                </Box>
              </Grid>
              {activeTab === 'transactions' && (
                <Grid item xs={12}>
                  <TransactionsContainer>
                    <Suspense fallback={<CircularProgress size={20} color="inherit" />}>
                      <TokenTransactionV2
                        transactions={finalTransactions}
                        poolData={tokens}
                        tokenList={tokens.map((token) => ({
                          id: token.TokenID,
                          symbol: token.Symbol,
                          name: token.TokenName || token.Name,
                          logo: token.Logo && (token.Logo.startsWith('http')
                            ? token.Logo
                            : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${token.Logo}`),
                          price: token.LatestPoolRatio
                            ? formatTokenPriceWithSubscript(token.LatestPoolRatio)
                            : 'N/A',
                        }))}
                        onPoolSelect={handlePoolSelect}
                        searchQuery={searchQuery}
                        onWalletClick={handleAddressClick}
                        onLoadAllTransactions={handleLoadAllTransactions}
                      />
                    </Suspense>
                  </TransactionsContainer>
                </Grid>
              )}
              {activeTab === 'wallet' && (
                <Grid item xs={12}>
                  <UniformContainer sx={{ minHeight: 'calc(100vh - 16px)', overflowY: 'auto' }}>
                    <Box mb={0.5}>
                      <TextField
                        label="Enter Wallet Address"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                      />
                    </Box>
                    {selectedPool ? (
                      <WalletSummaryView tokenId={selectedPool.TokenID} walletAddress={walletAddress} />
                    ) : (
                      <Typography variant="caption">
                        Please select a token for the wallet view.
                      </Typography>
                    )}
                  </UniformContainer>
                </Grid>
              )}
              {activeTab === 'summary' && (
                <Grid item xs={12}>
                  <UniformContainer sx={{ minHeight: 'calc(100vh - 16px)', overflowY: 'auto' }}>
                    <PoolSummaryView
                      poolSummary={poolSummary}
                      loading={!poolSummary && !poolSummaryError}
                      error={poolSummaryError}
                      onAddressClick={handleAddressClick}
                    />
                  </UniformContainer>
                </Grid>
              )}
              {activeTab === 'holders' && (
                <Grid item xs={12}>
                  <UniformContainer sx={{ minHeight: 'calc(100vh - 16px)', overflowY: 'auto' }}>
                    {selectedToken?.TokenID ? (
                      <TokenHolders
                        key={selectedToken.TokenID}
                        tokenId={selectedToken.TokenID}
                        onHolderSelect={(address) => {
                          setWalletAddress(address);
                          setActiveTab('wallet');
                        }}
                      />
                    ) : (
                      <Typography variant="caption">
                        Please select a token to view holders.
                      </Typography>
                    )}
                  </UniformContainer>
                </Grid>
              )}
              {activeTab === 'live' && (
                <Grid item xs={12}>
                  <UniformContainer sx={{ minHeight: 'calc(100vh - 16px)', overflowY: 'auto' }}>
                    <LiveTransactions />
                  </UniformContainer>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* Right Sidebar */}
          {showRightSidebar && (
            <Grid item xs={12} md={3} lg={2}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                  height: 'calc(100vh - 16px)',
                  overflowY: 'auto',
                  fontSize: '0.75rem',
                  backgroundColor: '#141414',
                  border: '1px solid #0e0e0e',
                  p: 0.5,
                }}
              >
                <DetailSection elevation={2}>
                  <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#FFD700' }}>
                    Alephium Price
                  </Typography>
                  {alphPriceLoading ? (
                    <Skeleton variant="text" width={80} height={20} sx={{ mx: 'auto' }} />
                  ) : (
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#32CD32', textAlign: 'center' }}>
                      {alphPrice ? formatMoney(alphPrice, '$') : 'N/A'}
                    </Typography>
                  )}
                  <Box mt={1} sx={{ backgroundColor: '#141414', p: 0.5, borderRadius: 4, textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ color: '#FFD700', mb: 0.5, display: 'block' }}>
                      Price fetched from CoinGecko
                    </Typography>
                    <AlphDetailRow label="Market Cap" value={marketCapGlobal ? formatMoney(marketCapGlobal, '$') : 'N/A'} />     
                    <AlphDetailRow label="24h Volume" value={volume24h ? formatMoney(volume24h, '$') : 'N/A'} />
                    <AlphDetailRow label="24h Change" value={change24h ? `${change24h.toFixed(2)}%` : 'N/A'} />
                    <AlphDetailRow label="24h High" value={high24h ? formatMoney(high24h, '$') : 'N/A'} />
                    <AlphDetailRow label="24h Low" value={low24h ? formatMoney(low24h, '$') : 'N/A'} />
                  </Box>
                </DetailSection>
                <DetailSection elevation={2}>
                  <FearGreedIndexDirect />
                </DetailSection>
                <DetailSection elevation={2}>
                  <TransactionStatsGauge pool={selectedPool} />
                </DetailSection>
                {selectedPool && (
                  <DetailSection elevation={2}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#FFD700' }}>
                      {selectedPool.TokenName} Stats
                    </Typography>
                    <Box mt={1} sx={{ backgroundColor: '#141414', p: 0.5, borderRadius: 4, textAlign: 'center' }}>
                      <AlphDetailRow label="Total Supply" value={selectedPool?.total_supply ? formatMoney(selectedPool.total_supply) : 'N/A'} />
                      <AlphDetailRow label="Market Cap" value={selectedPool?.marketcap ? formatMoney(selectedPool.marketcap) : 'N/A'} />
                    </Box>
                    <Box mt={1} sx={{ backgroundColor: '#141414', p: 0.5, borderRadius: 4 }}>
                      <Typography variant="caption" sx={{ fontWeight: 'bold', textAlign: 'center', display: 'block', mb: 0.5 }}>
                        Volume Stats
                      </Typography>
                      <VolumeStatRow
                        label="Day"
                        volume={
                          selectedPool?.DailyVolume_ALPH
                            ? selectedPool.DailyVolume_ALPH
                            : selectedPool?.DailyVolume_ConvertedALPH || 0
                        }
                        marketCap={selectedPool?.marketcap || 0}
                      />
                      <VolumeStatRow
                        label="Week"
                        volume={
                          selectedPool?.WeeklyVolume_ALPH
                            ? selectedPool.WeeklyVolume_ALPH
                            : selectedPool?.WeeklyVolume_ConvertedALPH || 0
                        }
                        marketCap={selectedPool?.marketcap || 0}
                      />
                      <VolumeStatRow
                        label="Month"
                        volume={
                          selectedPool?.MonthlyVolume_ALPH
                            ? selectedPool.MonthlyVolume_ALPH
                            : selectedPool?.MonthlyVolume_ConvertedALPH || 0
                        }
                        marketCap={selectedPool?.marketcap || 0}
                      />
                      <VolumeStatRow
                        label="Year"
                        volume={
                          selectedPool?.YearlyVolume_ALPH
                            ? selectedPool.YearlyVolume_ALPH
                            : selectedPool?.YearlyVolume_ConvertedALPH || 0
                        }
                        marketCap={selectedPool?.marketcap || 0}
                      />
                    </Box>
                  </DetailSection>
                )}
                <DetailSection elevation={2}>
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="subtitle1" align="center" sx={{ fontWeight: 'bold', color: '#FFD700', mb: 0.5 }}>
                      Basic Info
                    </Typography>
                    <Typography variant="caption" align="center" sx={{ display: 'block' }}>
                      <strong>Pool ID:</strong>{' '}
                      <LocalFormattedPoolID poolID={selectedPool?.PoolID} copyFn={copyToClipboard} />
                    </Typography>
                    <Typography variant="caption" align="center">
                      <strong>Pool Name:</strong> {selectedPool?.PoolName}
                    </Typography>
                    <Typography variant="caption" align="center">
                      <strong>Exchange:</strong> {selectedPool?.PoolExchange}
                    </Typography>
                    <Typography variant="caption" align="center">
                      <strong>Description:</strong> {selectedPool?.Description}
                    </Typography>
                    <Box sx={{ borderTop: '1px solid #444', pt: 0.5, textAlign: 'center' }}>
                      <Typography variant="caption" align="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <strong>Token Name:</strong> {selectedPool?.TokenName} ({selectedPool?.Symbol})
                        {selectedPool?.Logo && (
                          <TokenLogo
                            src={selectedPool.Logo.startsWith('http') ? selectedPool.Logo : `https://file.myonion.fun/cdn-cgi/image/width=240,height=240,fit=crop,format=webp,quality=85/${selectedPool.Logo}`}
                            alt={`${selectedPool.TokenName} logo`}
                            style={{ marginLeft: 4 }}
                          />
                        )}
                      </Typography>
                      <Typography variant="caption" align="center">
                        <strong>Token ID:</strong>{' '}
                        <LocalFormattedTokenID tokenID={selectedPool?.TokenID} copyFn={copyToClipboard} />
                      </Typography>
                      <Typography variant="caption" align="center">
                        <strong>Token Address:</strong> {selectedPool?.TokenAddress || 'N/A'}
                      </Typography>
                    </Box>
                    <Box sx={{ borderTop: '1px solid #444', pt: 0.5, textAlign: 'center' }}>
                      <Typography variant="caption" align="center">
                        <strong>Pair Name:</strong> {selectedPool?.PairName}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5, mt: 0.5 }}>
                        {selectedPool?.Token0Logo && (
                          <img src={selectedPool.Token0Logo} alt={`${selectedPool.Token0Name} logo`} style={{ width: 16, height: 16, borderRadius: '50%' }} />
                        )}
                        <Typography variant="caption">
                          <strong>Token 0:</strong> {selectedPool?.Token0Name} ({selectedPool?.Token0Symbol})
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5, mt: 0.5 }}>
                        {selectedPool?.Token1Logo && (
                          <img src={selectedPool.Token1Logo} alt={`${selectedPool.Token1Name} logo`} style={{ width: 16, height: 16, borderRadius: '50%' }} />
                        )}
                        <Typography variant="caption">
                          <strong>Token 1:</strong> {selectedPool?.Token1Name} ({selectedPool?.Token1Symbol})
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </DetailSection>
                <DetailSection elevation={2}>
                  <PoolRatioGauge selectedPool={selectedPool} />
                </DetailSection>
              </Box>
            </Grid>
          )}
          {showLeftSidebar ? (
            <LeftToggleButton onClick={() => setShowLeftSidebar(false)} size="small">
              <ChevronLeftIcon />
            </LeftToggleButton>
          ) : (
            <LeftToggleButton onClick={() => setShowLeftSidebar(true)} size="small" sx={{ left: 0 }}>
              <ChevronRightIcon />
            </LeftToggleButton>
          )}
          {showRightSidebar ? (
            <RightToggleButton onClick={() => setShowRightSidebar(false)} size="small">
              <ChevronRightIcon />
            </RightToggleButton>
          ) : (
            <RightToggleButton onClick={() => setShowRightSidebar(true)} size="small">
              <ChevronLeftIcon />
            </RightToggleButton>
          )}
          <Typography variant="caption" sx={{ color: '#fff', position: 'fixed', bottom: 8, left: 8 }}></Typography>
          <Dialog open={pumpInfoOpen} onClose={() => setPumpInfoOpen(false)}>
            <DialogTitle sx={{ backgroundColor: '#1a1a1e', color: '#FFD700' }}>
              Pump Meter Explanation
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: '#141414', color: '#fff' }}>
              <Typography variant="body1" gutterBottom>
                The pump meter measures a token's daily trading volume relative to its market capitalization.
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Step 1:</strong> Calculate the raw pump value: (VolumeDaily / MarketCap) * 100.
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Step 2:</strong> Apply a logarithmic scale: pumpValue = 20 * log10(rawPump + 1). This reduces the impact of extreme values.
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Step 3:</strong> The value is capped at 100% to keep the gauge within bounds.
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Step 4:</strong> Color coding is applied:
                <br />• Red for low (&lt; 20%),
                <br />• Orange for moderate (20% - 60%),
                <br />• Green for high (&ge; 60%).
              </Typography>
              <Typography variant="body2">
                <strong>Example:</strong> If VolumeDaily is ℵ1,000 and MarketCap is ℵ2,000, then rawPump = 50%. The scaled pumpValue will be approximately 34.2%, displayed in orange.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ backgroundColor: '#141414' }}>
              <Button onClick={() => setPumpInfoOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const TradingDashboard = () => (
  <SWRConfig value={{ fetcher }}>
    <TradingDashboardInner />
  </SWRConfig>
);

export default TradingDashboard;