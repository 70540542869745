// theme.js
import { createTheme } from '@mui/material/styles';

const getCustomTheme = (mode = 'dark') =>
  createTheme({
    palette: {
      mode,
      primary: {
        // Use a vibrant green for primary elements (as seen with gains)
        main: '#32CD32',
      },
      secondary: {
        // Use a vivid red/orange for secondary elements (as seen with losses)
        main: '#FF4500',
      },
      background: {
        default: mode === 'dark' ? '#121212' : '#ffffff',
        paper: mode === 'dark' ? '#1E1E1E' : '#f9f9f9',
      },
      text: {
        primary: mode === 'dark' ? '#ffffff' : '#000000',
        secondary: mode === 'dark' ? '#B0B0B0' : '#555555',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Use sentence case and a slightly smaller font size
            textTransform: 'none',
            fontSize: '0.75rem',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '4px 8px',
            fontSize: '0.75rem',
          },
          head: {
            fontWeight: 'bold',
            color: mode === 'dark' ? '#B0B0B0' : '#555555',
            backgroundColor: mode === 'dark' ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            padding: 16,
            // Slight rounding for a modern look
            borderRadius: 8,
            // A subtle shadow (adjust for dark mode if desired)
            boxShadow: mode === 'dark'
              ? '0px 2px 8px rgba(0, 0, 0, 0.7)'
              : '0px 2px 8px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  });

export default getCustomTheme;