// src/games/poker/deck.js
const suits = ['hearts', 'diamonds', 'clubs', 'spades'];
const values = [
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'J',
    'Q',
    'K',
    'A',
];

/**
 * Function to create a new deck of cards
 * @returns {Array} deck - Array of card objects with suit and value
 */
export const createDeck = () => {
    const deck = [];
    suits.forEach((suit) => {
        values.forEach((value) => {
            deck.push({ suit, value });
        });
    });
    return deck;
};

/**
 * Function to shuffle the deck using Fisher-Yates algorithm
 * @param {Array} deck - The array of card objects to shuffle
 * @returns {Array} - A new shuffled deck
 */
export const shuffleDeck = (deck) => {
    const shuffledDeck = [...deck];
    for (let i = shuffledDeck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
    }
    return shuffledDeck;
};

/**
 * Function to draw a specified number of cards from the deck
 * @param {Array} deck - The array of card objects to draw from
 * @param {number} numberOfCards - The number of cards to draw
 * @returns {Object} - An object containing the drawn cards and the remaining deck
 */
export const drawCards = (deck, numberOfCards) => {
    const drawnCards = deck.slice(0, numberOfCards);
    const remainingDeck = deck.slice(numberOfCards);
    return { drawnCards, remainingDeck };
};
