// WalletConnectModal.js

import React from 'react';
import { motion } from 'framer-motion';
import { FaQrcode, FaDesktop } from 'react-icons/fa';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { useWalletModal } from './walletmodalcontext';
import alphLogo from './assets/logos/ALPH.png'; // Importing the Alephium logo
import './walletmodal.css';
import useWalletConnection from './usewalletconnection'; // Import the custom hook

const WalletConnectModal = () => {
    const { isConnectModalOpen, handleCloseModal } = useWalletModal();
    const { handleWalletConnect } = useWalletConnection(); // Use the custom hook

    if (!isConnectModalOpen) return null;

    return (
        <div className="wallet-modal-overlay">
            <div className="wallet-modal-container">
                <motion.div
                    className="wallet-modal"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.9, opacity: 0 }}
                >
                    {/* Modal Header */}
                    <div className="modal-header">
                        <h2 className="modal-title">Connect a Wallet</h2>
                        <button
                            className="close-modal-btn"
                            onClick={handleCloseModal}
                        >
                            <svg
                                className="close-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Modal Content */}
                    <div className="modal-content">
                        <div className="wallet-options">
                            <h3 className="section-title">New User</h3>
                            <a
                                href="https://alephium.org/#wallets"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="wallet-option"
                            >
                                <img src={alphLogo} alt="Alephium Icon" /> Get
                                an Alephium wallet
                            </a>

                            <h3 className="section-title">Existing User</h3>
                            <button
                                className="wallet-option"
                                onClick={() => handleWalletConnect('injected')} // Use the hook's function
                            >
                                <img src={alphLogo} alt="Alephium Icon" />{' '}
                                Alephium Extension
                            </button>
                            <button
                                className="wallet-option"
                                onClick={() =>
                                    handleWalletConnect('desktopWallet')
                                } // Use the hook's function
                            >
                                <FaDesktop /> Alephium Desktop
                            </button>
                            <button
                                className="wallet-option"
                                onClick={() =>
                                    handleWalletConnect('walletConnect')
                                } // Use the hook's function
                            >
                                <FaQrcode /> Wallet Connect
                            </button>
                            <button className="wallet-option disabled" disabled>
                                <img src={alphLogo} alt="Zelcore Icon" />{' '}
                                Zelcore
                            </button>
                        </div>

                        <div className="wallet-info-section">
                            <h3 className="wallet-info-text">
                                What is a Wallet?
                            </h3>
                            <p className="wallet-info-description">
                                Alephium Wallets are used to send, receive,
                                store, and display all of your digital assets
                                like DOH and NFTs. Select your preferred method
                                or create a new Alephium wallet to get started.
                            </p>
                            <div className="wallet-info-buttons">
                                <a
                                    href="https://alephium.org/#wallets"
                                    target="_blank"
                                    className="get-wallet-btn"
                                >
                                    Get a Wallet
                                </a>
                                <a
                                    href="https://docs.alephium.org/wallet"
                                    target="_blank"
                                    className="learn-more-btn"
                                >
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default WalletConnectModal;
