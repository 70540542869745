// src/utils/pokerUtils.js

// Function to deal cards to players
export const dealCards = (deck, numberOfPlayers, cardsPerPlayer = 2) => {
    const playerHands = [];
    for (let i = 0; i < numberOfPlayers; i++) {
        const hand = [];
        for (let j = 0; j < cardsPerPlayer; j++) {
            hand.push(deck.pop());
        }
        playerHands.push(hand);
    }
    return playerHands;
};

// Function to deal community cards (flop, turn, river)
export const dealCommunityCards = (deck, number) => {
    const communityCards = [];
    for (let i = 0; i < number; i++) {
        communityCards.push(deck.pop());
    }
    return communityCards;
};

// Function to reset and shuffle the deck
export const shuffleDeck = (deck) => {
    return [...deck].sort(() => Math.random() - 0.5);
};

// Function to reset the deck for a new game
export const resetDeck = (deck, suits, values) => {
    deck.length = 0; // Clear the current deck
    // Generate a new deck with the given suits and values
    for (let suit of suits) {
        for (let value of values) {
            deck.push({ suit, value });
        }
    }
    return shuffleDeck(deck); // Shuffle the new deck
};
