// src/tokens/ProfitLoss.js

import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    InputGroup,
    Spinner,
    Alert,
    Table,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import PropTypes from 'prop-types';

function ProfitLoss() {
    const [tokenId, setTokenId] = useState('');
    const [results, setResults] = useState([]); // Initialize as empty array
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(results.length / itemsPerPage);

    const handlePageClick = (selectedItem) => {
        setCurrentPage(selectedItem.selected);
    };

    const formatNumber = (number) => {
        if (typeof number !== 'number') return number;

        // Handle very large or small numbers using scientific notation
        if (Math.abs(number) < 1e-6 || Math.abs(number) >= 1e6) {
            return number.toExponential(2);
        }

        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 18,
        }).format(number);
    };

    const displayData = results.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage,
    );

    const fetchTransactions = async (id) => {
        setLoading(true);
        setError(null);
        setResults([]);
        setCurrentPage(0); // Reset to first page on new search

        try {
            const response = await axios.get(
                `https://dohcloud.azurewebsites.net/api_token_transactions`,
                {
                    params: { tokenId: id },
                },
            );

            console.log('API Response:', response.data); // For debugging

            // Check if response.data is an array
            if (Array.isArray(response.data)) {
                setResults(response.data);
            } else if (
                response.data.transactions &&
                Array.isArray(response.data.transactions)
            ) {
                setResults(response.data.transactions);
            } else {
                console.error('Unexpected API response format:', response.data);
                throw new Error('Unexpected API response format.');
            }
        } catch (err) {
            console.error('API Error:', err); // Log the error for debugging
            let errorMessage = 'An error occurred while fetching data.';
            if (err.response) {
                // Server responded with a status other than 2xx
                errorMessage = `Error: ${
                    err.response.status
                } - ${JSON.stringify(err.response.data)}`;
            } else if (err.request) {
                // Request was made but no response received
                errorMessage = 'No response received from the server.';
            } else if (err.message) {
                // Other errors
                errorMessage = err.message;
            }
            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (tokenId.trim() === '') return;
        fetchTransactions(tokenId.trim());
    };

    return (
        <Container className="my-5">
            <Row className="justify-content-center">
                <Col xs={12} md={8}>
                    <h1 className="text-center mb-4">
                        Token Transactions Dashboard
                    </h1>
                    <Form onSubmit={handleSubmit}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Enter Token ID"
                                value={tokenId}
                                onChange={(e) => setTokenId(e.target.value)}
                                required
                                disabled={loading}
                            />
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                            >
                                <FaSearch />{' '}
                                {loading ? 'Searching...' : 'Search'}
                            </Button>
                        </InputGroup>
                    </Form>

                    {loading && (
                        <div className="text-center my-4">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        </div>
                    )}

                    {error && (
                        <Alert variant="danger" className="mt-4">
                            {error}
                        </Alert>
                    )}

                    {!loading && !error && results.length > 0 && (
                        <>
                            <Table
                                striped
                                bordered
                                hover
                                responsive
                                className="mt-4"
                            >
                                <thead>
                                    <tr>
                                        <th>Address</th>
                                        <th>Average Buy (Point)</th>
                                        <th>Average Sell (Point)</th>
                                        <th>Profit/Loss</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayData.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{entry.address}</td>
                                            <td>
                                                {formatNumber(
                                                    entry.average_buy_in_point,
                                                )}
                                            </td>
                                            <td>
                                                {formatNumber(
                                                    entry.average_sell_point,
                                                )}
                                            </td>
                                            <td>
                                                {entry.profit_loss >= 0 ? (
                                                    <span className="text-success">
                                                        +
                                                        {formatNumber(
                                                            entry.profit_loss,
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span className="text-danger">
                                                        {formatNumber(
                                                            entry.profit_loss,
                                                        )}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {pageCount > 1 && (
                                <ReactPaginate
                                    previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={
                                        'pagination justify-content-center'
                                    }
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                />
                            )}
                        </>
                    )}

                    {!loading && !error && results.length === 0 && tokenId && (
                        <Alert variant="info" className="mt-4">
                            No transactions found for Token ID:{' '}
                            <strong>{tokenId}</strong>
                        </Alert>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

ProfitLoss.propTypes = {
    // If ProfitLoss expects props in the future, define them here
};

export default ProfitLoss;
