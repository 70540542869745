// src/components/TransactionProcessor.js

import React, { useState, useEffect } from 'react';
import TransactionTable from '../tokens/transactiontable'; // Adjust the path if necessary
import TopHoldersTable from '../tokens/topholders'; // Adjust the path if necessary
import axios from 'axios'; // Ensure axios is installed

const TransactionProcessor = () => {
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [errorTransactions, setErrorTransactions] = useState(null);

    const [selectedWalletAddress, setSelectedWalletAddress] = useState(null);
    const [tokenAddress, setTokenAddress] = useState('YOUR_TOKEN_ADDRESS'); // Replace with actual token address
    const [pairAddress, setPairAddress] = useState('YOUR_PAIR_ADDRESS'); // Replace with actual pair address

    // Fetch and merge transactions from both APIs
    useEffect(() => {
        const fetchTransactions = async () => {
            setLoadingTransactions(true);
            setErrorTransactions(null);

            try {
                // Fetch Elexium transactions from dohcloud API
                const elexiumResponse = await axios.get(
                    'https://dohcloud.azurewebsites.net/api_token_transactions',
                );
                const elexiumTransactions = elexiumResponse.data.flatMap(
                    (pool) =>
                        pool.transactions.map((tx) => ({
                            ...tx,
                            source: 'elexium',
                            trade_timestamp: Number(tx.trade_timestamp) * 1000, // Convert seconds to ms if necessary
                        })),
                );

                // Fetch Ayin transactions from Mobula API
                const mobulaResponse = await axios.get(
                    'https://api.mobula.io/api/1/market/trades/pair',
                    {
                        params: { address: pairAddress },
                    },
                );
                const mobulaTransactions = mobulaResponse.data.data.map(
                    (tx) => ({
                        type: tx.type || 'N/A',
                        base_volume: tx.token_amount_vs || 0,
                        target_volume: tx.token_amount || 0,
                        trade_timestamp: tx.date, // Assuming 'date' is in ms
                        tx_id: tx.hash || '',
                        source: 'ayin',
                    }),
                );

                // Merge and sort transactions by timestamp (descending)
                const mergedTransactions = [
                    ...elexiumTransactions,
                    ...mobulaTransactions,
                ].sort((a, b) => b.trade_timestamp - a.trade_timestamp);

                setTransactions(mergedTransactions);
            } catch (err) {
                console.error('Error fetching transactions:', err);
                setErrorTransactions('Failed to load transactions.');
            } finally {
                setLoadingTransactions(false);
            }
        };

        // Only fetch if pairAddress is defined
        if (pairAddress) {
            fetchTransactions();
        }
    }, [pairAddress]);

    // Handle wallet selection (clicked from TransactionTable)
    const handleHolderSelect = (walletAddress) => {
        setSelectedWalletAddress(walletAddress);
        console.log('Selected wallet address:', walletAddress);
    };

    // Handle transaction selection if needed
    const handleTransactionSelect = (txId, poolAddress, walletAddress) => {
        console.log(
            `Transaction Selected: txId=${txId}, poolAddress=${poolAddress}, walletAddress=${walletAddress}`,
        );
        // Additional logic if needed
    };

    return (
        <div className="transaction-processor-container">
            <div className="section">
                <h2>Transactions</h2>
                {loadingTransactions ? (
                    <p>Loading transactions...</p>
                ) : errorTransactions ? (
                    <p style={{ color: 'red' }}>{errorTransactions}</p>
                ) : (
                    <TransactionTable
                        transactions={transactions}
                        onHolderSelect={handleHolderSelect}
                        onTransactionSelect={handleTransactionSelect}
                    />
                )}
            </div>

            <div className="section">
                <h2>Top Holders</h2>
                <TopHoldersTable
                    tokenAddress={tokenAddress}
                    pairAddress={pairAddress}
                    selectedWalletAddress={selectedWalletAddress}
                    onHolderSelect={setSelectedWalletAddress}
                />
            </div>
        </div>
    );
};

export default TransactionProcessor;
