import React from 'react';
import styles from './playerhand.module.css';

// Map suits to symbols
const suitSymbols = {
    hearts: '♥',
    diamonds: '♦',
    clubs: '♣',
    spades: '♠',
};

// Map values to their display equivalents
const valueMap = {
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
    J: 'J',
    Q: 'Q',
    K: 'K',
    A: 'A',
};

const PlayerHand = ({ player, cards, chips, isActive }) => {
    // Prepare classes based on the state
    const playerHandClass = `${styles.playerHand} ${
        isActive ? styles.active : ''
    }`;

    return (
        <div className={playerHandClass} data-chips={`Chips: $${chips}`}>
            <h3 className={styles.playerName}>{player}</h3>
            <div className={styles.cards}>
                {cards.map((card, index) => {
                    const cardValue = valueMap[card.value];
                    const suitSymbol = suitSymbols[card.suit];

                    if (!cardValue || !suitSymbol) return null; // Skip rendering if card details are invalid

                    // Determine if the suit should be displayed in red or black
                    const suitColorClass =
                        card.suit === 'hearts' || card.suit === 'diamonds'
                            ? styles.redSuit
                            : styles.blackSuit;

                    return (
                        <div key={index} className={styles.customCard}>
                            <span
                                className={`${styles.cardValue} ${suitColorClass}`}
                            >
                                {cardValue}
                            </span>
                            <span
                                className={`${styles.cardSuit} ${suitColorClass}`}
                            >
                                {suitSymbol}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PlayerHand;
