import { useEffect, useState, useRef, useCallback } from 'react';

const useWebPubSub = (groupName) => {
  const [liveCandleUpdates, setLiveCandleUpdates] = useState([]);
  const [snapshot, setSnapshot] = useState(null);
  const wsRef = useRef(null);

  useEffect(() => {
    let pingInterval;

    if (!groupName) {
      console.log("[WS] No group name provided. Skipping WebSocket connection.");
      return;
    }

    const fetchWsUrl = async () => {
      try {
        console.log("[WS] Fetching WebSocket URL via proxy...");
        // Call the proxy endpoint instead of the direct Azure Function URL
        const response = await fetch("https://api.doh.money/proxy/v2_proxy?route=generatewebsocketURL", {
          headers: {
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_PUBLIC || ""
          }
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch WebSocket URL: ${response.statusText}`);
        }
        const data = await response.json();
        const { clientUrl } = data;
        if (!clientUrl) {
          throw new Error("No WebSocket URL provided in the response.");
        }
        const wsUrl = clientUrl.startsWith("wss://")
          ? clientUrl
          : clientUrl.replace(/^https:\/\//i, 'wss://');
        console.log("[WS] Using WebSocket URL:", wsUrl);

        const ws = new WebSocket(wsUrl);
        wsRef.current = ws;

        ws.onopen = (event) => {
          console.log("✅ WebSocket onopen fired", event);
          const joinMessage = JSON.stringify({ action: "join", group: groupName });
          ws.send(joinMessage);
          console.log("[WS] Joining group:", groupName, "with message:", joinMessage);

          pingInterval = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({ type: "ping" }));
              console.log("[WS] Ping sent");
            }
          }, 30000);
        };

        ws.onmessage = (event) => {
          console.log("[WS] Raw message received:", event.data);
          try {
            const message = JSON.parse(event.data);
            console.log("[WS] Parsed message:", message);
            if (message.type === 'snapshot') {
              console.log("[WS] Received snapshot data:", message.data);
              setSnapshot(message.data);
            } else if (message.type === 'candle_update' && message.data) {
              console.log("[WS] Received candle_update data:", message.data);
              setLiveCandleUpdates(prev => {
                const index = prev.findIndex(c => c.time === message.data.time);
                if (index !== -1) {
                  const updated = [...prev];
                  updated[index] = {
                    ...updated[index],
                    high: Math.max(updated[index].high, message.data.high),
                    low: Math.min(updated[index].low, message.data.low),
                    close: message.data.close,
                    volume: updated[index].volume + message.data.volume,
                  };
                  return updated;
                } else {
                  return [...prev, message.data].sort((a, b) => a.time - b.time);
                }
              });
            } else {
              console.log("[WS] Unhandled message type:", message.type);
            }
          } catch (error) {
            console.error("[WS] Error parsing message:", error);
          }
        };

        ws.onerror = (error) => {
          console.error("[WS] WebSocket error:", error);
        };

        ws.onclose = (event) => {
          console.log("🔴 WebSocket connection closed", event);
        };
      } catch (error) {
        console.error("[WS] Error fetching WebSocket URL:", error);
      }
    };

    fetchWsUrl();

    return () => {
      if (pingInterval) {
        clearInterval(pingInterval);
        console.log("[WS] Cleared ping interval");
      }
      if (wsRef.current) {
        console.log("[WS] Closing WebSocket connection...");
        wsRef.current.close();
      }
    };
  }, [groupName]);

  const sendMessage = useCallback((message) => {
    const ws = wsRef.current;
    if (!ws) {
      console.error("[WS] No WebSocket instance available.");
      return;
    }
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(message));
      console.log("[WS] Sent message:", message);
    } else if (ws.readyState === WebSocket.CONNECTING) {
      console.log("[WS] WebSocket is connecting; waiting to send message...");
      ws.addEventListener('open', () => {
        ws.send(JSON.stringify(message));
        console.log("[WS] Sent message after open:", message);
      }, { once: true });
    } else {
      console.error("[WS] Cannot send message, WebSocket is not open.");
    }
  }, []);

  return { liveCandleUpdates, snapshot, sendMessage };
};

export default useWebPubSub;