import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import * as d3 from 'd3';
import {
  ThemeProvider,
  CssBaseline,
  Box,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Tabs,
  Tab,
  Collapse,
  Button,
  Skeleton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  ContentCopy as ContentCopyIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import TokenTransactionV2 from './transactions';
import TradingChart from './tradingchart';
import WalletSummaryView from './walletsummary';
import PoolSummaryView from './poolsummary';
import getCustomTheme from './theme';
import GaugeChart from 'react-gauge-chart';

// ------------------------------------------------------------------
// Environment Variables with Fallback Defaults & Debug Logging
// ------------------------------------------------------------------
const TRANSACTIONS_API_URL = 'https://api.doh.money/single/api/v2_getpool_single';

const CHARTDATA_API_URL = 'https://api.doh.money/single/v2_getpool_single_chart';

const PUBLIC_KEY = 'edf899c60f4249d8857c45477cf5c575';



// ------------------------------------------------------------------
// CUSTOM HOOK: useMexcWebSocket
// ------------------------------------------------------------------
const MEXC_WS_URL = 'wss://wbs.mexc.com/ws';
const LIQUIDATION_THRESHOLD = 500; // adjust based on ALPH volume trends

const useMexcWebSocket = () => {
  const [liquidations, setLiquidations] = useState([]);
  useEffect(() => {
    const ws = new WebSocket(MEXC_WS_URL);
    ws.onopen = () => {
      console.log("Connected to MEXC WebSocket");
      const subscriptionMessage = {
        method: "SUBSCRIPTION",
        params: ["spot@public.deals.v3.api@ALPHUSDT"],
        id: 1,
      };
      ws.send(JSON.stringify(subscriptionMessage));
    };
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.data && data.data.deals) {
        data.data.deals.forEach((trade) => {
          const price = parseFloat(trade.p);
          const volume = parseFloat(trade.v);
          const tradeType = trade.T === 1 ? "BUY" : "SELL";
          if (tradeType === "SELL" && volume >= LIQUIDATION_THRESHOLD) {
            const newLiquidation = {
              price,
              volume,
              timestamp: new Date().toLocaleTimeString(),
            };
            setLiquidations((prev) => [newLiquidation, ...prev.slice(0, 10)]);
          }
        });
      }
    };
    ws.onerror = (error) => console.error("WebSocket Error:", error);
    ws.onclose = () => console.log("MEXC WebSocket Disconnected");
    return () => ws.close();
  }, []);
  return liquidations;
};

// ------------------------------------------------------------------
// LIQUIDATION TRACKER COMPONENT
// ------------------------------------------------------------------
const LiquidationTracker = () => {
  const liquidations = useMexcWebSocket();
  const [openInfo, setOpenInfo] = useState(false);
  return (
    <Paper elevation={3} sx={{ padding: 1, mt: 1, backgroundColor: "#2E2E2E" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FF4444" }}>
          🚨 MEXC Liquidations
        </Typography>
        <IconButton size="small" onClick={() => setOpenInfo(true)}>
          <InfoOutlinedIcon sx={{ fontSize: 16, color: "#FFD700" }} />
        </IconButton>
      </Box>
      <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
        <DialogTitle>Liquidation Tracker Info</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This panel displays recent large sell orders for ALPH (above the threshold). These orders may indicate liquidation events.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInfo(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ maxHeight: "150px", overflowY: "auto", mt: 1 }}>
        {liquidations.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No liquidations detected.
          </Typography>
        ) : (
          liquidations.map((liq, index) => (
            <Typography key={index} variant="caption" sx={{ color: "#FFD700", mb: 0.5, display: 'block' }}>
              🔻 {liq.timestamp} - {liq.volume} ALPH at ${liq.price}
            </Typography>
          ))
        )}
      </Box>
    </Paper>
  );
};

// ------------------------------------------------------------------
// STYLED COMPONENTS
// ------------------------------------------------------------------
const DarkBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#1E1E1E",
  color: theme.palette.text.primary,
  minHeight: "100vh",
  width: "100%",
  padding: theme.spacing(1),
}));

const Sidebar = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  height: "calc(100vh - 16px)",
  overflowY: "auto",
  border: `1px solid ${theme.palette.divider}`,
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "3px",
  },
}));

const TokenItem = styled(Box)(({ theme, selected }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: selected ? `2px solid ${theme.palette.primary.main}` : "1px solid transparent",
  backgroundColor: selected ? theme.palette.action.hover : "transparent",
  color: theme.palette.text.primary,
  cursor: "pointer",
  marginBottom: theme.spacing(0.5),
  transition: "border-color 0.2s, transform 0.2s",
  "&:hover": {
    transform: "scale(1.01)",
    backgroundColor: "rgba(50,205,50,0.1)",
  },
}));

const PoolItem = styled(Box)(({ theme, selected }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.25),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: selected ? theme.palette.action.selected : "transparent",
  color: selected ? theme.palette.warning.main : theme.palette.text.primary,
  cursor: "pointer",
  marginBottom: theme.spacing(0.25),
  transition: "background-color 0.2s",
}));

const PoolList = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(0.5),
}));

const TokenLogo = styled("img")({
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  transition: "opacity 0.3s ease-in-out",
});

const ChartContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: "#000000",
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const TransactionsContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  height: "calc(100vh - 16px)",
  overflowY: "auto",
}));

const DetailSection = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  backgroundColor: "#2E2E2E",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s ease, background-color 0.3s ease",
  "&:hover": {
    transform: "scale(1.01)",
    backgroundColor: "#333333",
  },
}));

const GaugeWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#2E2E2E",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
  border: `2px solid ${theme.palette.primary.main}`,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const scrollLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const TickerContainer = styled(Box)({
  overflow: "hidden",
  backgroundColor: "#000",
  padding: "4px 0",
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
  position: "relative",
  whiteSpace: "nowrap",
});

const TickerContent = styled(Box)({
  display: "inline-flex",
  whiteSpace: "nowrap",
  animation: `${scrollLeft} 600s linear infinite`,
  minWidth: "400%",
});

const TickerBar = ({ tokens }) => {
  return (
    <TickerContainer>
      <TickerContent>
        {[...tokens, ...tokens, ...tokens].map((token, index) => {
          const percentChange =
            token.FirstPoolRatio && token.LatestPoolRatio
              ? ((token.LatestPoolRatio - token.FirstPoolRatio) / token.FirstPoolRatio) * 100
              : 0;
          return (
            <Box
              key={`${token.TokenID}-${index}`}
              sx={{
                display: "inline-flex",
                alignItems: "center",
                marginRight: 1,
                padding: "0 5px",
                borderRight: "1px solid #444",
              }}
            >
              {token.Logo && (
                <img
                  src={token.Logo}
                  alt={`${token.TokenName} logo`}
                  style={{ width: 20, height: 20, borderRadius: "50%", marginRight: 4 }}
                />
              )}
              <Typography variant="caption" sx={{ color: "#fff", marginRight: 2 }}>
                {token.Symbol}
              </Typography>
              <Typography variant="caption" sx={{ color: percentChange >= 0 ? "#00FF00" : "#FF4444" }}>
                {percentChange.toFixed(2)}%
              </Typography>
            </Box>
          );
        })}
      </TickerContent>
    </TickerContainer>
  );
};

// ------------------------------------------------------------------
// HELPER FUNCTIONS
// ------------------------------------------------------------------
const formatMoney = (amount) => {
  if (amount == null || isNaN(amount)) return "N/A";
  return `$${Number(amount).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const formatRatio = (ratio) => {
  if (ratio == null || isNaN(ratio)) return "N/A";
  const num = parseFloat(ratio);
  if (num >= 1) return num.toFixed(3);
  const str = num.toFixed(5);
  return str.replace(/^0+/, "");
};

const formatTokenID = (tokenID) => {
  if (!tokenID) return "N/A";
  return `${tokenID.slice(0, 4)}...${tokenID.slice(-4)}`;
};

const formatTokenPriceWithSubscript = (price) => {
  if (!price || isNaN(price)) return "N/A";
  return `${Number(price).toFixed(6)} ℵ`;
};

const AlphDetailRow = ({ label, value }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" py={0.25} borderBottom="1px solid #444">
    <Typography variant="caption" sx={{ fontWeight: "bold", color: "#32CD32" }}>
      {label}
    </Typography>
    <Typography variant="caption" sx={{ color: "#FFD700" }}>
      {value}
    </Typography>
  </Box>
);

const LocalFormattedTokenID = ({ tokenID, copyFn }) => (
  <Tooltip title={tokenID} arrow>
    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
      <Typography variant="caption" sx={{ cursor: "pointer", mr: 0.5 }} onClick={() => copyFn(tokenID)}>
        {formatTokenID(tokenID)}
      </Typography>
      <IconButton size="small" onClick={() => copyFn(tokenID)}>
        <ContentCopyIcon sx={{ fontSize: "14px", color: "#B0B0B0" }} />
      </IconButton>
    </Box>
  </Tooltip>
);

const LocalFormattedPoolID = ({ poolID, copyFn }) => (
  <Tooltip title={poolID} arrow>
    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
      <Typography variant="caption" sx={{ cursor: "pointer", mr: 0.5 }} onClick={() => copyFn(poolID)}>
        {formatTokenID(poolID)}
      </Typography>
      <IconButton size="small" onClick={() => copyFn(poolID)}>
        <ContentCopyIcon sx={{ fontSize: "14px", color: "#B0B0B0" }} />
      </IconButton>
    </Box>
  </Tooltip>
);

// ------------------------------------------------------------------
// POOL RATIO GAUGE COMPONENT
// ------------------------------------------------------------------
const PoolRatioGauge = ({ selectedPool }) => {
  const [openInfo, setOpenInfo] = useState(false);
  if (!selectedPool || !selectedPool.FirstPoolRatio || !selectedPool.HighestPoolRatio) {
    return <Typography variant="caption" color="text.secondary">No ratio data available</Typography>;
  }
  const minRatio = parseFloat(selectedPool.FirstPoolRatio);
  const maxRatio = parseFloat(selectedPool.HighestPoolRatio);
  const currentRatio = parseFloat(selectedPool.LatestPoolRatio);
  if (isNaN(minRatio) || isNaN(maxRatio) || isNaN(currentRatio)) return null;
  const normalizedValue = (currentRatio - minRatio) / (maxRatio - minRatio);
  return (
    <GaugeWrapper>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
          Pool Ratio Gauge
        </Typography>
        <IconButton size="small" onClick={() => setOpenInfo(true)}>
          <InfoOutlinedIcon sx={{ fontSize: 16, color: "#FFD700" }} />
        </IconButton>
      </Box>
      <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
        <DialogTitle>Pool Ratio Gauge Info</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            The Pool Ratio Gauge indicates the current price ratio compared to the historical minimum and maximum ratios.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInfo(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <GaugeChart
        id="pool-ratio-speedometer"
        nrOfLevels={20}
        percent={normalizedValue}
        colors={["#d32f2f", "#FFA500", "#00c853"]}
        arcWidth={0.3}
        needleColor="#fff"
        textColor="#fff"
        formatTextValue={() => `${currentRatio.toFixed(5)} ℵ`}
      />
      <Typography variant="caption" mt={1} color="text.secondary">
        Min: {minRatio.toFixed(5)} ℵ | Max: {maxRatio.toFixed(5)} ℵ
      </Typography>
    </GaugeWrapper>
  );
};

// ------------------------------------------------------------------
// FEAR & GREED BAR GAUGE COMPONENT
// ------------------------------------------------------------------
const FearGreedBarGauge = () => {
  const [data, setData] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const svgRef = useRef();
  useEffect(() => {
    axios
      .get("https://api.doh.money/single/v2_feargreed", {
        headers: { "Ocp-Apim-Subscription-Key": PUBLIC_KEY },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => console.error("Error fetching Fear & Greed Index:", err));
  }, []);
  useEffect(() => {
    if (data) {
      const index = +data.index;
      const width = 130;
      const height = 16;
      const svg = d3.select(svgRef.current).attr("width", width).attr("height", height);
      svg.selectAll("*").remove();
      svg.append("rect").attr("width", width).attr("height", height).attr("fill", "#444");
      let fillColor = "#00c853";
      if (index < 25) fillColor = "#d32f2f";
      else if (index < 50) fillColor = "#FFA500";
      else if (index < 75) fillColor = "#FFD700";
      else fillColor = "#00c853";
      svg.append("rect").attr("width", (index / 100) * width).attr("height", height).attr("fill", fillColor);
      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("dy", ".35em")
        .attr("text-anchor", "middle")
        .attr("fill", "#fff")
        .style("font-size", "10px")
        .text(`${data.index} - ${data.classification}`);
    }
  }, [data]);
  return (
    <Box sx={{ textAlign: "center", mt: 1 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
          Fear & Greed
        </Typography>
        <IconButton size="small" onClick={() => setOpenInfo(true)}>
          <InfoOutlinedIcon sx={{ fontSize: 16, color: "#FFD700" }} />
        </IconButton>
      </Box>
      <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
        <DialogTitle>Fear & Greed Index Info</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This gauge reflects market sentiment based on the Fear & Greed Index.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInfo(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {data ? (
        <svg ref={svgRef}></svg>
      ) : (
        <CircularProgress size={16} color="inherit" />
      )}
    </Box>
  );
};

// ------------------------------------------------------------------
// MAIN DASHBOARD COMPONENT
// ------------------------------------------------------------------
const TradingDashboardInner = () => {
  const [mode] = useState("dark");
  const theme = useMemo(() => getCustomTheme(mode), [mode]);

  // Dashboard state variables
  const [expandedTokenIds, setExpandedTokenIds] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("transactions_desc");
  const [activeTab, setActiveTab] = useState("transactions");
  const [walletAddress, setWalletAddress] = useState("");
  const [tradingViewLoaded, setTradingViewLoaded] = useState(false);
  const [chartRequested, setChartRequested] = useState(false);

  // Expanded sections for the right sidebar
  const [expandedSections, setExpandedSections] = useState({
    alph: false,
    basicInfo: true,
    ratios: true,
    performance: true,
  });
  const toggleExpandSection = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // Load TradingView script on demand
  useEffect(() => {
    if (chartRequested && !window.TradingView) {
      const script = document.createElement("script");
      script.src = "/tradingview/charting_library/charting_library.js";
      script.async = true;
      script.onload = () => setTradingViewLoaded(true);
      script.onerror = () => console.error("Failed to load TradingView library");
      document.body.appendChild(script);
    }
  }, [chartRequested]);

  // Automatically load chart when a pool is selected
  useEffect(() => {
    if (selectedPool) {
      setChartRequested(true);
    }
  }, [selectedPool]);

  // ------------------------------------------------------------------
  // Single API query to fetch pool (tokenData) and transaction data
  // ------------------------------------------------------------------
  const {
    data: poolApiData,
    error: poolApiError,
    isLoading: loadingPoolApi,
  } = useQuery(
    "poolApiData",
    async () => {
      const response = await axios.get(
        "https://api.doh.money/single/api/v2_getpool_single?tokenId=b067767804137fa913fe30a26a7c5f397faba36aaa7b9650327ae4e6e9305d00",
        { headers: { "Ocp-Apim-Subscription-Key": PUBLIC_KEY } }
      );
      return response.data;
    }
  );

  // Group tokenData into tokens array (each token gets an allPools array)
  const tokens = useMemo(() => {
    if (!poolApiData?.tokenData) return [];
    const tokenMap = poolApiData.tokenData.reduce((acc, pool) => {
      if (!acc[pool.TokenID]) {
        acc[pool.TokenID] = { ...pool, allPools: [] };
      }
      acc[pool.TokenID].allPools.push(pool);
      return acc;
    }, {});
    return Object.values(tokenMap);
  }, [poolApiData]);

  // Use transactions array from the API response
  const transactions = poolApiData?.transactions || [];

  const handleSortChange = (event) => setSortOption(event.target.value);
  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const sortedFilteredTokens = useMemo(() => {
    if (!tokens) return [];
    const lowerQuery = searchQuery.toLowerCase();
    const filtered = tokens.filter(
      (token) =>
        (token.TokenName || "").toLowerCase().includes(lowerQuery) ||
        (token.Symbol || "").toLowerCase().includes(lowerQuery)
    );
    const [option, direction] = sortOption.split("_");
    return filtered.sort((a, b) => {
      const getValue = (token) => {
        switch (option) {
          case "name":
            return token.TokenName || "";
          case "price":
            return token.LatestPoolRatio || 0;
          case "multiplier":
            return (token.Latest_X || 0) * 1000 + (token.ATH_X || 0);
          case "transactions":
            return token.TransactionCount || 0;
          default:
            return 0;
        }
      };
      const aVal = getValue(a);
      const bVal = getValue(b);
      if (typeof aVal === "string") {
        return direction === "asc" ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      }
      return direction === "asc" ? aVal - bVal : bVal - aVal;
    });
  }, [tokens, searchQuery, sortOption]);

  const handleTokenToggle = (tokenId) => {
    setExpandedTokenIds((prev) =>
      prev.includes(tokenId) ? prev.filter((id) => id !== tokenId) : [...prev, tokenId]
    );
  };

  // When selecting a token, default to its first pool entry
  const handleTokenSelect = (token) => {
    setSelectedPool(null);
    setChartRequested(false);
    setExpandedSections({
      alph: false,
      basicInfo: true,
      ratios: true,
      performance: true,
    });
    if (token.allPools && token.allPools.length > 0) {
      const defaultPool = token.allPools[0]; // use the first pool from tokenData
      setSelectedPool(defaultPool);
    }
  };

  const handlePoolSelect = (pool) => {
    setSelectedPool(pool);
    setChartRequested(false);
    setExpandedSections({
      alph: false,
      basicInfo: true,
      ratios: true,
      performance: true,
    });
  };

  const handleLoadChartClick = () => {
    setChartRequested(true);
  };

  const handleAddressClick = (address) => {
    setWalletAddress(address);
    setActiveTab("wallet");
  };

  const copyToClipboard = (text) => {
    if (!text) return;
    navigator.clipboard.writeText(text).catch((err) => console.error("Copy failed:", err));
  };

  // ------------------------------------------------------------------
  // Chart Data Query: Fetch chart data based on selectedPool
  // ------------------------------------------------------------------
  const {
    data: chartData = [],
    error: chartError,
    isLoading: loadingChart,
    refetch: refetchChart,
  } = useQuery(
    ["chartData", selectedPool?.PoolID],
    async () => {
      const chartResponse = await axios.get(`${CHARTDATA_API_URL}/${selectedPool.PoolID}`, {
        headers: { "Ocp-Apim-Subscription-Key": PUBLIC_KEY },
        params: { token_id: selectedPool.TokenID },
      });
      const rawData = chartResponse.data || [];
      return rawData
        .filter((item) => item.time && item.close !== null)
        .map((item) => ({
          time: Number(item.time),
          low: item.low ?? item.close ?? 0,
          high: item.high ?? item.close ?? 0,
          open: item.open ?? item.close ?? 0,
          close: item.close ?? 0,
          volume: item.volume || 0,
        }));
    },
    {
      enabled: !!selectedPool?.PoolID && chartRequested,
      staleTime: 30000,
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DarkBox>
        {tokens && tokens.length > 0 && <TickerBar tokens={tokens} />}
        <Grid container spacing={1}>
          {/* Left Sidebar */}
          <Grid item xs={12} md={3} lg={2}>
            <Sidebar>
              <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
                Token Pools
              </Typography>
              <Box mb={1}>
                <FormControl fullWidth size="small">
                  <InputLabel id="sort-select-label">Sort By</InputLabel>
                  <Select
                    labelId="sort-select-label"
                    id="sort-select"
                    value={sortOption}
                    label="Sort By"
                    onChange={handleSortChange}
                  >
                    <MenuItem value="name_asc">Name (A-Z)</MenuItem>
                    <MenuItem value="name_desc">Name (Z-A)</MenuItem>
                    <MenuItem value="price_asc">Price (Low to High)</MenuItem>
                    <MenuItem value="price_desc">Price (High to Low)</MenuItem>
                    <MenuItem value="multiplier_asc">Multiplier (Low to High)</MenuItem>
                    <MenuItem value="multiplier_desc">Multiplier (High to Low)</MenuItem>
                    <MenuItem value="transactions_asc">Txs (Low to High)</MenuItem>
                    <MenuItem value="transactions_desc">Txs (High to Low)</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={1}>
                <TextField
                  label="Search Tokens"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Token name or symbol..."
                  fullWidth
                  InputProps={{ sx: { backgroundColor: "background.paper" } }}
                  InputLabelProps={{ sx: { color: "text.secondary" } }}
                />
              </Box>
              <Divider sx={{ mb: 1 }} />
              {loadingPoolApi ? (
                <Box>
                  <Skeleton variant="rectangular" height={30} />
                  <Skeleton variant="rectangular" height={30} sx={{ mt: 1 }} />
                </Box>
              ) : poolApiError ? (
                <Typography color="error" variant="caption">
                  {poolApiError.message}
                </Typography>
              ) : (
                sortedFilteredTokens.map((token) => {
                  const displayName =
                    token.TokenName && token.TokenName.length > 12
                      ? token.TokenName.substring(0, 12) + "..."
                      : token.TokenName;
                  return (
                    <Box key={token.TokenID}>
                      <TokenItem
                        selected={selectedPool?.TokenID === token.TokenID}
                        onClick={() => {
                          handleTokenToggle(token.TokenID);
                          handleTokenSelect(token);
                        }}
                      >
                        {token.Logo && (
                          <TokenLogo
                            src={token.Logo}
                            alt={`${token.TokenName} logo`}
                            style={{ marginRight: 4 }}
                          />
                        )}
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="caption">{token.Symbol}</Typography>
                          <Typography variant="caption" sx={{ color: theme.palette.warning.main }}>
                            {displayName}
                          </Typography>
                        </Box>
                        <Typography variant="caption" align="right" sx={{ width: 50, whiteSpace: "nowrap" }}>
                          {formatRatio(token.LatestPoolRatio)} ℵ
                        </Typography>
                      </TokenItem>
                      {expandedTokenIds.includes(token.TokenID) && token.allPools && (
                        <PoolList>
                          {token.allPools.map((pool) => (
                            <PoolItem
                              key={pool.PoolID}
                              selected={selectedPool?.PoolID === pool.PoolID}
                              onClick={() => handlePoolSelect(pool)}
                            >
                              <Box display="flex" alignItems="center" mr={1}>
                                <Box position="relative" width={28} height={20} display="flex">
                                  {token.PairLogo2 && (
                                    <TokenLogo
                                      src={token.PairLogo2}
                                      alt={`${token.TokenName} Pair Logo 2`}
                                      style={{
                                        width: 16,
                                        height: 16,
                                        border: "1px solid white",
                                        borderRadius: "50%",
                                        opacity: 0.8,
                                        position: "absolute",
                                        left: 4,
                                        zIndex: 1,
                                      }}
                                    />
                                  )}
                                  {token.PairLogo1 && (
                                    <TokenLogo
                                      src={token.PairLogo1}
                                      alt={`${token.TokenName} Pair Logo 1`}
                                      style={{
                                        width: 16,
                                        height: 16,
                                        border: "1px solid white",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        left: 0,
                                        zIndex: 2,
                                      }}
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Typography variant="caption" sx={{ flexGrow: 1 }}>
                                {`${pool.PoolExchange} - ${pool.PairName}`}
                              </Typography>
                              <Typography variant="caption" sx={{ color: theme.palette.warning.main }}>
                                {formatRatio(pool.LatestPoolRatio)} ℵ
                              </Typography>
                            </PoolItem>
                          ))}
                        </PoolList>
                      )}
                    </Box>
                  );
                })
              )}
            </Sidebar>
          </Grid>

          {/* Main Content Area */}
          <Grid item xs={12} md={6} lg={8}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ChartContainer>
                  {loadingChart ? (
                    <Box display="flex" alignItems="center">
                      <CircularProgress size={30} color="primary" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        Loading Chart...
                      </Typography>
                    </Box>
                  ) : chartError ? (
                    <Typography color="error" variant="caption">
                      {chartError.message}
                    </Typography>
                  ) : chartData.length > 0 ? (
                    <TradingChart
                      chartData={chartData}
                      isDarkMode={mode === "dark"}
                      pairName={selectedPool?.PairName || ""}
                      loading={loadingChart}
                      error={chartError}
                    />
                  ) : !chartRequested ? (
                    <Button onClick={handleLoadChartClick}>Load Chart</Button>
                  ) : (
                    <Typography variant="caption">No chart data available.</Typography>
                  )}
                </ChartContainer>
              </Grid>
              <Grid item xs={12}>
                <Tabs
                  value={activeTab}
                  onChange={(e, newVal) => setActiveTab(newVal)}
                  indicatorColor="primary"
                  textColor="inherit"
                  sx={{ mb: 1, "& .MuiTab-root": { padding: "2px 4px" } }}
                >
                  <Tab label="Transactions" value="transactions" />
                  <Tab label="Wallet" value="wallet" />
                  <Tab label="Summary" value="summary" />
                </Tabs>
              </Grid>
              {activeTab === "transactions" ? (
                <Grid item xs={12}>
                  <TransactionsContainer>
                    <TokenTransactionV2
                      transactions={transactions}
                      poolData={tokens}
                      tokenList={tokens.map((token) => ({
                        id: token.TokenID,
                        symbol: token.Symbol,
                        name: token.TokenName,
                        logo: token.Logo,
                        price: formatTokenPriceWithSubscript(token.LatestPoolRatio),
                      }))}
                      onPoolSelect={handlePoolSelect}
                      searchQuery={searchQuery}
                      onWalletClick={handleAddressClick}
                    />
                  </TransactionsContainer>
                </Grid>
              ) : activeTab === "wallet" ? (
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 1,
                      minHeight: "calc(100vh - 16px)",
                      overflowY: "auto",
                    }}
                  >
                    <Box mb={1}>
                      <TextField
                        label="Enter Wallet Address"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                      />
                    </Box>
                    {selectedPool ? (
                      <WalletSummaryView tokenId={selectedPool.TokenID} walletAddress={walletAddress} />
                    ) : (
                      <Typography variant="caption">Please select a pool for the wallet view.</Typography>
                    )}
                  </Paper>
                </Grid>
              ) : activeTab === "summary" ? (
                <Grid item xs={12}>
                  <Paper sx={{ p: 1, minHeight: "calc(100vh - 16px)", overflowY: "auto" }}>
                    {selectedPool ? (
                      <PoolSummaryView poolSummary={selectedPool} onAddressClick={handleAddressClick} />
                    ) : (
                      <Typography variant="caption">Please select a pool to view summary.</Typography>
                    )}
                  </Paper>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {/* Enhanced Right Sidebar */}
          <Grid item xs={12} md={3} lg={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                height: "calc(100vh - 16px)",
                overflowY: "auto",
              }}
            >
              {/* ALPH Price Card */}
              <DetailSection elevation={2}>
                <Box
                  onClick={() => toggleExpandSection("alph")}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
                    Alephium Price
                  </Typography>
                  {expandedSections.alph ? (
                    <ExpandLessIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  )}
                </Box>
                {loadingPoolApi ? (
                  <Skeleton variant="text" width={80} height={20} sx={{ margin: "auto" }} />
                ) : (
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#32CD32", textAlign: "center" }}>
                    N/A
                  </Typography>
                )}
                <Collapse in={expandedSections.alph}>
                  <Box mt={1} sx={{ backgroundColor: "#1E1E1E", p: 1, borderRadius: 1 }}>
                    <Typography variant="caption" sx={{ color: "#FFD700", mb: 1 }}>
                      ALPH Price Details
                    </Typography>
                    <AlphDetailRow label="Volume (24h)" value={"N/A"} />
                    <AlphDetailRow label="Market Cap" value={"N/A"} />
                    <AlphDetailRow label="Fully Diluted MC" value={"N/A"} />
                  </Box>
                </Collapse>
              </DetailSection>

              {/* Liquidation Tracker Component */}
              <DetailSection elevation={2}>
                <LiquidationTracker />
              </DetailSection>

              {/* Fear & Greed Card */}
              <DetailSection elevation={2} sx={{ textAlign: "center" }}>
                <FearGreedBarGauge />
              </DetailSection>

              {/* Basic Information Card */}
              <DetailSection elevation={2}>
                <Box
                  onClick={() => toggleExpandSection("basicInfo")}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
                    Basic Information
                  </Typography>
                  {expandedSections.basicInfo ? (
                    <ExpandLessIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  )}
                </Box>
                <Collapse in={expandedSections.basicInfo}>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="caption">
                      <strong>Pool ID:</strong>{" "}
                      <LocalFormattedPoolID poolID={selectedPool?.PoolID} copyFn={copyToClipboard} />
                    </Typography>
                    <Typography variant="caption">
                      <strong>Pool Name:</strong> {selectedPool?.PoolName}
                    </Typography>
                    <Typography variant="caption">
                      <strong>Exchange:</strong> {selectedPool?.PoolExchange}
                    </Typography>
                    <Typography variant="caption">
                      <strong>Description:</strong> {selectedPool?.Description}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, borderTop: "1px solid #444", pt: 1 }}>
                    <Typography variant="caption">
                      <strong>Token Name:</strong> {selectedPool?.TokenName} ({selectedPool?.Symbol})
                    </Typography>
                    {selectedPool?.Logo && (
                      <Box mt={1} textAlign="center">
                        <img src={selectedPool?.Logo} alt={`${selectedPool?.TokenName} logo`} style={{ width: 40, height: 40 }} />
                      </Box>
                    )}
                    <Box mt={1} display="flex" alignItems="center">
                      <Typography variant="caption" component="span" sx={{ mr: 1 }}>
                        <strong>Token ID:</strong>
                      </Typography>
                      <LocalFormattedTokenID tokenID={selectedPool?.TokenID} copyFn={copyToClipboard} />
                    </Box>
                    <Typography variant="caption">
                      <strong>Token Address:</strong> {selectedPool?.TokenAddress || "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1, borderTop: "1px solid #444", pt: 1 }}>
                    <Typography variant="caption">
                      <strong>Pair Name:</strong> {selectedPool?.PairName}
                    </Typography>
                    <Typography variant="caption">
                      <strong>Token 0:</strong> {selectedPool?.Token0Name} ({selectedPool?.Token0Symbol})
                    </Typography>
                    <Typography variant="caption">
                      <strong>Token 1:</strong> {selectedPool?.Token1Name} ({selectedPool?.Token1Symbol})
                    </Typography>
                  </Box>
                </Collapse>
              </DetailSection>

              {/* Ratios & Timestamps Card */}
              <DetailSection elevation={2}>
                <Box
                  onClick={() => toggleExpandSection("ratios")}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
                    Pool Ratios & Timestamps
                  </Typography>
                  {expandedSections.ratios ? (
                    <ExpandLessIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  )}
                </Box>
                <Collapse in={expandedSections.ratios}>
                  <Typography variant="caption">
                    <strong>First Ratio:</strong> {formatRatio(selectedPool?.FirstPoolRatio)}
                    <span style={{ fontSize: "0.65rem", marginLeft: "4px", color: "#B0B0B0" }}>
                      {selectedPool?.FirstPoolTimestamp ? new Date(selectedPool.FirstPoolTimestamp).toLocaleString() : ""}
                    </span>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Latest Ratio:</strong> {formatRatio(selectedPool?.LatestPoolRatio)}
                    <span style={{ fontSize: "0.65rem", marginLeft: "4px", color: "#B0B0B0" }}>
                      {selectedPool?.LatestPoolTimestamp ? new Date(selectedPool.LatestPoolTimestamp).toLocaleString() : ""}
                    </span>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Highest Ratio:</strong> {formatRatio(selectedPool?.HighestPoolRatio)}
                    <span style={{ fontSize: "0.65rem", marginLeft: "4px", color: "#B0B0B0" }}>
                      {selectedPool?.HighestPoolTimestamp ? new Date(selectedPool.HighestPoolTimestamp).toLocaleString() : ""}
                    </span>
                  </Typography>
                </Collapse>
              </DetailSection>

              {/* Pool Ratio Gauge Card */}
              <DetailSection elevation={2}>
                <PoolRatioGauge selectedPool={selectedPool} />
              </DetailSection>

              {/* Performance Metrics Card */}
              <DetailSection elevation={2}>
                <Box
                  onClick={() => toggleExpandSection("performance")}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700" }}>
                    Performance Metrics
                  </Typography>
                  {expandedSections.performance ? (
                    <ExpandLessIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#FFD700", fontSize: 16 }} />
                  )}
                </Box>
                <Collapse in={expandedSections.performance}>
                  <Typography variant="caption">
                    <strong>Avg Buy Price:</strong> {selectedPool?.AvgBuyPrice ? formatMoney(selectedPool.AvgBuyPrice) : "N/A"}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Avg Sell Price:</strong> {selectedPool?.AvgSellPrice ? formatMoney(selectedPool.AvgSellPrice) : "N/A"}
                  </Typography>
                  <Typography variant="caption">
                    <strong>Multiplier:</strong> {selectedPool?.Latest_X ? Number(selectedPool.Latest_X).toFixed(2) + "x" : "N/A"}
                  </Typography>
                </Collapse>
              </DetailSection>

              {/* Pool Transaction Stats Card */}
              <DetailSection elevation={2}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#FFD700", mb: 1, textAlign: "center" }}>
                  Pool Transaction Stats
                </Typography>
                <Typography variant="caption">
                  <strong>Total Transactions:</strong> {selectedPool?.TransactionCount || "N/A"}
                </Typography>
                <Typography variant="caption">
                  <strong>Buys:</strong> {selectedPool?.Buys || "N/A"}
                </Typography>
                <Typography variant="caption">
                  <strong>Sells:</strong> {selectedPool?.Sells || "N/A"}
                </Typography>
              </DetailSection>
            </Box>
          </Grid>
        </Grid>
      </DarkBox>
    </ThemeProvider>
  );
};

const queryClient = new QueryClient();

const TradingDashboard = () => (
  <QueryClientProvider client={queryClient}>
    <TradingDashboardInner />
  </QueryClientProvider>
);

export default TradingDashboard;