import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  TableSortLabel
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, BarChart, Bar, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import './holders.css';

// Use the subscription key from env variables
const SUBSCRIPTION_KEY =
  process.env.NEXT_PUBLIC_APP || process.env.REACT_APP_PUBLIC || '';

// Create axios client with an interceptor to attach the subscription key
const apiClient = axios.create({
  headers: { 'X-Verified-By': 'Vercel' },
});
apiClient.interceptors.request.use((config) => {
  config.headers['Ocp-Apim-Subscription-Key'] = SUBSCRIPTION_KEY;
  return config;
});

// Get the holders endpoint from the env (falling back to the proxy URL)
const HOLDER_API_URL =
  process.env.REACT_APP_HOLDER_API_URL

// Default fallback if API doesn't provide decimals
const DEFAULT_DECIMALS = 7;

const COLORS = ['#FFD700', '#32CD32', '#FF8C00', '#d32f2f', '#1976d2', '#9c27b0'];

const formatNumber = (num) =>
  Number(num).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value, percent } = payload[0];
    return (
      <div
        style={{
          backgroundColor: '#333',
          padding: '8px',
          borderRadius: '4px',
          color: '#fff'
        }}
      >
        <p style={{ margin: 0 }}>{name}</p>
        <p style={{ margin: 0 }}>
          {formatNumber(value)} ({(percent * 100).toFixed(0)}%)
        </p>
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};

const TokenHolders = ({ tokenId, onHolderSelect }) => {
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('balance');
  const [order, setOrder] = useState('desc');
  const [lastRefreshed, setLastRefreshed] = useState(null);

  const fetchHolders = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient.get(HOLDER_API_URL, {
        params: { tokenid: tokenId }
      });
      setData(response.data);
      setLastRefreshed(new Date().toLocaleString());
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId) {
      fetchHolders();
    }
  }, [tokenId]);

  // Use decimals from API response or fall back to DEFAULT_DECIMALS
  const decimals = data && data.decimals ? data.decimals : DEFAULT_DECIMALS;

  // Convert each holder balance from smallest unit to token units and filter out zero balances
  const totalSupply = useMemo(() => {
    if (data && data.holders) {
      return data.holders
        .filter((holder) => Number(holder.balance) > 0)
        .reduce(
          (acc, holder) =>
            acc + Number(holder.balance) / Math.pow(10, decimals),
          0
        );
    }
    return 0;
  }, [data, decimals]);

  // Filter out zero balance holders and then filter by search text
  const filteredHolders = useMemo(() => {
    if (!data || !data.holders) return [];
    return data.holders
      .filter((holder) => Number(holder.balance) > 0)
      .filter((holder) =>
        holder.address.toLowerCase().includes(search.toLowerCase())
      );
  }, [data, search]);

  const sortedHolders = useMemo(() => {
    const holdersCopy = [...filteredHolders];
    return holdersCopy.sort((a, b) => {
      const aValue =
        orderBy === 'balance'
          ? Number(a.balance) / Math.pow(10, decimals)
          : a.address.toLowerCase();
      const bValue =
        orderBy === 'balance'
          ? Number(b.balance) / Math.pow(10, decimals)
          : b.address.toLowerCase();
      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredHolders, order, orderBy, decimals]);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Prepare chart data for the top 10 holders
  const chartData = useMemo(() => {
    if (!data || !data.holders) return [];
    const sorted = data.holders
      .filter((holder) => Number(holder.balance) > 0)
      .sort((a, b) => Number(b.balance) - Number(a.balance));
    const topHolders = sorted.slice(0, 10).map((holder) => ({
      name: holder.address.slice(0, 8) + '...',
      value: Number(holder.balance) / Math.pow(10, decimals)
    }));
    const othersTotal = sorted
      .slice(10)
      .reduce(
        (acc, holder) =>
          acc + Number(holder.balance) / Math.pow(10, decimals),
        0
      );
    if (othersTotal > 0) {
      topHolders.push({ name: 'Others', value: othersTotal });
    }
    return topHolders;
  }, [data, decimals]);

  const handleHolderClick = (address) => {
    if (onHolderSelect) {
      onHolderSelect(address);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box mt={4} textAlign="center">
        <Typography variant="h6" color="error">
          Error: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={4} p={2} sx={{ backgroundColor: '#000', color: '#fff', borderRadius: 2 }}>
      <Grid container spacing={3}>
        {/* Header & Search */}
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Token: {data.tokenName}
                  </Typography>
                  <Typography variant="subtitle2">
                    Total Holders: {data.holders ? data.holders.filter(holder => Number(holder.balance) > 0).length : 0}
                  </Typography>
                  <Typography variant="subtitle2">
                    Total Supply: {formatNumber(totalSupply)}
                  </Typography>
                  {lastRefreshed && (
                    <Typography variant="caption" color="grey.400">
                      Last refreshed: {lastRefreshed}
                    </Typography>
                  )}
                </Box>
                <Box mt={{ xs: 2, sm: 0 }}>
                  <TextField
                    label="Search by Address"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{
                      backgroundColor: '#000',
                      '& .MuiInputBase-input': { color: '#fff', fontSize: '0.8rem' },
                      '& .MuiInputLabel-root': { color: '#fff', fontSize: '0.8rem' }
                    }}
                  />
                </Box>
              </Box>
              <Button variant="contained" color="primary" onClick={fetchHolders} sx={{ mt: 1, fontSize: '0.75rem' }}>
                Refresh Data
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Pie Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', textAlign: 'center' }}>
            <CardContent>
              <Box display="flex" justifyContent="center">
                {chartData.length > 0 ? (
                  <PieChart width={250} height={250}>
                    <Pie data={chartData} cx="50%" cy="50%" outerRadius={70} dataKey="value">
                      {chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <RechartsTooltip content={<CustomTooltip />} />
                  </PieChart>
                ) : (
                  <Typography variant="caption">No data for chart.</Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', textAlign: 'center' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Top 10 Holders
              </Typography>
              {chartData.length > 0 ? (
                <BarChart width={300} height={250} data={chartData}>
                  <XAxis dataKey="name" tick={{ fill: "#fff", fontSize: 10 }} />
                  <YAxis tickFormatter={(value) => formatNumber(value)} tick={{ fill: "#fff", fontSize: 10 }} />
                  <RechartsTooltip formatter={(value) => formatNumber(value)} />
                  <Bar dataKey="value" fill="#1976d2" />
                </BarChart>
              ) : (
                <Typography variant="caption">No data for chart.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Holders Table */}
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <CardContent>
              {sortedHolders && sortedHolders.length > 0 ? (
                <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: '#fff' }}>
                          <TableSortLabel
                            active={orderBy === 'address'}
                            direction={orderBy === 'address' ? order : 'asc'}
                            onClick={() => handleSortRequest('address')}
                          >
                            <strong>Address</strong>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ color: '#fff' }} align="right">
                          <TableSortLabel
                            active={orderBy === 'balance'}
                            direction={orderBy === 'balance' ? order : 'asc'}
                            onClick={() => handleSortRequest('balance')}
                          >
                            <strong>Balance</strong>
                          </TableSortLabel>
                        </TableCell>
                        <TableCell sx={{ color: '#fff' }} align="right">
                          <strong>Percentage</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedHolders.map((holder) => {
                        const balance = Number(holder.balance) / Math.pow(10, decimals);
                        const percentage = totalSupply ? ((balance / totalSupply) * 100).toFixed(2) : 0;
                        return (
                          <TableRow
                            key={holder.address}
                            onClick={() => handleHolderClick(holder.address)}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': { backgroundColor: '#222' }
                            }}
                          >
                            <TableCell sx={{ color: '#fff' }}>{holder.address}</TableCell>
                            <TableCell sx={{ color: '#fff' }} align="right">
                              {formatNumber(balance)}
                            </TableCell>
                            <TableCell sx={{ color: '#fff' }} align="right">
                              {formatNumber(percentage)}%
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <Typography variant="body2">No holders found.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Recent Activity Placeholder */}
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                Recent Activity
              </Typography>
              <Typography variant="caption" color="grey.400">
                No recent activity available.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

TokenHolders.propTypes = {
  tokenId: PropTypes.string.isRequired,
  onHolderSelect: PropTypes.func, // Callback to load wallet view with a holder address
};

export default TokenHolders;