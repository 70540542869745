import React, { useState, useEffect } from 'react';

const TokenCatcher = () => {
    const [score, setScore] = useState(0);
    const [isGameActive, setIsGameActive] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);

    useEffect(() => {
        const savedLeaderboard = JSON.parse(
            localStorage.getItem('leaderboard'),
        );
        if (savedLeaderboard) {
            setLeaderboard(savedLeaderboard);
        }
    }, []);

    const startGame = () => {
        setScore(0); // Reset score at the start of the game
        setIsGameActive(true);

        const interval = setInterval(() => {
            const token = document.createElement('div');
            token.classList.add('token');
            token.style.left = Math.random() * 90 + 'vw';
            token.style.top = '-50px';
            token.addEventListener('click', () => {
                setScore((prevScore) => prevScore + 1);
                token.remove();
            });

            document.getElementById('game-area').appendChild(token);

            setTimeout(() => token.remove(), 5000);
        }, 1000);

        // End the game after 30 seconds
        setTimeout(() => {
            clearInterval(interval);
            setIsGameActive(false);
            // Use the latest score value directly inside the callback
            updateLeaderboard(score);
            alert(`Game Over! Your score: ${score} 🏆`);
        }, 30000);
    };

    const updateLeaderboard = (newScore) => {
        const playerName = prompt('Enter your name to save your score:');
        if (!playerName) return;

        const newEntry = { name: playerName, score: newScore };
        const updatedLeaderboard = [...leaderboard, newEntry]
            .sort((a, b) => b.score - a.score)
            .slice(0, 5); // Keep only the top 5 scores

        setLeaderboard(updatedLeaderboard);
        localStorage.setItem('leaderboard', JSON.stringify(updatedLeaderboard));
    };

    return (
        <div className="token-catcher-container">
            <h2 className="token-catcher-header">Under Maintenance 🚧</h2>
            <p className="token-catcher-description">
                We're busy upgrading the system to bring you an even better
                experience! While we tinker with the code, why not test your
                skills in this game?
            </p>
            <div id="game-area" className="token-catcher-game-area"></div>
            <p className="token-catcher-score">Score: {score}</p>
            <button
                className="start-game-btn"
                onClick={startGame}
                disabled={isGameActive}
            >
                {isGameActive ? 'Game in Progress...' : 'Start Game'}
            </button>
            <div className="leaderboard">
                <h3>Leaderboard 🏆</h3>
                <ul>
                    {leaderboard.map((entry, index) => (
                        <li key={index}>
                            {index + 1}. {entry.name} - {entry.score} points
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TokenCatcher;
