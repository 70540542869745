import React, { useState } from 'react';
import { Container, CircularProgress, Alert, Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';

const ATTOALPH_TO_ALPH = 1e18;

// Function to format the balance from attoAlph to Alph
const formatBalance = (balanceInAttoAlph) => {
  if (balanceInAttoAlph === 'Error') {
    return balanceInAttoAlph;
  }
  // Convert from attoAlph (smallest unit) to Alph
  const balanceInAlph = (
    parseInt(balanceInAttoAlph) / ATTOALPH_TO_ALPH
  ).toFixed(18); // Limiting to 18 decimal places
  return balanceInAlph;
};

const TokenForm = ({ onSubmit }) => {
  const [tokenID, setTokenID] = useState('');
  const [limit, setLimit] = useState(100);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!tokenID) {
      alert('Please enter a Token ID.');
      return;
    }
    onSubmit(tokenID, limit);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
    
      <TextField
        label="Token ID"
        variant="outlined"
        fullWidth
        required
        value={tokenID}
        onChange={(e) => setTokenID(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Limit"
        variant="outlined"
        type="number"
        fullWidth
        value={limit}
        onChange={(e) => setLimit(e.target.value)}
        sx={{ mb: 2 }}
        InputProps={{ inputProps: { min: 1 } }}
      />
      <Button variant="contained" color="primary" type="submit">
        Get Asset History
      </Button>
    </Box>
  );
};

const BalanceTable = ({ balances }) => (
  <TableContainer sx={{ mt: 4 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Address</TableCell>
          <TableCell>Balance (Alph)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {balances.map(({ address, balance }, index) => (
          <TableRow key={index}>
            <TableCell>{address}</TableCell>
            <TableCell>{balance}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const Odyssey = () => {
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Replace this with your actual Azure Function URL
  const AZURE_FUNCTION_URL = 'https://dohcloud.doh.money/api/v2_odyssey/{tokenid}';

  const handleFetchBalances = async (tokenID, limit) => {
    setLoading(true);
    setError(null);
    setBalances([]);

    try {
      const response = await axios.get(AZURE_FUNCTION_URL, {
        params: {
          tokenID,
          limit,
        },
      });

      if (Array.isArray(response.data)) {
        setBalances(response.data);
      } else {
        setError('Unexpected response format.');
      }
    } catch (err) {
      console.error('Error fetching balances:', err);
      setError(err.response?.data || 'An error occurred while fetching balances.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Asset Wallet History
      </Typography>
      <Typography variant="h6" paragraph align="center">
        This tool tracks the journey of your assets, such as NFTs, by entering a Token ID. You can explore transactions, 
        ownership transfers, and other relevant data associated with your asset.
      </Typography>
      <TokenForm onSubmit={handleFetchBalances} />
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 4 }}>
          {error}
        </Alert>
      )}
      {!loading && !error && balances.length > 0 && (
        <BalanceTable balances={balances} />
      )}
    </Container>
  );
};

export default Odyssey;