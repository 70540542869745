import React, { useState, useEffect, useRef } from 'react';

const TOKEN_OPTIONS = [
  {
    label: "GRUMPY",
    tokenId: "ec3f74643fcf9439d267c48e5b4a1c793cc0ec9619fa7528a2644b5af949c500",
    poolId: "18dc674c3a9453e934c569d3112eb0d6d7e782373cef5d13c70a0714dce14f00"
  },
  {
    label: "DOH",
    tokenId: "93db3ced5685c6e5438afcc94fc20a1bca8885be75c02f6f164da30f217c2500",
    poolId: "19e2692626fa55ba80dc3568b46c49347fe7fe482a6ec86118995c99365d8400"
  }
];

async function initiateWebSocketConnection(tokenId, poolId, onMessageCallback) {
  const SUBSCRIPTION_KEY = "";
  // Pass tokenId and poolId as query parameters
  const GENERATE_URL_ENDPOINT = `https://api.doh.money/api/v2_generatewebsocketURL?tokenId=${tokenId}&poolId=${poolId}`;

  console.log("[PushAPI] Fetching WebSocket URL...");
  try {
    const response = await fetch(GENERATE_URL_ENDPOINT, {
      headers: { "Ocp-Apim-Subscription-Key": SUBSCRIPTION_KEY }
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch WebSocket URL: ${response.statusText}`);
    }
    const data = await response.json();
    console.log("[PushAPI] Received WS URL response:", data);

    const clientUrl = data.clientUrl;
    if (!clientUrl) {
      throw new Error("No clientUrl in response.");
    }
    const wsUrl = clientUrl.startsWith("wss://")
      ? clientUrl
      : clientUrl.replace(/^https:\/\//i, 'wss://');
    console.log("[PushAPI] Using WebSocket URL:", wsUrl);

    const ws = new WebSocket(wsUrl);

    ws.onopen = async () => {
      console.log("✅ Connected to Push API WebSocket.");

      // Start heartbeat to keep the connection alive (every 5 seconds)
      const heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ action: "ping" }));
          console.log("[Heartbeat] Ping sent.");
        }
      }, 5000);
      ws._heartbeatInterval = heartbeatInterval;

      // Immediately send join message
      const group = `pool_${poolId}_token_${tokenId}`;
      const joinMessage = JSON.stringify({ action: "join", group: group });
      ws.send(joinMessage);
      console.log("[PushAPI] Sent join message:", joinMessage);

      // Immediately call the pushSnapshot API to trigger data push
      try {
        const snapshotResponse = await fetch(
          `https://api.doh.money/api/v2_pushsnapshot?tokenId=${tokenId}&poolId=${poolId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY
            }
          }
        );
        const snapshotData = await snapshotResponse.text();
        console.log("[PushAPI] Snapshot data received from backend:", snapshotData);
      } catch (error) {
        console.error("[PushAPI] Error calling pushSnapshot:", error);
      }
    };

    ws.onmessage = (event) => {
      console.log("📩 Raw message received:", event.data);
      try {
        const parsed = JSON.parse(event.data);
        console.log("[PushAPI] Parsed message:", parsed);
        if (onMessageCallback) {
          onMessageCallback(parsed, event.data);
        }
      } catch (error) {
        console.error("[PushAPI] Error parsing message:", error);
        if (onMessageCallback) {
          onMessageCallback(null, event.data);
        }
      }
    };

    ws.onerror = (err) => {
      console.error("❌ WebSocket error:", err.message);
    };

    ws.onclose = (event) => {
      if (ws._heartbeatInterval) clearInterval(ws._heartbeatInterval);
      console.log(`🔴 WebSocket closed. Code: ${event.code}, Reason: ${event.reason}`);
      if (event.reason) {
        alert(`WS closed: ${event.reason}`);
      }
      console.log("WebSocket closed unexpectedly, checking reason and code...");
    };

    return ws;
  } catch (error) {
    console.error("Error in initiateWebSocketConnection:", error);
    throw error;
  }
}

function TestWebsocketConnection() {
  const [status, setStatus] = useState("Not Connected");
  const [messages, setMessages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(TOKEN_OPTIONS[0]);
  const wsRef = useRef(null);

  const connectWebSocket = async (option) => {
    try {
      const ws = await initiateWebSocketConnection(
        option.tokenId,
        option.poolId,
        (parsed, raw) => {
          setMessages(prev => [...prev, parsed || { raw }]);
        }
      );
      wsRef.current = ws;
      setStatus("Connected");
    } catch (error) {
      setStatus("Connection failed");
      console.error(error);
    }
  };

  const disconnectWebSocket = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.close();
    }
    setStatus("Not Connected");
    wsRef.current = null;
  };

  useEffect(() => {
    return () => {
      disconnectWebSocket();
    };
  }, []);

  const handleOptionChange = (e) => {
    const selected = TOKEN_OPTIONS.find(opt => opt.tokenId === e.target.value);
    setSelectedOption(selected);
  };

  const handleConnectClick = () => {
    disconnectWebSocket();
    setMessages([]);
    connectWebSocket(selectedOption);
  };

  return (
    <div>
      <h2>Push API WebSocket Connection Status: {status}</h2>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Select Token:&nbsp;
          <select value={selectedOption.tokenId} onChange={handleOptionChange}>
            {TOKEN_OPTIONS.map(opt => (
              <option key={opt.tokenId} value={opt.tokenId}>
                {opt.label}
              </option>
            ))}
          </select>
        </label>
        <button onClick={handleConnectClick} style={{ marginLeft: "10px" }}>
          Connect
        </button>
      </div>
      <div style={{
        border: "1px solid #ccc",
        padding: "10px",
        maxHeight: "300px",
        overflowY: "scroll",
        marginTop: "10px"
      }}>
        {messages.length === 0 ? (
          <p>No messages yet.</p>
        ) : (
          messages.map((msg, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <pre>{JSON.stringify(msg, null, 2)}</pre>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default TestWebsocketConnection;