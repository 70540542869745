import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
  Drawer,
} from '@mui/material';
import { Menu } from 'lucide-react';
import { useWallet } from '@alephium/web3-react';
import { useWalletModal } from './walletmodalcontext';
import useWalletConnection from './usewalletconnection';
import dohLogo from './assets/logos/DOH_Official.png';
import dohBetaLogo from './assets/logos/dohbeta.png';

const Navbar = ({ onSearch }) => {
  const theme = useTheme();

  // State for mobile drawer
  const [mobileOpen, setMobileOpen] = useState(false);
  const toggleDrawer = () => setMobileOpen(!mobileOpen);

  // Wallet hooks
  const { handleOpenModal } = useWalletModal();
  const walletContext = useWallet();
  const { connectionStatus, account } = walletContext || {};
  const connected = connectionStatus === 'connected';
  const walletAddress = account?.address || '';

  const { handleDisconnect } = useWalletConnection();
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [ansName, setAnsName] = useState('');

  useEffect(() => {
    if (connected && walletAddress) {
      // Fetch and set the ANS profile (code omitted for brevity)
      // ...
    }
  }, [connected, walletAddress]);

  const truncateAddress = (address) =>
    address ? `${address.substring(0, 6)}...${address.substring(address.length - 4)}` : '';

  const onDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      await handleDisconnect();
      walletContext.connectionStatus = 'disconnected';
      setIsDisconnecting(false);
    } catch (error) {
      console.error('Disconnection error:', error);
      setIsDisconnecting(false);
    }
  };

  // Desktop Navigation: Logo, Navigation Shortcut, and Wallet Section
  const desktopNav = (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#0D0D0D',
        boxShadow: theme.shadows[4],
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left Section: Logo & Navigation Shortcuts */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/home" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
            <Box component="img" src={dohLogo} alt="DOH Logo" sx={{ height: 40, mr: 1 }} />
            <Box component="img" src={dohBetaLogo} alt="DOH Beta Logo" sx={{ height: 30 }} />
          </Link>

        </Box>

        {/* Wallet Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {!connected ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenModal}
              size="small"
              sx={{ textTransform: 'none', fontSize: '0.75rem', px: 1, py: 0.5 }}
            >
              Connect Wallet
            </Button>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body2" sx={{ color: '#fff', fontSize: '0.75rem' }}>
                {ansName || truncateAddress(walletAddress)}
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                onClick={onDisconnect}
                disabled={isDisconnecting}
                size="small"
                sx={{ textTransform: 'none', fontSize: '0.75rem', px: 1, py: 0.5 }}
              >
                {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
              </Button>
            </Box>
          )}
          {/* Mobile Menu Toggle */}
          <IconButton
            sx={{ display: { xs: 'flex', md: 'none' } }}
            onClick={toggleDrawer}
          >
            <Menu size={24} color="#fff" />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );

  // Mobile Drawer (includes the navigation shortcut)
  const mobileDrawer = (
    <Box
      onClick={toggleDrawer}
      sx={{ width: 250, bgcolor: '#0D0D0D', color: '#fff', height: '100%' }}
    >
      <Box sx={{ p: 2 }}>
        {!connected ? (
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleOpenModal}
            size="small"
            sx={{ textTransform: 'none', fontSize: '0.75rem', px: 1, py: 0.5 }}
          >
            Connect Wallet
          </Button>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="body2" sx={{ textAlign: 'center', color: '#fff', fontSize: '0.75rem' }}>
              {ansName || truncateAddress(walletAddress)}
            </Typography>
            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              onClick={onDisconnect}
              disabled={isDisconnecting}
              size="small"
              sx={{ textTransform: 'none', fontSize: '0.75rem', px: 1, py: 0.5 }}
            >
              {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
            </Button>
          </Box>
        )}
        {/* Mobile Navigation Shortcut */}
        <Box sx={{ mt: 2 }}>
          <Link to="/game" style={{ textDecoration: 'none' }}>
            <Button variant="text" color="inherit" fullWidth sx={{ textTransform: 'none' }}>
              Play Game
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {desktopNav}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', bgcolor: '#141414', color: '#fff' },
        }}
      >
        {mobileDrawer}
      </Drawer>
    </>
  );
};

export default Navbar;