import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse'; // Import PapaParse
import BigNumber from 'bignumber.js';
import './fetch2.css';
import { getDefaultAlephiumWallet } from '@alephium/get-extension-wallet';

// Import Donut Logos
import legendaryDohnut from '../assets/logos/legendaryDohnut.jpg';
import epicDohnut from '../assets/logos/epicDohnut.jpg';
import rareDohnut from '../assets/logos/rareDohnut.jpg';
import commonDohnut from '../assets/logos/commonDohnut.jpg';

// Import React Icons
import { FaExchangeAlt, FaWallet } from 'react-icons/fa';

// Spinner Component
const Spinner = () => (
    <div className="spinner" aria-label="Loading Spinner">
        <div className="double-bounce1"></div>
        <div className="double-bounce2"></div>
    </div>
);

// Confirmation Modal Component
const ConfirmationModal = ({ isOpen, onClose, onConfirm, airdropDetails }) => {
    if (!isOpen || !airdropDetails) return null;

    return (
        <div className="confirmation-modal">
            <div className="confirmation-content">
                <h2>Confirm Airdrop</h2>
                <p>
                    Please review the airdrop details below before confirming:
                </p>
                <div className="airdrop-summary">
                    <h4>Airdrop Summary</h4>
                    <p>
                        <strong>Total Airdrop Amount:</strong>{' '}
                        {airdropDetails.totalAirdrop} EX
                    </p>
                    <p>
                        <strong>Total Wallets:</strong>{' '}
                        {airdropDetails.totalWallets}
                    </p>
                    {airdropDetails.stages.length === 1 &&
                    airdropDetails.stages[0].stage === 'CSV Uploaded' ? (
                        <p>
                            <em>Airdrop data sourced from uploaded CSV.</em>
                        </p>
                    ) : (
                        <p>
                            <em>
                                Airdrop data sourced from backend processing.
                            </em>
                        </p>
                    )}
                </div>
                <div className="airdrop-details">
                    <h4>Airdrop Details by Stage</h4>
                    {airdropDetails.stages.map((stage, idx) => (
                        <div key={idx} className="stage-detail">
                            <h5>
                                {stage.stage} - Total: {stage.stageTotal} EX
                            </h5>
                            <ul>
                                {stage.wallets.map((wallet, wIdx) => (
                                    <li key={wIdx}>
                                        <a
                                            href={`https://explorer.alephium.org/addresses/${wallet.address}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {wallet.address}
                                        </a>
                                        : {wallet.amount} EX
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="modal-actions">
                    <button onClick={onConfirm} className="confirm-button">
                        Confirm Airdrop
                    </button>
                    <button onClick={onClose} className="cancel-button">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default function NFTOwners() {
    // State Hooks
    const [wallet, setWallet] = useState(null); // Wallet instance
    const [account, setAccount] = useState(null); // Connected account
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null); // Holds the response data from the backend
    const [error, setError] = useState(null);
    const [excludeNFTIndexes, setExcludeNFTIndexes] = useState('');
    const [showProcessingPopup, setShowProcessingPopup] = useState(false);
    const [quoteData, setQuoteData] = useState({
        quote: '',
        character: '',
        image: '',
    });
    const [progress, setProgress] = useState(0); // Progress percentage
    const [currentStep, setCurrentStep] = useState(''); // Current processing step
    const [airdropLoading, setAirdropLoading] = useState(false); // Airdrop loading state
    const [airdropResult, setAirdropResult] = useState(null); // Airdrop result state

    // New State Variables for Totals
    const [totalAirdropAmount, setTotalAirdropAmount] = useState(
        new BigNumber(0),
    ); // Total Airdrop Amount
    const [totalWallets, setTotalWallets] = useState(0); // Total Number of Wallets

    // State for Past EPOCH Rewards
    const [pastEpochs, setPastEpochs] = useState([
        { epoch: 'Epoch 1', reward: '150 EX' },
        { epoch: 'Epoch 2', reward: '127 EX' },
        { epoch: 'Epoch 3', reward: '77 EX' },
        { epoch: 'Epoch 4', reward: '47 EX' },
        { epoch: 'Epoch 5', reward: '50 EX' },
        { epoch: 'Epoch 6', reward: '40 EX' },
        { epoch: 'Epoch 7', reward: '45 EX' },
        { epoch: 'Epoch 8', reward: '55 EX' },
    ]);

    // State for Collapsible Explanation
    const [showExplanation, setShowExplanation] = useState(false);

    // New State Variables for CSV Upload
    const [csvData, setCsvData] = useState([]); // Array of { address: string, amount: string }

    // State for Confirmation Modal
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [preparedAirdropDetails, setPreparedAirdropDetails] = useState(null);

    const BACKEND_API_URL =
        'https://dohcloud.azurewebsites.net/api_doh_dohnuts'; // Replace with your actual URL

    // Constants for rarity percentages
    const RARITY_PERCENTAGES = {
        Legendary: 0.025,
        Epic: 0.015,
        Rare: 0.01,
        Common: 0.007,
    };

    const DECIMALS = 18; // Adjust this if EX uses a different number of decimals
    const exContractId =
        'cad22f7c98f13fe249c25199c61190a9fb4341f8af9b1c17fcff4cd4b2c3d200';

    // Wallet Initialization
    useEffect(() => {
        const setupWallet = async () => {
            try {
                const walletInstance = await getDefaultAlephiumWallet();
                if (walletInstance) {
                    await walletInstance.enable({
                        networkId: 'mainnet', // or 'testnet'
                        addressGroup: 0,
                        onDisconnected: () => {
                            console.log('Wallet disconnected');
                            setWallet(null);
                            setAccount(null);
                        },
                    });
                    setWallet(walletInstance);
                    const accountInfo =
                        await walletInstance.getSelectedAccount();
                    setAccount(accountInfo);
                    console.log('Wallet connected:', accountInfo);
                } else {
                    console.error('No wallet found');
                }
            } catch (error) {
                console.error('Error initializing wallet:', error);
            }
        };

        setupWallet();
    }, []);

    // Helper function to format BigNumber values for display
    const formatBigNumber = (
        number,
        decimals = DECIMALS,
        decimalPlaces = 4,
    ) => {
        try {
            const bn = new BigNumber(number);
            if (!bn.isFinite()) return '0.0000';
            return bn
                .dividedBy(new BigNumber(10).pow(decimals))
                .toFixed(decimalPlaces);
        } catch (error) {
            console.error(
                'formatBigNumber: Invalid number input:',
                number,
                error,
            );
            return '0.0000';
        }
    };

    // Helper function to scale EX to smallest unit (e.g., attoEX)
    // Retained in case it's needed elsewhere, but not used in airdrop
    const scaleToSmallestUnit = (value, decimals = 18) => {
        try {
            const bn = new BigNumber(value);
            if (bn.isNaN() || !bn.isFinite()) {
                throw new Error('Invalid value for scaling');
            }
            return bn.multipliedBy(new BigNumber(10).pow(decimals)).toFixed(0); // Return as string without decimals
        } catch (error) {
            console.error(
                'scaleToSmallestUnit: Error scaling value:',
                value,
                error,
            );
            return '0'; // Fallback to zero on error
        }
    };

    // Helper function to shorten wallet addresses (e.g., abcd...wxyz)
    const shortenAddress = (address) => {
        if (!address || address.length < 10) return address;
        return `${address.substring(0, 4)}...${address.substring(
            address.length - 4,
        )}`;
    };

    // Handle Exclude NFT Indexes Input
    const handleExcludeNFTIndexesChange = (e) => {
        setExcludeNFTIndexes(e.target.value);
    };

    // Function to fetch a random Simpsons quote
    const fetchSimpsonsQuote = async () => {
        try {
            const response = await axios.get(
                'https://thesimpsonsquoteapi.glitch.me/quotes',
            );
            if (Array.isArray(response.data) && response.data.length > 0) {
                const { quote, character, image } = response.data[0];
                setQuoteData({ quote, character, image });
                console.log(`Fetched Quote: "${quote}" by ${character}`);
            } else {
                setQuoteData({
                    quote: "D'oh! Something went wrong.",
                    character: 'Homer Simpson',
                    image: '',
                });
                console.warn('No quote data received from API.');
            }
        } catch (error) {
            console.error('Error fetching Simpsons quote:', error);
            setQuoteData({
                quote: "D'oh! Something went wrong.",
                character: 'Homer Simpson',
                image: '',
            });
        }
    };

    // Function to fetch a new Simpsons quote every 5 seconds while processing
    useEffect(() => {
        let interval = null;
        if (showProcessingPopup) {
            interval = setInterval(() => {
                fetchSimpsonsQuote();
            }, 5000); // Fetch new quote every 5 seconds
        }
        return () => clearInterval(interval);
    }, [showProcessingPopup]);

    // Helper function to determine if an address is a contract address
    const isContractAddress = (address) => {
        // Example logic: contract addresses start with 'zFW'
        // Modify this logic based on Alephium's address specifications
        return address.startsWith('zFW');
    };

    // Helper function to validate addresses (Customize based on Alephium's address format)
    const isValidAddress = (address) => {
        // Example validation: addresses start with 'ak' and have 42 characters
        return /^ak[a-zA-Z0-9]{40}$/.test(address);
    };

    // Handle CSV Upload
    const handleCSVUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const parsedData = results.data
                    .map((row, index) => {
                        // Assuming CSV has headers: "address" and "amount"
                        const address = row.address?.trim();
                        const amount = row.amount?.trim();

                        // Basic validation
                        if (!address || !amount) {
                            console.warn(
                                `Row ${
                                    index + 2
                                } is missing address or amount.`,
                            );
                            return null;
                        }

                        if (!isValidAddress(address)) {
                            // Implement isValidAddress based on your criteria
                            console.warn(
                                `Row ${
                                    index + 2
                                } has an invalid address: ${address}`,
                            );
                            return null;
                        }

                        if (isNaN(amount) || Number(amount) <= 0) {
                            console.warn(
                                `Row ${
                                    index + 2
                                } has an invalid amount: ${amount}`,
                            );
                            return null;
                        }

                        return { address, amount };
                    })
                    .filter(Boolean); // Remove invalid rows

                if (parsedData.length === 0) {
                    alert('No valid data found in the CSV.');
                    setCsvData([]);
                    return;
                }

                setCsvData(parsedData);
                alert(
                    `Successfully parsed ${parsedData.length} entries from the CSV.`,
                );
            },
            error: (error) => {
                console.error('Error parsing CSV:', error);
                alert(
                    'Failed to parse CSV file. Please check the file format.',
                );
            },
        });
    };

    // Function to handle the processing of NFTs by calling the backend
    const handleProcess = async () => {
        setLoading(true);
        setError(null);
        setData(null);
        setShowProcessingPopup(true); // Show processing popup
        setProgress(0); // Reset progress
        setCurrentStep('Initializing'); // Set initial step
        await fetchSimpsonsQuote(); // Fetch the initial quote

        try {
            // Prepare the request payload
            const payload = {
                excludeNFTIndexes: excludeNFTIndexes.trim(),
            };

            // Update progress and current step
            setProgress(10);
            setCurrentStep('Fetching Reward Pool Balance');

            // Make a POST request to the backend API
            const response = await axios.post(BACKEND_API_URL, payload);

            if (response.status === 200 && response.data.success) {
                console.log('NFTOwners: Fetched data:', response.data.data); // Log fetched data

                // Convert rewards to BigNumber instances for consistency
                const processedData = {
                    ...response.data.data,
                    walletTotals: Object.entries(
                        response.data.data.walletTotals,
                    ).reduce((acc, [address, walletData]) => {
                        acc[address] = {
                            ...walletData,
                            rewards: new BigNumber(walletData.rewards), // Convert to BigNumber
                        };
                        // Debug log for raw rewards before conversion
                        console.log(
                            `Wallet ${address} rewards raw:`,
                            walletData.rewards,
                        );
                        return acc;
                    }, {}),
                };

                // Log processed data to verify conversion
                console.log('Processed Data:', processedData);

                setData(processedData);
                setProgress(100);
                setCurrentStep('Completed');
            } else {
                throw new Error(
                    response.data.error || 'Unknown error occurred.',
                );
            }
        } catch (err) {
            console.error('NFTOwners: Error processing NFTs:', err);
            setError(
                err.message || 'Failed to process data. Please try again.',
            );
            setProgress(100);
            setCurrentStep('Error');
        } finally {
            setLoading(false);
            setShowProcessingPopup(false); // Hide processing popup
            // setProgress(100); // Already set above
        }
    };

    // Helper function to generate CSV content for a given rarity (modified to address,amount)
    const generateCSVContent = () => {
        let csvContent = 'address,amount\n';

        if (csvData.length > 0) {
            // Use CSV uploaded data
            csvData.forEach((entry) => {
                csvContent += `${entry.address},${entry.amount}\n`;
            });
        } else if (data?.walletTotals) {
            // Use backend data
            Object.entries(data.walletTotals).forEach(
                ([address, walletData]) => {
                    const totalReward = walletData.nftRewards.reduce(
                        (acc, nft) => acc.plus(nft.reward || 0),
                        new BigNumber(0),
                    );
                    csvContent += `${address},${formatBigNumber(
                        totalReward,
                    )}\n`;
                },
            );
        }

        return csvContent;
    };

    // Function to download airdrop CSV in address,amount format
    const downloadAirdropCSV = () => {
        const csvContent = generateCSVContent();
        if (!csvContent) {
            alert('No airdrop data available to generate CSV.');
            return;
        }

        // Create a Blob from the CSV string
        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;',
        });

        // Create a temporary link to trigger the download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute(
            'download',
            `Airdrop_${new Date().toISOString().slice(0, 10)}.csv`,
        );
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // New downloadCSV function to generate CSV for airdrop entries
    // Optional: Remove if not needed
    /*
  const downloadCSV = (rarity) => {
    // Existing functionality for per-rarity CSV downloads
    if (!data?.walletTotals || !data.rarityCounts) {
      alert('No data available to generate CSV.');
      return;
    }

    // Filter wallets that have NFTs of the specified rarity
    const walletsWithRarity = Object.entries(data.walletTotals).filter(
      ([_, walletData]) => walletData.rarityCounts[rarity] > 0
    );

    if (walletsWithRarity.length === 0) {
      alert(`No wallets found with ${rarity} rarity NFTs.`);
      return '';
    }

    // CSV Header
    let csvContent = 'address,amount\n';

    // Populate CSV rows
    walletsWithRarity.forEach(([wallet, walletData]) => {
      const rarityRewards = walletData.nftRewards
        .filter((nft) => nft.rarity === rarity)
        .reduce((acc, nft) => acc.plus(new BigNumber(nft.reward || 0)), new BigNumber(0));

      const formattedReward = formatBigNumber(rarityRewards);

      csvContent += `${wallet},${formattedReward}\n`;
    });

    // Create a Blob from the CSV string
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary link to trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${rarity}_Rewards.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  */

    // New State Variables for Confirmation Modal
    // (Already included above)

    // Function to prepare airdrop details from backend data
    const prepareAirdropDetails = () => {
        if (!data?.walletTotals) {
            alert('No wallet data available for airdrop.');
            return null;
        }

        // Define the airdrop stages in order
        const airdropStages = ['Legendary', 'Epic', 'Rare', 'Common'];

        // Initialize total airdrop amount and total wallets
        let totalAirdrop = new BigNumber(0);
        let totalWalletCount = 0;

        // Collect detailed airdrop information
        const stagesDetails = airdropStages
            .map((stage) => {
                const walletsInStage = Object.entries(data.walletTotals).filter(
                    ([_, walletData]) => walletData.rarityCounts[stage] > 0,
                );

                if (walletsInStage.length === 0) return null; // Skip if no wallets in this stage

                const stageTotal = walletsInStage.reduce(
                    (acc, [_, walletData]) => {
                        const stageReward = walletData.nftRewards
                            .filter((nft) => nft.rarity === stage)
                            .reduce(
                                (acc, nft) =>
                                    acc.plus(new BigNumber(nft.reward || 0)),
                                new BigNumber(0),
                            );
                        return acc.plus(stageReward);
                    },
                    new BigNumber(0),
                );

                totalAirdrop = totalAirdrop.plus(stageTotal);
                totalWalletCount += walletsInStage.length;

                const wallets = walletsInStage.map(([address, walletData]) => ({
                    address,
                    amount: formatBigNumber(
                        walletData.nftRewards
                            .filter((nft) => nft.rarity === stage)
                            .reduce(
                                (acc, nft) =>
                                    acc.plus(new BigNumber(nft.reward || 0)),
                                new BigNumber(0),
                            ),
                    ),
                }));

                return {
                    stage,
                    wallets,
                    stageTotal: formatBigNumber(stageTotal),
                };
            })
            .filter(Boolean); // Remove null entries

        return {
            totalAirdrop: formatBigNumber(totalAirdrop),
            totalWallets: totalWalletCount,
            stages: stagesDetails,
        };
    };

    // Function to prepare airdrop details from CSV data
    const prepareAirdropDetailsFromCSV = (csvEntries) => {
        // Calculate total airdrop amount and total wallets
        let totalAirdrop = new BigNumber(0);
        const wallets = csvEntries.map((entry) => {
            const reward = new BigNumber(entry.amount);
            totalAirdrop = totalAirdrop.plus(reward);
            return {
                address: entry.address,
                amount: entry.amount,
            };
        });

        return {
            totalAirdrop: formatBigNumber(totalAirdrop),
            totalWallets: wallets.length,
            stages: [
                {
                    stage: 'CSV Uploaded',
                    wallets,
                    stageTotal: formatBigNumber(totalAirdrop),
                },
            ],
        };
    };

    // Function to handle airdrop initiation (preparation step)
    const handleAirdropInitiation = () => {
        let airdropDetails;

        if (csvData.length > 0) {
            airdropDetails = prepareAirdropDetailsFromCSV(csvData);
        } else if (data?.walletTotals) {
            airdropDetails = prepareAirdropDetails();
        } else {
            alert(
                'No airdrop data available. Please process the collection or upload a CSV.',
            );
            return;
        }

        if (airdropDetails) {
            setPreparedAirdropDetails(airdropDetails);
            setShowConfirmationModal(true);
        }
    };

    // Function to execute the airdrop (confirmation step)
    const executeAirdrop = async () => {
        setShowConfirmationModal(false); // Close the confirmation modal
        setAirdropLoading(true);
        setAirdropResult(null);
        setShowProcessingPopup(true); // Show processing popup
        setProgress(0); // Reset progress
        setCurrentStep('Preparing Airdrop');
        await fetchSimpsonsQuote(); // Fetch initial quote

        try {
            if (!wallet) {
                alert('Wallet is not initialized. Please connect your wallet.');
                throw new Error('Wallet not initialized');
            }
            if (!account) {
                alert('No account connected.');
                throw new Error('No account connected');
            }

            let airdropEntries = [];

            if (csvData.length > 0) {
                // Use CSV data
                airdropEntries = csvData.map((entry) => ({
                    address: entry.address,
                    amount: new BigNumber(entry.amount),
                }));
            } else if (data?.walletTotals) {
                // Use backend data
                airdropEntries = Object.entries(data.walletTotals).flatMap(
                    ([address, walletData]) =>
                        walletData.nftRewards.map((nft) => ({
                            address,
                            amount: new BigNumber(nft.reward || 0),
                        })),
                );
            }

            if (airdropEntries.length === 0) {
                throw new Error('No airdrop entries available.');
            }

            // Filter out contract addresses and invalid rewards
            const validEntries = airdropEntries.filter((entry) => {
                const isContract = isContractAddress(entry.address);
                if (isContract) {
                    console.warn(
                        `Excluding contract address from airdrop: ${entry.address}`,
                    );
                    return false;
                }
                if (!entry.amount.isFinite() || entry.amount.lte(0)) {
                    console.warn(
                        `Invalid reward for ${entry.address}: ${entry.amount}`,
                    );
                    return false;
                }
                return true;
            });

            // Inform the user if any addresses were excluded
            if (validEntries.length !== airdropEntries.length) {
                alert(
                    `Some addresses were excluded from the airdrop due to invalid data or being contract addresses.`,
                );
            }

            if (validEntries.length === 0) {
                throw new Error('No valid airdrop entries to process.');
            }

            // Prepare transactions
            const transactions = validEntries.map((entry) => ({
                address: entry.address,
                attoAlphAmount: '1', // Dust amount
                tokens: [
                    {
                        id: exContractId,
                        amount: entry.amount.toFixed(0), // Ensure it's a string without decimals
                    },
                ],
            }));

            // Submit the transactions
            const result = await wallet.signAndSubmitTransferTx({
                signerAddress: account.address,
                destinations: transactions,
            });

            if (result.success) {
                console.log(
                    `Airdrop successful! Transaction hash: ${result.txHash}`,
                );
                setAirdropResult({ success: true, txHash: result.txHash });
                alert(`Airdrop successful! Transaction hash: ${result.txHash}`);
            } else {
                throw new Error(result.error || `Airdrop transaction failed.`);
            }
        } catch (error) {
            console.error('Airdrop failed:', error);
            setAirdropResult({ success: false, error: error.message });
            alert(`Airdrop failed: ${error.message}`);
        } finally {
            setAirdropLoading(false);
            setShowProcessingPopup(false); // Hide processing popup
        }
    };

    return (
        <div className="nft-owners-container">
            {/* DOHnuts Collapsible Explanation Section */}
            <div className="dohnuts-explanation compact">
                <h1
                    className="explanation-title"
                    onClick={() => setShowExplanation(!showExplanation)}
                >
                    What Are DOHnuts?{' '}
                    <span className="toggle-icon">
                        {showExplanation ? '▲' : '▼'}
                    </span>
                </h1>
                {showExplanation && (
                    <div className="explanation-content">
                        <p className="explanation-text">
                            DOHnuts are exclusive NFTs that reward holders with
                            weekly payouts in{' '}
                            <strong>$EX (Elexium token)</strong>.
                        </p>
                        <p className="explanation-text">
                            <strong>How It Works:</strong> Each week,{' '}
                            <strong>50%</strong> of the $DOH project wallet’s LP
                            staking rewards are distributed to NFT holders.
                            Proceeds from DOH sales (including royalties) are
                            reinvested into the DOHnut EX Lock, compounding
                            weekly to boost rewards.
                        </p>
                        <div className="rewards-summary">
                            <h4 className="rewards-title">Rarity Rewards</h4>
                            <ul className="rewards-list">
                                <li>
                                    <strong>Legendary:</strong> 2.5% per NFT
                                </li>
                                <li>
                                    <strong>Epic:</strong> 1.5% per NFT
                                </li>
                                <li>
                                    <strong>Rare:</strong> 1% per NFT
                                </li>
                                <li>
                                    <strong>Common:</strong> 0.7% per NFT
                                </li>
                            </ul>
                        </div>
                        <p className="explanation-text">
                            ⚠️ <strong>Note:</strong> Rewards shown during the
                            epoch are estimates. Final rewards will be
                            calculated and airdropped after the epoch concludes.
                            Rewards paid via wallet
                            18J6KUb768iVu6Y7dzFqPRRkum24aTobHjmTe6ztqb3eN
                        </p>
                    </div>
                )}
            </div>

            {/* Main Title */}
            <h2 className="title">DOHnuts Reward Platform</h2>

            {/* Past EPOCH Rewards Section */}
            <div className="past-epochs">
                <h3>Past EPOCH Rewards</h3>
                <ul>
                    {pastEpochs.map((epochData, idx) => (
                        <li key={idx}>
                            <strong>{epochData.epoch}:</strong>{' '}
                            {epochData.reward}
                        </li>
                    ))}
                </ul>
                {/* The "Add Epoch" form has been removed */}
            </div>

            {/* Exclude NFT Indexes Input */}
            <div className="exclude-section">
                <label htmlFor="excludeNFTIndexes" className="label">
                    Exclude NFT Indexes:
                </label>
                <input
                    id="excludeNFTIndexes"
                    type="text"
                    value={excludeNFTIndexes}
                    onChange={handleExcludeNFTIndexesChange}
                    placeholder="Enter NFT indexes to exclude, separated by commas"
                    className="input"
                />
            </div>

            {/* CSV Upload Section */}
            <div className="csv-upload-section">
                <label htmlFor="csvFile" className="label">
                    Upload Airdrop CSV:
                </label>
                <input
                    id="csvFile"
                    type="file"
                    accept=".csv"
                    onChange={handleCSVUpload}
                    className="input"
                />
            </div>

            {/* Parsed CSV Data Summary */}
            {csvData.length > 0 && (
                <div className="csv-data-summary">
                    <h4>Parsed Airdrop Entries</h4>
                    <p>Total Entries: {csvData.length}</p>
                    <button
                        onClick={() => setCsvData([])}
                        className="clear-csv-button"
                    >
                        Clear CSV Data
                    </button>
                </div>
            )}

            {/* Display Uploaded CSV Data */}
            {csvData.length > 0 && (
                <div className="csv-data-display">
                    <h4>Uploaded Airdrop Entries</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Wallet Address</th>
                                <th>Amount (EX)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {csvData.map((entry, idx) => (
                                <tr key={idx}>
                                    <td>{shortenAddress(entry.address)}</td>
                                    <td>{entry.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Process Button */}
            <button
                onClick={handleProcess}
                disabled={loading}
                className={`process-button ${loading ? 'disabled' : ''}`}
            >
                {loading ? 'Processing...' : 'Process Collection'}
            </button>

            {/* Airdrop Button */}
            <button
                onClick={handleAirdropInitiation}
                disabled={
                    airdropLoading ||
                    (!data?.walletTotals && csvData.length === 0)
                }
                className={`airdrop-button ${airdropLoading ? 'disabled' : ''}`}
                style={{ marginLeft: '10px' }}
            >
                {airdropLoading ? 'Airdropping...' : 'Initiate Airdrop'}
            </button>

            {/* CSV Download Section */}
            {(data?.walletTotals || csvData.length > 0) && (
                <div className="csv-download">
                    <h4>Download Airdrop CSV:</h4>
                    <button onClick={downloadAirdropCSV} className="csv-button">
                        Download Airdrop CSV
                    </button>
                </div>
            )}

            {/* Error Message */}
            {error && <div className="error-message">{error}</div>}

            {/* Airdrop Result */}
            {airdropResult && (
                <div
                    className={`airdrop-result ${
                        airdropResult.success ? 'success' : 'error'
                    }`}
                >
                    {airdropResult.success ? (
                        <p>
                            Airdrop successful! Transaction hash:{' '}
                            {airdropResult.txHash}
                        </p>
                    ) : (
                        <p>Airdrop failed: {airdropResult.error}</p>
                    )}
                </div>
            )}

            {/* Progress Indicator */}
            {currentStep && (
                <div className="progress-indicator">
                    <p>
                        Current Step: {currentStep} ({progress}%)
                    </p>
                    <div
                        className={`progress-bar ${
                            progress === 100 ? 'completed' : ''
                        }`}
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
            )}

            {/* Display Fetched Reward Amount */}
            {data?.rewardPool && (
                <div className="reward-info">
                    <h4>Reward Amount</h4>
                    <p>
                        <strong>Reward Pool:</strong>{' '}
                        {formatBigNumber(data.rewardPool)} EX
                    </p>
                </div>
            )}

            {/* Rarity Totals */}
            {data?.rarityCounts &&
                Object.keys(data.rarityCounts).length > 0 && (
                    <div className="rarity-totals">
                        <h4>Rarity Totals</h4>
                        <ul>
                            {Object.entries(data.rarityCounts).map(
                                ([rarity, count]) => (
                                    <li key={rarity}>
                                        {rarity}: {count} - Reward:{' '}
                                        {formatBigNumber(data.rewards[rarity])}{' '}
                                        EX
                                    </li>
                                ),
                            )}
                        </ul>
                    </div>
                )}

            {/* Results */}
            <div className="results-section">
                {/* Main Wallet Holder List */}
                <div className="wallet-holders">
                    <h3>DOHNUT Holders</h3>
                    {data?.walletTotals &&
                    Object.keys(data.walletTotals).length > 0 ? (
                        <div className="wallet-list">
                            {Object.entries(data.walletTotals).map(
                                ([wallet, walletData]) => {
                                    console.log(
                                        `NFTOwners: Wallet ${wallet} data:`,
                                        {
                                            count: walletData.count,
                                            rarityCounts:
                                                walletData.rarityCounts,
                                            rewards:
                                                walletData.rewards.toFixed(2), // Format rewards for display
                                        },
                                    ); // Log each wallet's data with formatted rewards
                                    return (
                                        <div
                                            key={wallet}
                                            className="wallet-item"
                                        >
                                            <div className="wallet-header">
                                                <a
                                                    href={`https://explorer.alephium.org/addresses/${wallet}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="wallet-address-link"
                                                >
                                                    <span className="wallet-address">
                                                        {shortenAddress(wallet)}
                                                    </span>
                                                </a>
                                                <div className="wallet-icons">
                                                    {walletData.rarityCounts
                                                        .Legendary > 0 && (
                                                        <img
                                                            src={
                                                                legendaryDohnut
                                                            }
                                                            alt="Legendary"
                                                            className="rarity-icon"
                                                            title="Legendary"
                                                        />
                                                    )}
                                                    {walletData.rarityCounts
                                                        .Epic > 0 && (
                                                        <img
                                                            src={epicDohnut}
                                                            alt="Epic"
                                                            className="rarity-icon"
                                                            title="Epic"
                                                        />
                                                    )}
                                                    {walletData.rarityCounts
                                                        .Rare > 0 && (
                                                        <img
                                                            src={rareDohnut}
                                                            alt="Rare"
                                                            className="rarity-icon"
                                                            title="Rare"
                                                        />
                                                    )}
                                                    {walletData.rarityCounts
                                                        .Common > 0 && (
                                                        <img
                                                            src={commonDohnut}
                                                            alt="Common"
                                                            className="rarity-icon"
                                                            title="Common"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <p>
                                                <strong>Total NFTs:</strong>{' '}
                                                {walletData.count}
                                            </p>
                                            <p>
                                                <strong>Rewards:</strong>{' '}
                                                {formatBigNumber(
                                                    walletData.rewards,
                                                )}{' '}
                                                EX
                                            </p>
                                            <div className="wallet-details">
                                                <h5>Rarity Counts:</h5>
                                                <ul>
                                                    {walletData.rarityCounts &&
                                                        Object.entries(
                                                            walletData.rarityCounts,
                                                        ).map(
                                                            ([
                                                                rarity,
                                                                count,
                                                            ]) => (
                                                                <li
                                                                    key={rarity}
                                                                >
                                                                    {rarity}:{' '}
                                                                    {count}
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                                <h5>Individual NFT Rewards:</h5>
                                                <ul>
                                                    {Array.isArray(
                                                        walletData.nftRewards,
                                                    ) &&
                                                        walletData.nftRewards.map(
                                                            (nft, idx) => (
                                                                <li key={idx}>
                                                                    NFT Index:{' '}
                                                                    {
                                                                        nft.nftIndex
                                                                    }
                                                                    , Rarity:{' '}
                                                                    {nft.rarity}
                                                                    , Reward:{' '}
                                                                    {formatBigNumber(
                                                                        nft.reward,
                                                                    )}{' '}
                                                                    EX
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                },
                            )}
                        </div>
                    ) : (
                        <p>No wallet holders to display.</p>
                    )}

                    {/* Excluded NFT Indexes */}
                    {data?.excludedNFTIndexes &&
                        data.excludedNFTIndexes.length > 0 && (
                            <div className="excluded-nfts">
                                <h4>Excluded NFT Indexes:</h4>
                                <p>{data.excludedNFTIndexes.join(', ')}</p>
                            </div>
                        )}

                    {/* CSV Download Buttons */}
                    {data?.walletTotals &&
                        Object.keys(RARITY_PERCENTAGES).length > 0 && (
                            <div className="csv-download">
                                <h4>Download CSVs by Rarity:</h4>
                                <div className="csv-buttons">
                                    {[
                                        'Legendary',
                                        'Epic',
                                        'Rare',
                                        'Common',
                                    ].map((rarity) => (
                                        <button
                                            key={rarity}
                                            onClick={() => downloadAirdropCSV()} // Changed to download airdrop CSV
                                            className="csv-button"
                                        >
                                            Download {rarity} CSV
                                        </button>
                                    ))}
                                </div>
                                {/* Optionally, add a separate button for a single airdrop CSV */}
                                {/* <button onClick={downloadAirdropCSV} className="csv-button">
                Download Airdrop CSV
              </button> */}
                            </div>
                        )}
                </div>
            </div>

            {/* Confirmation Modal */}
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={executeAirdrop}
                airdropDetails={preparedAirdropDetails}
            />

            {/* Enhanced Processing Popup */}
            {showProcessingPopup && (
                <div className="processing-popup">
                    <div className="processing-content">
                        <Spinner />
                        <div className="processing-info">
                            <p className="processing-step">
                                Current Step: {currentStep}
                            </p>
                            <p className="processing-progress">
                                Progress: {progress}%
                            </p>
                            {/* Display total airdrop amount and wallets */}
                            {airdropLoading && (
                                <div className="airdrop-summary">
                                    <h4>Airdrop Summary</h4>
                                    <p>
                                        <strong>Total Airdrop Amount:</strong>{' '}
                                        {formatBigNumber(totalAirdropAmount)} EX
                                    </p>
                                    <p>
                                        <strong>Total Wallets:</strong>{' '}
                                        {totalWallets}
                                    </p>
                                </div>
                            )}
                            {/* Optionally, display the current stage's wallets */}
                            {/* You can enhance this section to show more detailed info as needed */}
                            {quoteData.image && (
                                <img
                                    src={quoteData.image}
                                    alt={`${quoteData.character}`}
                                    className="processing-meme"
                                />
                            )}
                            <p className="processing-quote">
                                "{quoteData.quote}"
                            </p>
                            <p className="processing-character">
                                - {quoteData.character}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
