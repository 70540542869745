import React, { useEffect, useState } from 'react';
import './workflowstatus.css';

// Helper function to fetch data from the proxy
const fetchData = async (apiType, repository) => {
    try {
        const response = await fetch(
            `https://dohcloud.azurewebsites.net/proxy?apiType=${apiType}&repository=${repository}`,
        );
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch data from the proxy:', error);
        return null;
    }
};

const WorkflowStatus = () => {
    const [alephiumLatestVersion, setAlephiumLatestVersion] = useState('');
    const [myRepoLatestVersion, setMyRepoLatestVersion] = useState('');
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [synced, setSynced] = useState(false); // Sync status

    useEffect(() => {
        const getAlephiumGithubVersion = async () => {
            const data = await fetchData('github', 'alephium/alephium-stack');
            if (data && data.length > 0) {
                const latestCommit = data[0]; // Latest commit
                const commitMessage = latestCommit.commit.message;
                const version =
                    commitMessage.match(/Full node (\d+\.\d+\.\d+)/)?.[1] ||
                    'Unknown';
                setAlephiumLatestVersion(version);
            } else {
                setError('Failed to fetch Alephium GitHub data');
            }
        };

        const getMyRepoVersions = async () => {
            const data = await fetchData('dockerhub', 'cyxeio/alphnode_test');
            if (data && data.results) {
                // Filter tags that match the format v3.6.3 or 3.6.3
                const version = data.results.find((tag) =>
                    /^v?[0-9]+\.[0-9]+\.[0-9]+$/.test(tag.name),
                );
                setMyRepoLatestVersion(version?.name || 'Unknown');
            }
        };

        // Fetch both versions and check sync status
        const checkStatus = async () => {
            try {
                await getAlephiumGithubVersion();
                await getMyRepoVersions();
                // Strip out 'v' for a cleaner comparison
                const cleanAlephiumVersion = alephiumLatestVersion.replace(
                    /^v/,
                    '',
                );
                const cleanMyRepoVersion = myRepoLatestVersion.replace(
                    /^v/,
                    '',
                );

                // Check if versions are synced
                if (cleanAlephiumVersion === cleanMyRepoVersion) {
                    setSynced(true);
                } else {
                    setSynced(false);
                }
            } catch (err) {
                setError('Error fetching data from the proxy');
            } finally {
                setLoading(false); // Data fetch complete
            }
        };

        checkStatus();
    }, [alephiumLatestVersion, myRepoLatestVersion]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="workflow-container">
            <h2>Workflow Status and Docker Versions</h2>

            <div className="version-section alephium-node">
                <h3>
                    Alephium Node Mainnet - Latest Version:{' '}
                    {alephiumLatestVersion || 'Loading...'}
                </h3>
            </div>

            <div className="version-section doh-node">
                <h3>
                    DOH! Node Mainnet - Latest Version:{' '}
                    {myRepoLatestVersion || 'Loading...'}
                </h3>
            </div>

            <div className="status-section">
                <h2>
                    Status:
                    <span
                        className={`status-light ${
                            synced ? 'synced' : 'not-synced'
                        }`}
                    ></span>
                    {synced ? 'Synced' : 'Not Synced'}
                </h2>
            </div>

            <div className="polling-indicator">Polling Live...</div>

            <div className="footer">
                &copy; 2024 doh.money. All rights reserved.
            </div>
        </div>
    );
};

export default WorkflowStatus;
