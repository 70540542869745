import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NodeConsole = ({ nodeName }) => {
    const [logs, setLogs] = useState([]);
    const [wsUrl, setWsUrl] = useState('');

    useEffect(() => {
        // Function to fetch WebSocket URL from the backend
        const fetchWsUrl = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/generateWebSocketUrl`,
                    {
                        params: { nodeName },
                    },
                );
                setWsUrl(response.data.url); // Assuming the backend sends a field `url` in the response
            } catch (error) {
                console.error('Error fetching WebSocket URL:', error);
            }
        };

        if (nodeName) {
            fetchWsUrl(); // Fetch WebSocket URL when nodeName is available
        }
    }, [nodeName]);

    useEffect(() => {
        if (!wsUrl) return; // Only proceed if WebSocket URL is set

        const ws = new WebSocket(wsUrl);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.onmessage = (message) => {
            setLogs((prevLogs) => [message.data, ...prevLogs]);
        };

        ws.onerror = (error) => console.error('WebSocket error:', error);
        ws.onclose = () => console.log('WebSocket connection closed');

        return () => {
            ws.close(); // Cleanup WebSocket on unmount or URL change
        };
    }, [wsUrl]);

    return (
        <div className="node-console">
            <h3>Console Output for {nodeName}</h3>
            <pre
                style={{
                    whiteSpace: 'pre-wrap',
                    overflowY: 'scroll',
                    maxHeight: '300px',
                }}
            >
                {logs.join('\n')}
            </pre>
        </div>
    );
};

export default NodeConsole;
