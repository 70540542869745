// LiveTransactions.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';

const apiClient = axios.create({
  headers: { 'X-Verified-By': 'Vercel' },
});

apiClient.interceptors.request.use(
  (config) => {
    config.headers['Ocp-Apim-Subscription-Key'] =
      process.env.NEXT_PUBLIC_APP ||
      process.env.REACT_APP_PUBLIC 
    return config;
  },
  (error) => Promise.reject(error)
);

const LiveTransactions = () => {
  const [messages, setMessages] = useState([]);
  const [wsUrl, setWsUrl] = useState(null);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(null);

  // Fetch the WebSocket URL
  useEffect(() => {
    const fetchWsUrl = async () => {
      try {
        console.log("[LiveTransactions] Fetching WS URL...");
        const response = await apiClient.get('https://api.doh.money/api/v2_generatewebsocketURL');
        console.log("[LiveTransactions] WS URL API response:", response.data);
        // Use one of the possible keys – adjust if necessary.
        const url = response.data.clientUrl || response.data.url || response.data.websocketUrl;
        if (!url) {
          throw new Error('No valid WebSocket URL found in the response.');
        }
        setWsUrl(url);
        console.log("[LiveTransactions] Fetched WS URL:", url);
      } catch (err) {
        console.error("[LiveTransactions] Error fetching WS URL:", err);
        setError("Failed to fetch WebSocket URL.");
      }
    };

    fetchWsUrl();
  }, []);

  // Connect to the WebSocket when wsUrl is available
  useEffect(() => {
    if (!wsUrl) {
      console.log("[LiveTransactions] No WS URL yet.");
      return;
    }
    console.log("[LiveTransactions] Connecting to WS:", wsUrl);
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log("[LiveTransactions] WebSocket connected.");
      setConnected(true);
    };

    ws.onerror = (evt) => {
      console.error("[LiveTransactions] WebSocket error:", evt);
      setError("WebSocket error occurred.");
    };

    ws.onclose = (evt) => {
      console.log("[LiveTransactions] WebSocket closed:", evt);
      setConnected(false);
    };

    ws.onmessage = (event) => {
      console.log("[LiveTransactions] Raw message:", event.data);
      try {
        let message = event.data;
        if (typeof message === 'string') {
          message = JSON.parse(message);
        }
        // If still a string (double stringified), try again:
        if (typeof message === 'string') {
          message = JSON.parse(message);
        }
        console.log("[LiveTransactions] Parsed message:", message);

        // Check for live transaction type
        if (message.type === "live_transaction" && message.data) {
          let payload = message.data;
          if (typeof payload === 'string') {
            payload = JSON.parse(payload);
          }
          // Append to messages (keeping only last 100)
          setMessages((prev) => {
            const updated = [...prev, payload];
            console.log("[LiveTransactions] Updated messages:", updated);
            return updated.slice(-100);
          });
        } else {
          console.warn("[LiveTransactions] Unexpected message format:", message);
        }
      } catch (err) {
        console.error("[LiveTransactions] Error parsing message:", err);
      }
    };

    return () => {
      console.log("[LiveTransactions] Closing WS connection.");
      ws.close();
    };
  }, [wsUrl]);

  return (
    <Box sx={{ padding: '1rem', backgroundColor: '#1a1a1a', borderRadius: 2, color: '#fff' }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#FFD700' }}>
        Live Transactions {connected ? '🟢' : '🔴'}
      </Typography>
      {error && <Typography variant="body2" color="error">{error}</Typography>}
      {messages.length === 0 ? (
        <Box display="flex" alignItems="center" gap={1}>
          <CircularProgress size={18} color="inherit" />
          <Typography variant="body2">Waiting for transactions...</Typography>
        </Box>
      ) : (
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {messages.map((msg, idx) => (
              <li key={idx} style={{ marginBottom: '1rem', backgroundColor: '#2c2c2c', padding: '0.5rem', borderRadius: 4 }}>
                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', color: '#00FF99' }}>
                  {JSON.stringify(msg, null, 2)}
                </pre>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default LiveTransactions;