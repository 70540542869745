// Game.js
import React, { useState, useRef, useEffect, Suspense } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';

// Custom hook to capture keyboard input
function useKeyboard() {
  const [keys, setKeys] = useState({});
  useEffect(() => {
    const handleKeyDown = (e) =>
      setKeys((prev) => ({ ...prev, [e.code]: true }));
    const handleKeyUp = (e) =>
      setKeys((prev) => ({ ...prev, [e.code]: false }));
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  return keys;
}

// The player's spaceship component
function Spaceship({ spaceshipRef }) {
  const keys = useKeyboard();
  useFrame((state, delta) => {
    if (!spaceshipRef.current) return;
    const speed = 5;
    if (keys['ArrowLeft']) spaceshipRef.current.position.x -= delta * speed;
    if (keys['ArrowRight']) spaceshipRef.current.position.x += delta * speed;
    if (keys['ArrowUp']) spaceshipRef.current.position.y += delta * speed;
    if (keys['ArrowDown']) spaceshipRef.current.position.y -= delta * speed;
  });
  return (
    <mesh ref={spaceshipRef} position={[0, -3, 0]}>
      <coneBufferGeometry args={[0.5, 1.5, 8]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
}

// The enemy component that spawns and moves downward
function Enemy({ id, initialX, spaceshipRef, onOutOfBound, onCollision }) {
  const ref = useRef();
  const [hasCollided, setHasCollided] = useState(false);
  useFrame((state, delta) => {
    if (!ref.current) return;
    // Move enemy downward
    ref.current.position.y -= delta * 2;
    // Check for collision with the spaceship
    if (spaceshipRef.current && !hasCollided) {
      const dx = ref.current.position.x - spaceshipRef.current.position.x;
      const dy = ref.current.position.y - spaceshipRef.current.position.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      if (distance < 0.7) {
        setHasCollided(true);
        onCollision();
      }
    }
    // Remove enemy if off-screen
    if (ref.current.position.y < -6) {
      onOutOfBound(id);
    }
  });
  return (
    <mesh ref={ref} position={[initialX, 5, 0]}>
      <boxBufferGeometry args={[0.7, 0.7, 0.7]} />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

// A simple starfield background using points
function StarField() {
  const starCount = 500;
  const positions = new Float32Array(starCount * 3);
  for (let i = 0; i < starCount; i++) {
    positions[i * 3] = (Math.random() - 0.5) * 50;
    positions[i * 3 + 1] = (Math.random() - 0.5) * 50;
    positions[i * 3 + 2] = -Math.random() * 50; // Stars placed further back
  }
  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial attach="material" color="white" size={0.1} />
    </points>
  );
}

// Main game component
export default function Game() {
  const spaceshipRef = useRef();
  const [enemies, setEnemies] = useState([]);
  const [gameOver, setGameOver] = useState(false);

  // Spawn new enemies every 2 seconds while game is active
  useEffect(() => {
    if (gameOver) return;
    const interval = setInterval(() => {
      setEnemies((prev) => [
        ...prev,
        { id: Date.now(), x: (Math.random() - 0.5) * 10 },
      ]);
    }, 2000);
    return () => clearInterval(interval);
  }, [gameOver]);

  // Remove enemy when it moves off-screen
  const removeEnemy = (id) => {
    setEnemies((prev) => prev.filter((enemy) => enemy.id !== id));
  };

  // End the game on collision
  const handleCollision = () => {
    setGameOver(true);
  };

  // Restart the game by resetting state and spaceship position
  const restartGame = () => {
    setGameOver(false);
    setEnemies([]);
    if (spaceshipRef.current) {
      spaceshipRef.current.position.set(0, -3, 0);
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh', background: 'black' }}>
      <Canvas camera={{ position: [0, 0, 10], fov: 75 }}>
        <ambientLight intensity={0.5} />
        <directionalLight position={[0, 5, 5]} intensity={1} />
        <Suspense fallback={null}>
          <StarField />
          {!gameOver && <Spaceship spaceshipRef={spaceshipRef} />}
          {enemies.map((enemy) => (
            <Enemy
              key={enemy.id}
              id={enemy.id}
              initialX={enemy.x}
              spaceshipRef={spaceshipRef}
              onOutOfBound={removeEnemy}
              onCollision={handleCollision}
            />
          ))}
          {gameOver && (
            <Html center>
              <div style={{ color: 'white', fontSize: '2em', textAlign: 'center' }}>
                <p>Game Over</p>
                <button onClick={restartGame}>Restart</button>
              </div>
            </Html>
          )}
        </Suspense>
      </Canvas>
    </div>
  );
}