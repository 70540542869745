import React, { useEffect, useState, useRef, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Typography,
  Switch,
  FormControlLabel,
  createTheme,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import { GetApp as GetAppIcon } from '@mui/icons-material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import * as d3 from 'd3';
import './walletsummary.css';

// ------------------------------------------------------------------
// Environment Variables with Fallback Defaults
// ------------------------------------------------------------------
const SUBSCRIPTION_KEY =
  process.env.NEXT_PUBLIC_APP ||
  process.env.REACT_APP_SUBSCRIPTION_KEY ||
  '';

const WALLETSUMMARY_API_URL =
  process.env.NEXT_PUBLIC_WALLETSUMMARY_API_URL ||
  process.env.REACT_APP_WALLETSUMMARY_API_URL ||
  '';

// ------------------------------------------------------------------
// Token Display Component for Holdings (Uses Logo if Available)
// ------------------------------------------------------------------
const TokenDisplay = ({ tokenLogo, tokenId }) => {
  if (tokenLogo) {
    return (
      <img
        src={tokenLogo}
        alt={tokenId}
        style={{
          width: '1em',
          height: '1em',
          verticalAlign: 'middle',
          marginLeft: '0.25em',
        }}
      />
    );
  }
  return <span style={{ marginLeft: '0.25em' }}>{tokenId}</span>;
};

TokenDisplay.propTypes = {
  tokenLogo: PropTypes.string,
  tokenId: PropTypes.string.isRequired,
};

// ------------------------------------------------------------------
// Main Component
// ------------------------------------------------------------------
const WalletSummaryView = ({ tokenId, walletAddress, tokenLogo }) => {
  // Theme toggle: darkMode state controls our theme.
  const [darkMode, setDarkMode] = useState(true);
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
        components: {
          MuiTableRow: {
            styleOverrides: {
              root: {
                '&:hover': {
                  transform: 'none !important',
                  backfaceVisibility: 'hidden',
                  textRendering: 'optimizeLegibility',
                },
              },
            },
          },
        },
      }),
    [darkMode]
  );

  const [data, setData] = useState(null);
  const [timeframe, setTimeframe] = useState('all'); // options: 'all', '1d', '7d', '30d'
  const [txFilter, setTxFilter] = useState(''); // transaction type filter input
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Refs for d3 containers:
  const chartD3Ref = useRef(null);
  const heatmapRef = useRef(null);

  // --- Data Fetching ---
  useEffect(() => {
    if (!tokenId || !walletAddress) {
      setData(null);
      return;
    }
  
    setLoading(true);
    setError(null);
  
    const proxyUrl = `${WALLETSUMMARY_API_URL}/${tokenId}/${walletAddress}`;
  
    axios
      .get(proxyUrl, {
        headers: { 'Ocp-Apim-Subscription-Key': SUBSCRIPTION_KEY },
      })
      .then((response) => {
        const raw = response.data[0];
        const key = Object.keys(raw)[0];
        const parsed = JSON.parse(raw[key]);
        setData(parsed);
      })
      .catch((err) => {
        console.error('Error fetching wallet summary:', err);
        setError(err.message || 'Error fetching wallet summary');
      })
      .finally(() => setLoading(false));
  }, [tokenId, walletAddress]);

  // --- Helper Functions ---
  const formatNum = (num, decimals = 6) => {
    if (num === null || isNaN(num)) return 'N/A';
    return Number(num).toFixed(decimals);
  };

  // Compute totals from summary rows.
  const computeTotals = (summary) => {
    let totalTx = 0,
      totalGain = 0,
      totalBuyTx = 0,
      weightedBuy = 0,
      totalSellTx = 0,
      weightedSell = 0,
      totalTokens = 0,
      totalBuys = 0,
      totalSells = 0;
    summary.forEach((row) => {
      totalTx += row.transaction_count;
      totalGain += parseFloat(row.total_gain_loss) || 0;
      const buy = parseFloat(row.avg_buy_price) || 0;
      const sell = parseFloat(row.avg_sell_price) || 0;
      const txCount = row.transaction_count || 0;
      weightedBuy += buy * txCount;
      totalBuyTx += txCount;
      weightedSell += sell * txCount;
      totalSellTx += txCount;
      totalTokens += parseFloat(row.total_tokens_transacted) || 0;
      totalBuys += row.total_buys || 0;
      totalSells += row.total_sells || 0;
    });
    return {
      totalTx,
      totalGain,
      weightedAvgBuy: totalBuyTx ? weightedBuy / totalBuyTx : 0,
      weightedAvgSell: totalSellTx ? weightedSell / totalSellTx : 0,
      totalTokens,
      totalBuys,
      totalSells,
      roi: totalBuyTx ? (totalGain / totalBuyTx) * 100 : 0,
    };
  };

  // Holdings summary: aggregate token amounts from transactions.
  const computeHoldings = (transactions) => {
    const holdings = {};
    transactions.forEach((tx) => {
      const got = parseFloat(tx.got_tokens) || 0;
      const spent = parseFloat(tx.for_tokens) || 0;
      const token = tx.pool_name || 'Unknown Pool';
      if (!holdings[token]) {
        holdings[token] = { net: 0, got: 0, spent: 0 };
      }
      holdings[token].got += got;
      holdings[token].spent += spent;
      holdings[token].net += got - spent;
    });
    return holdings;
  };

  // --- d3 Cumulative Profit/Loss Chart ---
  useEffect(() => {
    if (!data || !data.transactions) return;
    d3.select(chartD3Ref.current).selectAll('*').remove();

    const sortedTx = [...data.transactions].sort(
      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
    );
    let cumulative = 0;
    const rawChartData = sortedTx.map((tx) => {
      const gainLoss = parseFloat(tx.gain_loss) || 0;
      cumulative += gainLoss;
      return {
        time: new Date(tx.timestamp),
        value: cumulative,
      };
    });

    let filteredData = rawChartData;
    if (timeframe !== 'all') {
      const now = Date.now();
      let ms;
      if (timeframe === '1d') ms = 86400000;
      else if (timeframe === '7d') ms = 7 * 86400000;
      else if (timeframe === '30d') ms = 30 * 86400000;
      filteredData = rawChartData.filter((d) => d.time.getTime() >= now - ms);
    }

    const margin = { top: 20, right: 20, bottom: 30, left: 50 },
      width = 500 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;

    const svg = d3
      .select(chartD3Ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('display', 'block')
      .style('margin', '0 auto')
      .style('background', darkMode ? '#1E1E1E' : '#FFF')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleTime()
      .domain(d3.extent(filteredData, (d) => d.time))
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([
        d3.min(filteredData, (d) => d.value) * 1.05,
        d3.max(filteredData, (d) => d.value) * 1.05,
      ])
      .range([height, 0]);

    const area = d3
      .area()
      .x((d) => x(d.time))
      .y0(height)
      .y1((d) => y(d.value))
      .curve(d3.curveMonotoneX);

    const line = d3
      .line()
      .x((d) => x(d.time))
      .y((d) => y(d.value))
      .curve(d3.curveMonotoneX);

    // Append area path.
    svg
      .append('path')
      .datum(filteredData)
      .attr('fill', 'rgba(50,205,50,0.3)')
      .attr('d', area)
      .attr('opacity', 0)
      .transition()
      .duration(800)
      .attr('opacity', 1);

    // Append line path.
    svg
      .append('path')
      .datum(filteredData)
      .attr('fill', 'none')
      .attr('stroke', '#32CD32')
      .attr('stroke-width', 2)
      .attr('d', line)
      .attr('opacity', 0)
      .transition()
      .duration(800)
      .attr('opacity', 1);

    const xAxis = d3
      .axisBottom(x)
      .ticks(5)
      .tickSizeOuter(0)
      .tickFormat(d3.timeFormat('%m/%d'));

    const yAxis = d3.axisLeft(y).ticks(5).tickSizeOuter(0);

    // Create x-axis group.
    svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .style('fill', darkMode ? '#FFF' : '#000');

    svg
      .append('g')
      .call(yAxis)
      .selectAll('text')
      .style('fill', darkMode ? '#FFF' : '#000');

    svg.selectAll('.domain, .tick line').style('stroke', darkMode ? '#888' : '#AAA');

    // d3 Zoom behavior.
    const zoom = d3
      .zoom()
      .scaleExtent([1, 8])
      .on('zoom', zoomed);

    svg.call(zoom);

    function zoomed(event) {
      const newX = event.transform.rescaleX(x);
      svg.selectAll('path').attr('d', function (d) {
        if (!d || !Array.isArray(d)) return null;
        const currentFill = d3.select(this).attr('fill');
        if (currentFill && currentFill !== 'none') {
          const newArea = d3
            .area()
            .x((datum) => newX(datum.time))
            .y0(height)
            .y1((datum) => y(datum.value))
            .curve(d3.curveMonotoneX);
          return newArea(d);
        } else {
          const newLine = d3
            .line()
            .x((datum) => newX(datum.time))
            .y((datum) => y(datum.value))
            .curve(d3.curveMonotoneX);
          return newLine(d);
        }
      });
      svg
        .selectAll('g')
        .filter(function () {
          return d3.select(this).attr('transform') === `translate(0,${height})`;
        })
        .call(xAxis.scale(newX));
    }

    // Dynamic tooltip.
    const tooltip = d3
      .select(chartD3Ref.current)
      .append('div')
      .style('position', 'absolute')
      .style('background', darkMode ? '#333' : '#EEE')
      .style('color', darkMode ? '#FFF' : '#000')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    svg
      .append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mousemove', function (event) {
        const [mx] = d3.pointer(event);
        const bisectDate = d3.bisector((d) => d.time).left;
        const x0 = x.invert(mx);
        const i = bisectDate(filteredData, x0, 1);
        const d0 = filteredData[i - 1];
        const d1 = filteredData[i];
        const d =
          d1 && x0 - d0.time > d1.time - x0 ? d1 : d0 || filteredData[0];
        tooltip
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        tooltip
          .html(
            `<strong>${d3.timeFormat('%m/%d/%Y %H:%M')(d.time)}</strong><br/>Value: ${formatNum(
              d.value,
              2
            )} ℵ`
          )
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 28 + 'px');
      })
      .on('mouseout', () =>
        tooltip.transition().duration(500).style('opacity', 0)
      );
  }, [data, timeframe, darkMode]);

  // --- d3 Heatmap ---
  useEffect(() => {
    if (!data || !data.transactions) return;
    d3.select(heatmapRef.current).selectAll('*').remove();

    const heatData = Array.from({ length: 7 }, () => Array(24).fill(0));
    data.transactions.forEach((tx) => {
      const d = new Date(tx.timestamp);
      const day = d.getDay();
      const hour = d.getHours();
      heatData[day][hour] += 1;
    });

    const margin = { top: 20, right: 20, bottom: 20, left: 20 },
      width = 300 - margin.left - margin.right,
      height = 300 - margin.top - margin.bottom;

    const svg = d3
      .select(heatmapRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('display', 'block')
      .style('margin', '0 auto')
      .style('background', darkMode ? '#1E1E1E' : '#FFF')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const hours = d3.range(0, 24);
    const colorScale = d3
      .scaleSequential(d3.interpolateInferno)
      .domain([0, d3.max(heatData.flat()) || 1]);

    const cellWidth = width / 24;
    const cellHeight = height / 7;

    // Tooltip for heatmap.
    const heatTooltip = d3
      .select(heatmapRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('background', darkMode ? '#333' : '#EEE')
      .style('color', darkMode ? '#FFF' : '#000')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    heatData.forEach((row, dayIndex) => {
      row.forEach((count, hourIndex) => {
        svg
          .append('rect')
          .attr('x', hourIndex * cellWidth)
          .attr('y', dayIndex * cellHeight)
          .attr('width', cellWidth)
          .attr('height', cellHeight)
          .attr('fill', colorScale(count))
          .on('mousemove', (event) => {
            heatTooltip.transition().duration(200).style('opacity', 0.9);
            heatTooltip
              .html(`${days[dayIndex]} ${hourIndex}:00<br/>${count} tx`)
              .style('left', event.pageX + 10 + 'px')
              .style('top', event.pageY - 28 + 'px');
          })
          .on('mouseout', () =>
            heatTooltip.transition().duration(500).style('opacity', 0)
          );
      });
    });

    // Day labels.
    svg
      .selectAll('.dayLabel')
      .data(days)
      .enter()
      .append('text')
      .text((d) => d)
      .attr('x', -5)
      .attr('y', (d, i) => i * cellHeight + cellHeight / 2)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .attr('fill', darkMode ? '#FFF' : '#000')
      .attr('font-size', '10px');

    // Hour labels.
    svg
      .selectAll('.hourLabel')
      .data(hours)
      .enter()
      .append('text')
      .text((d) => d)
      .attr('x', (d, i) => i * cellWidth + cellWidth / 2)
      .attr('y', -5)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'bottom')
      .attr('fill', darkMode ? '#FFF' : '#000')
      .attr('font-size', '10px');
  }, [data, darkMode]);

  // --- Export Functions ---
  const exportCSV = () => {
    if (!data || !data.transactions) return;
    const header = Object.keys(data.transactions[0]);
    const csvRows = [
      header.join(','),
      ...data.transactions.map((row) =>
        header
          .map((field) =>
            JSON.stringify(row[field], (key, value) =>
              value === null ? '' : value
            )
          )
          .join(',')
      ),
    ];
    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wallet_transactions.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportJSON = () => {
    if (!data || !data.transactions) return;
    const blob = new Blob([JSON.stringify(data.transactions, null, 2)], {
      type: 'application/json',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'wallet_transactions.json';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportChartAsImage = () => {
    const svgNode = chartD3Ref.current.querySelector('svg');
    if (!svgNode) return;
    const serializer = new XMLSerializer();
    const source = serializer.serializeToString(svgNode);
    const blob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'cumulative_profit_chart.svg';
    a.click();
    URL.revokeObjectURL(url);
  };

  // --- Additional Analytics ---
  const computeAdditionalAnalytics = () => {
    if (!data || !data.transactions || data.transactions.length < 2) return {};
    const txTimes = data.transactions.map((tx) => new Date(tx.timestamp).getTime());
    const diffs = [];
    for (let i = 1; i < txTimes.length; i++) {
      diffs.push(txTimes[i] - txTimes[i - 1]);
    }
    const avgDiff = diffs.reduce((a, b) => a + b, 0) / diffs.length;
    return { avgTimeBetweenTx: avgDiff };
  };

  const additionalAnalytics = computeAdditionalAnalytics();

  // --- Compute Highlights ---
  const getHighlights = () => {
    if (!data || !data.transactions) return {};
    let largestGain = null;
    data.transactions.forEach((tx) => {
      const gain = parseFloat(tx.gain_loss) || 0;
      if (!largestGain || gain > (parseFloat(largestGain.gain_loss) || 0)) {
        largestGain = tx;
      }
    });
    return { largestGain };
  };

  const highlights = getHighlights();

  const totals =
    data && data.summary && data.summary.length > 0
      ? computeTotals(data.summary)
      : {
          totalTx: 0,
          totalGain: 0,
          weightedAvgBuy: 0,
          weightedAvgSell: 0,
          totalTokens: 0,
          totalBuys: 0,
          totalSells: 0,
          roi: 0,
        };

  const holdings = data && data.transactions ? computeHoldings(data.transactions) : null;

  const filteredTransactions = useMemo(() => {
    if (!data || !data.transactions) return [];
    if (!txFilter) return data.transactions;
    return data.transactions.filter((tx) =>
      tx.transaction_type.toLowerCase().includes(txFilter.toLowerCase())
    );
  }, [data, txFilter]);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box display="flex" alignItems="center" gap={1} sx={{ p: 2 }}>
          <CircularProgress size={24} />
          <Typography>Loading wallet data…</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Typography color="error" sx={{ p: 2 }}>
          Error: {error}
        </Typography>
      </ThemeProvider>
    );
  }

  if (!data) {
    return (
      <ThemeProvider theme={theme}>
        <Typography sx={{ p: 2 }}>No wallet data available.</Typography>
      </ThemeProvider>
    );
  }

  // ------------------------------------------------------------------
  // TabPanel Component for Collapsible Details
  // ------------------------------------------------------------------
  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`transaction-tabpanel-${index}`}
        aria-labelledby={`transaction-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // --- Render ---
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mt: 2,
          p: 2,
          backgroundColor: theme.palette.background.default,
          borderRadius: 2,
          color: theme.palette.text.primary,
        }}
      >
        {/* Dark Mode Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label="Dark Mode"
          />
        </Box>

        <Typography variant="h6" gutterBottom>
          Wallet Summary for {data.summary && data.summary[0]?.pool_name}
        </Typography>

        {/* Additional Analytics */}
        <Paper
          sx={{
            p: 2,
            mb: 3,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Additional Analytics
          </Typography>
          <Typography variant="body2">
            Total Transactions: <strong>{totals.totalTx}</strong>
          </Typography>
          {additionalAnalytics.avgTimeBetweenTx && (
            <Typography variant="body2">
              Average Time Between Transactions:{' '}
              <strong>{(additionalAnalytics.avgTimeBetweenTx / 60000).toFixed(2)} minutes</strong>
            </Typography>
          )}
        </Paper>

        {/* Top Section: d3 Chart and Heatmap Side-by-Side */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2,
            mb: 3,
          }}
        >
          {/* d3 Cumulative Profit/Loss Chart */}
          <Paper sx={{ flex: 1, p: 2, backgroundColor: theme.palette.background.paper }}>
            <Typography variant="subtitle2" sx={{ mb: 1, textAlign: 'center' }}>
              Cumulative Profit/Loss Chart
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mb: 1 }}>
              {['all', '1d', '7d', '30d'].map((tf) => (
                <Button
                  key={tf}
                  variant={timeframe === tf ? 'contained' : 'outlined'}
                  onClick={() => setTimeframe(tf)}
                  size="small"
                >
                  {tf.toUpperCase()}
                </Button>
              ))}
            </Box>
            <Box ref={chartD3Ref} sx={{ width: '100%', height: 300 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
              <Button variant="outlined" size="small" onClick={exportChartAsImage}>
                Export Chart as Image
              </Button>
            </Box>
          </Paper>

          {/* d3 Heatmap */}
          <Paper sx={{ flex: 1, p: 2, backgroundColor: theme.palette.background.paper }}>
            <Typography variant="subtitle2" sx={{ mb: 1, textAlign: 'center' }}>
              Trade Activity Heatmap
            </Typography>
            <Box ref={heatmapRef} sx={{ width: '100%', height: 300 }} />
          </Paper>
        </Box>

        {/* Export Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mb: 2 }}>
          <Button variant="outlined" startIcon={<GetAppIcon />} onClick={exportCSV} size="small">
            Export CSV
          </Button>
          <Button variant="outlined" startIcon={<GetAppIcon />} onClick={exportJSON} size="small">
            Export JSON
          </Button>
        </Box>

        {/* Summary Table with Net Totals (Using ℵ) */}
        <TableContainer component={Paper} sx={{ mb: 3, backgroundColor: 'rgba(17,17,17,0.8)' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {[
                  { label: 'Pool', align: 'left' },
                  { label: 'Tx', align: 'right' },
                  { label: 'G/L', align: 'right' },
                  { label: 'Avg Buy', align: 'right' },
                  { label: 'Avg Sell', align: 'right' },
                  { label: 'Total', align: 'right' },
                  { label: 'Buys', align: 'right' },
                  { label: 'Sells', align: 'right' },
                ].map((header) => (
                  <TableCell
                    key={header.label}
                    align={header.align}
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: 'bold',
                      color: '#B0B0B0',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      p: '4px 8px',
                    }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.summary && data.summary.length > 0 ? (
                data.summary.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      transition: 'background-color 0.3s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                      },
                    }}
                  >
                    <TableCell sx={{ fontSize: '0.75rem' }}>{row.pool_name}</TableCell>
                    <TableCell align="right" sx={{ fontSize: '0.75rem' }}>
                      {row.transaction_count}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        color: parseFloat(row.total_gain_loss) >= 0 ? '#32CD32' : '#FF4500',
                      }}
                    >
                      {formatNum(row.total_gain_loss, 2)} ℵ
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: '0.75rem',
                        color: '#32CD32',
                        fontWeight: 'bold',
                      }}
                    >
                      {formatNum(row.avg_buy_price, 6)} ℵ
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: '0.75rem',
                        color: '#FF4500',
                        fontWeight: 'bold',
                      }}
                    >
                      {formatNum(row.avg_sell_price, 6)} ℵ
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                      {formatNum(row.total_tokens_transacted, 2)} 
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                      {row.total_buys}
                    </TableCell>
                    <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                      {row.total_sells}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center" sx={{ fontSize: '0.75rem' }}>
                    No summary data available
                  </TableCell>
                </TableRow>
              )}
              {totals && (
                <TableRow sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                  <TableCell sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>Net Totals</TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    {totals.totalTx}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      fontSize: '0.75rem',
                      fontWeight: 'bold',
                      color: totals.totalGain >= 0 ? '#32CD32' : '#FF4500',
                    }}
                  >
                    {formatNum(totals.totalGain, 2)} ℵ
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#32CD32' }}>
                    {formatNum(totals.weightedAvgBuy, 6)} ℵ
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#FF4500' }}>
                    {formatNum(totals.weightedAvgSell, 6)} ℵ
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    {formatNum(totals.totalTokens, 2)} 
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    {totals.totalBuys}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                    {totals.totalSells}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Holdings Summary with Token Logo (or Token Symbol) */}
        <Paper sx={{ p: 2, mb: 3, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Token & Pool Holdings
          </Typography>
          {holdings ? (
            Object.entries(holdings).map(([token, hld]) => (
              <Box key={token} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">{token}</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  Net: {formatNum(hld.net, 2)}{' '}
                  <TokenDisplay tokenLogo={tokenLogo} tokenId={tokenId} /> &nbsp; (Got:{' '}
                  <span style={{ color: '#32CD32' }}>{formatNum(hld.got, 2)}</span>, Spent:{' '}
                  <span style={{ color: '#FF4500' }}>{formatNum(hld.spent, 2)}</span>)
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No holdings data available.</Typography>
          )}
        </Paper>

        {/* Highlights Section */}
        <Paper sx={{ p: 2, mb: 3, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Trade Highlights
          </Typography>
          <Box>
            <Typography variant="body2">
              Highest Gain Trade:{' '}
              {highlights.largestGain ? (
                <span style={{ color: '#32CD32', fontWeight: 'bold' }}>
                  {formatNum(highlights.largestGain.gain_loss, 2)} gain on{' '}
                  {new Date(highlights.largestGain.timestamp).toLocaleString()}
                </span>
              ) : (
                'N/A'
              )}
            </Typography>
          </Box>
        </Paper>

        {/* Wallet vs. Market */}
        <Paper sx={{ p: 2, mb: 3, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Wallet vs. Market
          </Typography>
          <Typography variant="body2">
            Your wallet ROI is{' '}
            <span style={{ color: totals.roi >= 0 ? '#32CD32' : '#FF4500', fontWeight: 'bold' }}>
              {formatNum(totals.roi, 2)}%
            </span>{' '}
            compared to the market average.
          </Typography>
        </Paper>

        {/* Transaction Summary Table */}
        <Paper sx={{ p: 2, mb: 3, backgroundColor: 'transparent', boxShadow: 'none', maxHeight: 250, overflowY: 'auto' }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Transaction Details
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {[
                    { label: 'Hash', align: 'center' },
                    { label: 'Time', align: 'left' },
                    { label: 'Type', align: 'center' },
                    { label: 'Got', align: 'right' },
                    { label: 'For', align: 'right' },
                    { label: 'Ratio', align: 'right' },
                    { label: 'G/L', align: 'right' },
                    { label: 'Receiver', align: 'left' },
                    { label: 'Pool', align: 'left' },
                  ].map((header) => (
                    <TableCell
                      key={header.label}
                      align={header.align}
                      sx={{
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        color: '#B0B0B0',
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        p: '4px 8px',
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTransactions && filteredTransactions.length > 0 ? (
                  filteredTransactions.map((tx, idx) => {
                    const txColor = tx.transaction_type.toLowerCase().includes('buy')
                      ? '#32CD32'
                      : tx.transaction_type.toLowerCase().includes('sell')
                      ? '#FF4500'
                      : '#FFF';
                    return (
                      <TableRow
                        key={idx}
                        sx={{
                          transition: 'background-color 0.3s ease-in-out',
                          '&:hover': {
                            backgroundColor: 'rgba(255,255,255,0.1)',
                          },
                        }}
                      >
                        <TableCell align="center" sx={{ fontSize: '0.75rem' }}>
                          <Tooltip title={tx.hash || 'No hash available'}>
                            <FingerprintIcon fontSize="small" />
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.75rem' }}>
                          {new Date(tx.timestamp).toLocaleString()}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            color: txColor,
                          }}
                        >
                          {tx.transaction_type}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: '0.75rem',
                            color: '#32CD32',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNum(tx.got_tokens, 6)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: '0.75rem',
                            color: '#FF4500',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNum(tx.for_tokens, 6)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: '0.75rem',
                            color: '#FFD700',
                            fontWeight: 'bold',
                          }}
                        >
                          {formatNum(tx.ratio, 6)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            color: parseFloat(tx.gain_loss) >= 0 ? '#32CD32' : '#FF4500',
                          }}
                        >
                          {formatNum(tx.gain_loss, 2)}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.75rem' }}>
                          {tx.receiving_wallet_address || '-'}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.75rem' }}>
                          {tx.pool_name || '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center" sx={{ fontSize: '0.75rem' }}>
                      No transaction data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

WalletSummaryView.propTypes = {
  tokenId: PropTypes.string.isRequired,
  walletAddress: PropTypes.string.isRequired,
  tokenLogo: PropTypes.string, // Optional token logo passed from tokendashboard
};

export default WalletSummaryView;