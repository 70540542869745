import React, { useState, useMemo, useCallback } from 'react';
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Avatar,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { FixedSizeList as List } from 'react-window';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

// Styled Components
const LeaderboardPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  flex: 1,
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  height: 500, // Increased height to show more items
}));

const RankBadge = styled(Box)(({ theme }) => ({
  minWidth: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  marginRight: theme.spacing(1),
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const ControlsContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  alignItems: 'center',
}));

const ListItemContainer = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
}));

// Main Component
const PoolSummaryView = React.memo(({ poolSummary, loading, error, onAddressClick }) => {
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState('gain_desc');
  const [filterText, setFilterText] = useState('');
  const [detailedView, setDetailedView] = useState(true);

  // Ensure poolSummary is at least an empty object
  const summary = poolSummary || {};

  const handleAddressClick = useCallback(
    (address) => {
      console.log('Address clicked:', address);
      if (onAddressClick) onAddressClick(address);
    },
    [onAddressClick]
  );

  // Ensure topGainers and topLosers are arrays
  const topGainers = useMemo(() => {
    const gainers = Array.isArray(summary.topGainers) ? summary.topGainers : [];
    return gainers
      .filter((item) =>
        item.pool_name?.toLowerCase().includes(filterText.toLowerCase())
      )
      .sort((a, b) => b.total_gain_loss - a.total_gain_loss);
  }, [summary.topGainers, filterText]);

  const topLosers = useMemo(() => {
    const losers = Array.isArray(summary.topLosers) ? summary.topLosers : [];
    return losers
      .filter((item) =>
        item.pool_name?.toLowerCase().includes(filterText.toLowerCase())
      )
      .sort((a, b) => a.total_gain_loss - b.total_gain_loss);
  }, [summary.topLosers, filterText]);

  const renderRow = ({ index, style, data }) => {
    const { items, title } = data;
    const item = items[index];

    // Format wallet address as XXX...XXXXX
    const formatAddress = (address) => {
      if (!address) return 'N/A';
      return `${address.slice(0, 3)}...${address.slice(-5)}`;
    };

    return (
      <ListItemContainer
        style={style}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        aria-label={`Rank ${index + 1} - ${item.pool_name}`}
        onClick={() => handleAddressClick(item.initiating_address)}
      >
        <RankBadge>{index + 1}</RankBadge>
        {item.token_logo && (
          <Avatar
            src={item.token_logo}
            alt={item.pool_name || 'Token Logo'}
            sx={{ width: 30, height: 30, marginRight: theme.spacing(1) }}
          />
        )}
        <Box flexGrow={1}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
            {item.pool_name || 'N/A'}
          </Typography>
          {detailedView && (
            <Tooltip title={item.initiating_address || ''} arrow>
              <Typography
                variant="body2"
                sx={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '1rem' }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddressClick(item.initiating_address);
                }}
              >
                {formatAddress(item.initiating_address)}
              </Typography>
            </Tooltip>
          )}
        </Box>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 'bold',
            color:
              title === 'Top Losers'
                ? theme.palette.error.main
                : title === 'Top Gainers'
                ? theme.palette.success.main
                : theme.palette.text.primary,
            fontSize: '0.8rem',
          }}
        >
          {Number(item.total_gain_loss).toFixed(2)} ℵ
        </Typography>
      </ListItemContainer>
    );
  };

  const VirtualizedList = ({ title, items }) => (
    <LeaderboardPaper elevation={2}>
      <Typography variant="h6" sx={{ fontSize: '1rem' }} gutterBottom>
        {title}
      </Typography>
      {items.length > 0 ? (
        <List
          height={400} // Adjusted height to show more items
          itemCount={items.length}
          itemSize={70}
          width="100%"
          itemData={{ items, title }}
        >
          {renderRow}
        </List>
      ) : (
        <Typography variant="body2" sx={{ p: 2 }}>
          No data available.
        </Typography>
      )}
    </LeaderboardPaper>
  );

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={2}>
        <CircularProgress size={30} />
        <Typography variant="body2" ml={2} fontSize="0.8rem">
          Loading Summary...
        </Typography>
      </Box>
    );
  }

  // If summary is empty, show a friendly message.
  if (!summary || Object.keys(summary).length === 0) {
    return (
      <Typography variant="body2" p={2} fontSize="0.8rem">
        No summary data available. Please select a pool.
      </Typography>
    );
  }

  return (
    <Box>
      {/* Controls */}
      <ControlsContainer>
        <FormControl variant="outlined" size="small">
          <InputLabel id="sort-label">Sort</InputLabel>
          <Select
            labelId="sort-label"
            label="Sort"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <MenuItem value="gain_desc">Highest Gain</MenuItem>
            <MenuItem value="loss_asc">Highest Loss</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Filter Pools"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <Button variant="contained" onClick={() => setDetailedView((prev) => !prev)}>
          {detailedView ? 'Condense View' : 'Detailed View'}
        </Button>
      </ControlsContainer>
      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} p={3}>
        <VirtualizedList title="Top Gainers" items={topGainers} />
        <VirtualizedList title="Top Losers" items={topLosers} />
      </Box>
    </Box>
  );
});

PoolSummaryView.propTypes = {
  poolSummary: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
  onAddressClick: PropTypes.func,
};

PoolSummaryView.defaultProps = {
  poolSummary: {},
  loading: false,
  error: null,
};

export default PoolSummaryView;